<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>

      <loading :loading="loading" />

      <v-card class="v-card-bottom-30 hidden-xs-only">
        <v-card-title class="py-5">
          <h4>{{ use_language.order }}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{ use_language.orderList }} </span>
          <v-spacer/>
        </v-card-title>
      </v-card>

      <v-card class="v-card-bottom-30">
        <v-card-title>
         <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="py-2 ml-3">{{ use_language.searchList }}</span>
         <v-spacer/>
         <v-btn @click="refresh()" color="primary" class="mr-3">{{ use_language.reload }}</v-btn>

        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
              <span>{{ use_language.taobao_order_id }} : </span>
            </v-col>
            <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
              <v-text-field
                v-model="ch_TaobaoOrderID"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
              <span>{{ use_language.china_order_id }} : </span>
            </v-col>
            <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
              <v-text-field
                v-model="ch_ChinaOrderID"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
              <span>{{ use_language.tracking_no }} : </span>
            </v-col>
            <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
              <v-text-field
                v-model="ch_TrackingNo"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="2" xl="1" class="pb-0"></v-col>
            <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
              <v-btn @click="Searching()" color="primary" class="mr-3">{{ use_language.search }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="v-card-bottom-30">
        <v-tabs v-model="tab_list" dark background-color="primary" show-arrows>
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
          <v-tab @click="tab_list = 0, get_china_order(null, null, null)" ><span>{{ use_language.total }}</span></v-tab>
          <v-tab @click="tab_list = 1, get_china_order(1, null, null)" ><span>{{ use_language.waitSend }} ({{count_tap1}})</span></v-tab>
          <v-tab @click="tab_list = 2, get_china_order(2, null, null)" ><span>{{ use_language.ordered }} ({{count_tap2}})</span></v-tab>
          <v-tab @click="tab_list = 3, get_china_order(3, null, null)" ><span>{{ use_language.wait_receive }} ({{count_tap3}})</span></v-tab>
          <v-tab @click="tab_list = 4, get_china_order_success(4, null)" ><span>{{ use_language.success }} ({{count_tap4}})</span></v-tab>
        </v-tabs>
      </v-card>
      <v-tabs-items v-model="tab_list" class="hidden-xs-only" style="background-color: transparent;" touchless>
        <v-tab-item v-for="j in 5" :key="j">
          <div v-if="data_mhao_china_order_show.length > 0">
            <v-card elevation="0" class="v-card-bottom-30" style="background-color: ;" v-for="item_order in data_mhao_china_order_show" :key="item_order.ID">
              <v-card-title elevation="1" style="background-color: #fff;">
                <v-icon size="20" color="info ">fa-box-open</v-icon>
                <span class="ml-3">{{ china_order_fornat(item_order['ID']) }}</span>
                <v-spacer/>
                <v-select
                  v-if="select_lang==0"
                  v-model="item_order['StatusID_tmp']"
                  autocomplete="off"
                  :items="status_list"
                  item-key="text"
                  item-value="value"
                  hide-details
                  dense
                  outlined
                  style="max-width: 150px"
                ></v-select>

                <v-select
                  v-else
                  v-model="item_order['StatusID_tmp']"
                  autocomplete="off"
                  :items="status_list_china"
                  item-key="text"
                  item-value="value"
                  hide-details
                  dense
                  outlined
                  style="max-width: 150px"
                ></v-select>


                <v-btn color="primary" dark class="ml-1" @click="update_data_china_order(item_order['ID'], item_order['StatusID'], item_order['StatusID_tmp'], item_order['TaobaoOrderID'], item_order['ChinaPost'], item_order['Remark'], item_order['OrderItemList'])"> {{ use_language.save }} </v-btn>
              </v-card-title>
              <v-divider/>
              <v-card-text style="background-color: transparent;">
                <div v-if="item_order['StatusID'] != 4">
                  <v-row v-if="item_order['StatusID'] != 1">
                    <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
                      {{ use_language.StartDateOrder }}
                    </v-col>
                    <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
                      <span text style="color: #2196f3;"><b>{{ item_order['StartOrderDate'] }}</b></span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
                      <span>{{ use_language.taobao_order_id }} : </span>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
                      <v-text-field
                        v-model="item_order['TaobaoOrderID']"
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
                      <span>{{ use_language.china_post }} : </span>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
                      <v-text-field
                        v-model="item_order['ChinaPost']"
                        outlined
                        type="number"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
                      <span>{{ use_language.remark }} : </span>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
                      <v-text-field
                        v-model="item_order['Remark']"
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <v-row class="mt-5">
                  <v-col cols="12" class="py-0">
                    <v-card-title elevation="1" style="background-color: #fff;" class="pa-0">
                      {{ use_language.dataProduct }}
                      <v-spacer/>
                    </v-card-title>
                  </v-col>
                  <!-- แสดง tap 1 2 3 -->
                  <v-col cols="12" v-if=" item_order['StatusID'] != 3 && item_order['StatusID'] != 4">
                    <v-data-table :headers="[
                      { text: use_language.tb_no, align: 'center', sortable: false, value: 'num', width:'80px' },
                      { text: use_language.tb_image, align: 'center', sortable: false, value: 'PhotoLink', width:'80px' },
                      { text: use_language.tb_link, align: 'left', value: 'ProductLink', sortable: false, width: '320px' },
                      { text: use_language.tb_product, align: 'left', value: 'stock_name', sortable: false, width: '180px' },
                      { text: use_language.tb_description, align: 'left', value: 'Description', sortable: false, width: '180px' },
                      { text: use_language.tb_unit_price, align: 'right', value: 'UnitPrice', sortable: false, width: '80px' },
                      { text: use_language.tb_quantity, align: 'right', value: 'quantity', sortable: false, width: '80px' },
                      { text: use_language.tb_quantity_ordered, align: 'right', value: 'Quantity_Ordered', sortable: false, width: '80px' },
                    ]"
                     :items="item_order.OrderItemList" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                      <template v-slot:item.num="{ item }">
                        {{ item_order.OrderItemList.indexOf(item) + 1 }}
                      </template>
                      <template v-slot:item.PhotoLink="{ item }">
                        <v-img style="margin:5px;"
                            :src="item.PhotoLink"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            width="50"
                            height="50"
                            @click="image_click(item.PhotoLink)"
                        ></v-img>
                      </template>
                      <template v-slot:item.ProductLink="{ item }">
                        <a class="pa-0" text color="primary" :href="item.ProductLink" target="_blank"  v-if="item.ProductLink != null">{{ item.ProductLink }}</a>
                      </template>
                      <template v-slot:item.stock_name="{ item }">
                        <span>{{ item.stock_name }}</span>
                        <span class="color-b3" v-if="item.Prop1_description != null && item.Prop1_description != '' "> {{ " / " + item.Prop1_description }} </span>
                        <span class="color-b3" v-if="item.Prop2_description != null && item.Prop2_description != '' "> {{ " / " + item.Prop2_description }} </span>
                      </template>
                      <template v-slot:item.Description="{ item }">
                        <v-text-field
                          v-model="item.Description"
                          outlined
                          hide-details
                        ></v-text-field>
                      </template>
                      <template v-slot:item.UnitPrice="{ item }">
                        <v-text-field
                          v-if="item_order['StatusID'] == 1"
                          v-model="item.UnitPrice"
                          outlined
                          type="number"
                          hide-details
                        ></v-text-field>
                        <span v-else>{{ format_price(item.UnitPrice) }}</span>
                      </template>
                      <template v-slot:item.quantity="{ item }">
                        <span>{{ format_number(item.quantity) }}</span>
                      </template>
                      <template v-slot:item.Quantity_Ordered="{ item }">
                        <v-text-field
                          v-if="item_order['StatusID'] == 1"
                          v-model="item.Quantity_Ordered"
                          outlined
                          type="number"
                          hide-details
                        ></v-text-field>
                        <span v-else>{{ format_number(item.Quantity_Ordered) }}</span>
                      </template>
                    </v-data-table>
                  </v-col>
                  <!-- แสดง tap 3 -->
                  <v-col cols="12" v-if="item_order['StatusID'] == 3 || item_order['StatusID'] == 4">
                    <v-data-table :headers="[
                      { text: use_language.tb_no, align: 'center', sortable: false, value: 'num', width:'80px' },
                      { text: use_language.tb_image, align: 'center', sortable: false, value: 'PhotoLink', width:'80px' },
                      { text: use_language.tb_link, align: 'left', value: 'ProductLink', sortable: false, width: '320px' },
                      { text: use_language.tb_product, align: 'left', value: 'stock_name', sortable: false, width: '180px' },
                      { text: use_language.tb_description, align: 'left', value: 'Description', sortable: false, width: '180px' },
                      { text: use_language.tb_unit_price, align: 'right', value: 'UnitPrice', sortable: false, width: '80px' },
                      { text: use_language.tb_quantity, align: 'right', value: 'quantity', sortable: false, width: '80px' },
                      { text: use_language.tb_quantity_ordered, align: 'right', value: 'Quantity_Ordered', sortable: false, width: '80px' },
                      { text: use_language.tb_quantity_received, align: 'right', value: 'Quantity_Received', sortable: false, width: '80px' },
                    ]"
                     :items="item_order.OrderItemList" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                      <template v-slot:item.num="{ item }">
                        {{ item_order.OrderItemList.indexOf(item) + 1 }}
                      </template>
                      <template v-slot:item.PhotoLink="{ item }">
                        <v-img style="margin:5px;"
                            :src="item.PhotoLink"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            width="50"
                            height="50"
                            @click="image_click(item.PhotoLink)"
                        ></v-img>
                      </template>
                      <template v-slot:item.stock_name="{ item }">
                        <span>{{ item.stock_name }}</span>
                        <span class="color-b3" v-if="item.Prop1_description != null && item.Prop1_description != '' "> {{ " / " + item.Prop1_description }} </span>
                        <span class="color-b3" v-if="item.Prop2_description != null && item.Prop2_description != '' "> {{ " / " + item.Prop2_description }} </span>
                      </template>
                      <template v-slot:item.Description="{ item }">
                        <v-text-field
                          v-model="item.Description"
                          outlined
                          hide-details
                        ></v-text-field>
                      </template>
                      <template v-slot:item.UnitPrice="{ item }">
                        <span>{{ format_price(item.UnitPrice) }}</span>
                      </template>
                      <template v-slot:item.quantity="{ item }">
                        <span>{{ format_number(item.quantity) }}</span>
                      </template>
                      <template v-slot:item.Quantity_Ordered="{ item }">
                        <span>{{ format_number(item.Quantity_Ordered) }}</span>
                      </template>
                      <template v-slot:item.Quantity_Received="{ item }">
                        <v-text-field
                          v-if="item_order['StatusID'] == 3"
                          v-model="item.Quantity_Received"
                          outlined
                          type="number"
                          hide-details
                        ></v-text-field>
                        <span v-else>{{ item.Quantity_Received }}</span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>

                <v-row class="mt-5" v-if="(item_order['StatusID'] == 2 || item_order['StatusID'] == 3 || item_order['StatusID'] == 4)">
                  <v-col cols="12" class="py-0">
                    <v-card-title elevation="1" style="background-color: #fff;" class="pa-0">
                      {{ use_language.dataTrackingNo }}
                      <v-spacer/>
                      <v-btn v-if="item_order['StatusID'] != 4" outlined color="primary" @click=" clear_data_tracking(), show_dialog_add_tracking(item_order['ID'])"><v-icon left size="18">fa-plus-circle</v-icon> {{ use_language.add }}</v-btn>
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" v-if="item_order['StatusID'] == 4">
                    <v-data-table :headers="[
                      { text: use_language.tb2_no, align: 'center', sortable: false, value: 'num', width:'30px' },
                      { text: use_language.tb2_tracking_no, align: 'center', sortable: false, value: 'TrackingNo', width:'80px' },
                      { text: use_language.tb2_shipdate, align: 'center', value: 'ShipDate', sortable: false, width: '180px' },
                      { text: use_language.tb2_created, align: 'center', value: 'Created', sortable: false, width: '180px' },
                      { text: use_language.tb2_created_by, align: 'left', value: 'CreatedBy', sortable: false, width: '180px' },
                      { text: use_language.tb2_remark, align: 'left', value: 'Remark', sortable: false, width: '180px' },
                    ]"
                     :items="item_order.TrackingItemList" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                      <template v-slot:item.num="{ item }">
                        {{ item_order.TrackingItemList.indexOf(item) + 1 }}
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-data-table :headers="[
                      { text: '', align: 'center', sortable: false, value: 'delete', width:'20px' },
                      { text: use_language.tb2_no, align: 'center', sortable: false, value: 'num', width:'30px' },
                      { text: use_language.tb2_tracking_no, align: 'center', sortable: false, value: 'TrackingNo', width:'80px' },
                      { text: use_language.tb2_shipdate, align: 'center', value: 'ShipDate', sortable: false, width: '180px' },
                      { text: use_language.tb2_created, align: 'center', value: 'Created', sortable: false, width: '180px' },
                      { text: use_language.tb2_created_by, align: 'left', value: 'CreatedBy', sortable: false, width: '180px' },
                      { text: use_language.tb2_remark, align: 'left', value: 'Remark', sortable: false, width: '180px' },
                    ]"
                     :items="item_order.TrackingItemList" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                      <template v-slot:item.delete="{ item }">
                        <v-btn icon color="primary" @click="delete_data_tracking_alert(item.ID)"><v-icon size="18">fa-trash-alt</v-icon></v-btn>
                      </template>
                      <template v-slot:item.num="{ item }">
                        {{ item_order.TrackingItemList.indexOf(item) + 1 }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </div>

          <div v-else>
            <v-card style="height: 50px">
              <v-card-text>
                <span>{{ use_language.nodata }}</span>
              </v-card-text>
            </v-card>
          </div>

          <!-- pageination -->
          <v-row  class="mx-0" v-if="tab_list == 0 || tab_list == 4">
            <v-col cols="12" align="center">
              <div class="text-center" @click="changePage()">
                <v-pagination
                  v-model="page"
                  :length="pageLength"
                  :total-visible="7"
                  color="primary"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog v-model="dialog_tracking" :fullscreen="isFullscreen"  scrollable max-width="45%" persistent >
        <v-card style="background: #f0f0f0" class="my-0">
          <v-card-title >
            <span>{{ use_language.add_tracking_no }}</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog_tracking = false, IsReadonly = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row row >
              <v-col class="pb-0" cols="12" md="12">
                <v-row row >
                  <v-col class="pb-0" cols="12" md="3">
                  <h4>  {{ use_language.tracking_no }} :</h4>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="9">
                    <v-text-field
                      ref="add_TrackingNo"
                      v-model="add_TrackingNo"
                      outlined
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col class="pb-0" cols="12" md="12">
                <v-row row >
                  <v-col class="pb-0" cols="12" md="3">
                  <h4>  {{ use_language.ShipDate  }} :</h4>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="5">
                    <v-menu
                      ref="menu_shipdate"
                      v-model="menu_shipdate"
                      :close-on-content-click="false"
                      :return-value.sync="add_ShipDate"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="computed_shipdate"
                          @change="value => computed_shipdate = value"
                          autocomplete="off"
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          style="width: 220px;"
                          readonly
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="add_ShipDate" @input="menu_shipdate = false, $refs.menu_shipdate.save(add_ShipDate)" no-title :allowed-dates="allowedDates" class="my-0">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time1"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time1"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          outlined
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        v-if="menu1"
                        v-model="time1"
                        full-width
                        @click:minute="$refs.menu1.save(time1)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col class="pb-0" cols="12" md="12">
                <v-row row >
                  <v-col class="pb-0" cols="12" md="3">
                  <h4>  {{ use_language.remark }} :</h4>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="9">
                    <v-textarea
                      ref="add_Remark"
                      v-model="add_Remark"
                      filled
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col class="pb-0" cols="12" md="12">
                <v-row row >
                  <v-col class="pb-0" cols="12" md="3"></v-col>
                  <v-col class="pb-0" cols="12" md="9" align="right">
                    <v-btn color="primary" @click="add_data_tracking()"><v-icon left size="18">fa-save</v-icon> {{ use_language.save }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_photo" width="500">
        <v-card class="my-0">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-img :src="dialog_photo_link" aspect-ratio="1.5" contain></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
</template>

<script>
    import * as easings from 'vuetify/es5/services/goto/easing-patterns'
    import Loading from '@/website/components/Loading'
    import axios from 'axios'
    import { mhaoService } from '@/website/global'
    import { format_number, format_order_id, format_price, isNumberWNoDot, formatMoney, china_order_fornat,get_languages } from '@/website/global_function'



    export default {
        components: {
            Loading,
            // 'barcode': VueBarcode
        },

        data: () => ({
            page_loading: true,
            loading: false,
            use_language: null,
            set_language: null,

            // ค้าหา
            ch_TaobaoOrderID : null,
            ch_ChinaOrderID : null,
            ch_TrackingNo: null,

            // แก้ไข
            // edit_china_order_id: null,

            // card
            tab_list: 1,
            status_list: [
              {"value": 1, "text": "รอสั่ง"},
              {"value": 2, "text": "สั่งแล้ว"},
              {"value": 3, "text": "รอรับสินค้า"},
              {"value": 4, "text": "สำเร็จ"},
            ],
            status_list_china: [
              {"value": 1, "text": "等待定购"},
              {"value": 2, "text": "已定购"},
              {"value": 3, "text": "等待收货"},
              {"value": 4, "text": "成功"},
            ],
            data_mhao_china_order: [],
            data_mhao_china_order_show: [],

            //  รูป
            dialog_photo: false,
            dialog_photo_link: null,


            // เปลี่ยนสถานะ
            chang_status_id : null,


            // ค้นหา
            data_param: null,

            // นับจำนวนข้อมูลใน tap
            count_tap1: 0,
            count_tap2: 0,
            count_tap3: 0,
            count_tap4: 0,

            // add dialog_tracking
            dialog_tracking: false,
            isFullscreen : false,

            add_tap: null,
            add_china_order_id: null,
            add_TrackingNo: null,
            // add_ShipDate: null,
            add_Remark: null,

            menu_shipdate: null,
            add_ShipDate: null,

            // time picker
            time1: '00:00',
            menu1: false,

            // paginations
            isPage: 0,
            page: 1,
            page_tmp: null,
            limits: 20,
            offsets: 0,
            pageLength: null,

            // เปลี่ยนภาษา
            select_lang: 1, //0 = thai 1 = china
            lang:[
              {
                "order": "ออเดอร์",
                "orderList": "รายการสั่งซื้อสินค้า",
                "searchList": "ค้นหารายการ",
                "reload": "รีโหลด",
                "TaobaoOrderID": "TaobaoOrderID",
                "ChinaOrderID": "ChinaOrderID",
                "TrackingNo": "TrackingNo",
                "search": "ค้นหา",
                "dataProduct": "ข้อมูลสินค้า",
                "dataTrackingNo": "ข้อมูล TrackingNo",
                "total": "ทั้งหมด",
                "waitSend": "รอสั่ง",
                "ordered": "สั่งแล้ว",
                "WaitReceive": "รอรับสินค้า",
                "Success": "สำเร็จ",
                "ShipDate": "ShipDate",
                "ChinaPost":"ChinaPost",
                "Remark": "Remark",
                "AddTrackingNo": "เพิ่ม TrackingNo",
                "save": "บันทึก",
                "add": "เพิ่ม",
                "tb_No": "ลำดับ",
                "tb_image": "รูป",
                "tb_link": "ลิงค์",
                "tb_product": "สินค้า",
                "tb_Description": "หมายเหตุ",
                "tb_UnitPrice": "ราคา (UnitPrice)",
                "tb_Quantity": "จำนวนที่สั่ง",
                "tb_Quantity_Ordered": "จำนวนที่สั่งจริง",
                "tb_Quantity_Received": "จำนวนที่รับจริง",
                "tb2_No": "ลำดับ",
                "tb2_TrackingNo": "TrackingNo",
                "tb2_ShipDate": "ShipDate",
                "tb2_Created": "Created",
                "tb2_CreatedBy": "CreatedBy",
                "tb2_Remark": "Remark",
                "NoData": "ไม่มีข้อมูล !!",
                "StartDateOrder": "วันที่เริ่มสั่งสินค้า",
                "ChinaOrder": "ChinaOrder",
                "ChinaPacking": "ChinaPacking",
              },
              {
                "order": "订单",
                "orderList": "购买订单",
                "searchList": "查询",
                "reload": "刷新",
                "TaobaoOrderID": "TaobaoOrderID",
                "ChinaOrderID": "ChinaOrderID",
                "TrackingNo": "运单号",
                "search": "搜索",
                "dataProduct": "产品信息",
                "dataTrackingNo": "运单号信息",
                "total": "总共",
                "waitSend": "待定购",
                "ordered": "已定购",
                "WaitReceive": "等待收货",
                "Success": "成功",
                "ShipDate": "发货日期",
                "ChinaPost":"运费",
                "Remark": "备注",
                "AddTrackingNo": "添加运单号",
                "save": "保存",
                "add": "添加",
                "tb_No": "顺数",
                "tb_image": "图片",
                "tb_link": "链接",
                "tb_product": "产品",
                "tb_Description": "备注",
                "tb_UnitPrice": "单价",
                "tb_Quantity": "数量",
                "tb_Quantity_Ordered": "实际购买",
                "tb_Quantity_Received": "实际收到",
                "tb2_No": "顺数",
                "tb2_TrackingNo": "运单号",
                "tb2_ShipDate": "发货日期",
                "tb2_Created": "制作时间",
                "tb2_CreatedBy": "制作人",
                "tb2_Remark": "备注",
                "NoData": "无数据 !!",
                "StartDateOrder": "创建日期",
                "ChinaOrder": "中国订单号",
                "ChinaPacking": "中国运单号",
                }
            ],
        }),

        computed: {
          computed_shipdate() {
            return this.formatDates(this.add_ShipDate)
          },
        },

        watch : {

        },


        async created() {

           //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));



            this.shop_id = localStorage.getItem("shop_id")
            this.staff_id = localStorage.getItem("staff_id")
            if(this.shop_id != 117){
              this.$router.push('/order')
            }
            await this.get_china_order(1, null, null)

            this.page_loading = false
        },

        methods: {
            format_number,
            format_order_id,
            format_price,
            isNumberWNoDot,
            formatMoney,
            china_order_fornat,
            get_languages,
            allowedDates: val => val <= new Date().toISOString().substr(0, 10),

            image_click(photo_link) {
                this.dialog_photo_link = photo_link
                this.dialog_photo = true
            },

            formatDates (date) {
              if (date == null){
                return null
              } else {
                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
              }
            },

            async  get_china_order (statusId, data_param, offset) {
              if(statusId == 0){statusId = null}
              this.loading = true
              let response = await axios.post(mhaoService+'mhao/get_china_order', {
                'status_id': statusId,
                'data_param': this.data_param,
                'offset': offset
              })
              if (response.data.status == 'Success'){
                this.data_mhao_china_order = []
                this.data_mhao_china_order_show = []
                this.data_mhao_china_order_show = response.data.result
                if(statusId == null){
                  this.getpagelength(response.data.count_status['All'])
                }
                this.count_tap1 = response.data.count_status['StatusID1']
                this.count_tap2 = response.data.count_status['StatusID2']
                this.count_tap3 = response.data.count_status['StatusID3']
                this.count_tap4 = response.data.count_status['StatusID4']
              }
              this.loading = false
             },

              async  get_china_order_success (statusId, offset) {
                var data_param = {
                  "shop_id" : this.shop_id,
                  "status_id" : statusId,
                  "offset" : offset,
                  "limit" : null,
                }

                this.loading = true
                let response = await axios.post(mhaoService+'mhao/get_china_order_success', {
                  'data_param': data_param
                })
                if (response.data.status == 'Success'){
                  this.data_mhao_china_order = []
                  this.data_mhao_china_order_show = []
                  this.data_mhao_china_order_show = response.data.result
                  this.getpagelength(response.data.count_status['StatusID4'])

                  this.count_tap1 = response.data.count_status['StatusID1']
                  this.count_tap2 = response.data.count_status['StatusID2']
                  this.count_tap3 = response.data.count_status['StatusID3']
                  this.count_tap4 = response.data.count_status['StatusID4']
                }
                this.loading = false
               },

               getpagelength (total_order_master) {
                 var total = 0
                 if(total_order_master != 0){
                   total = total_order_master
                 } else {
                   total = 1
                 }

                 total = parseInt(total)
                 this.pageLength = Math.ceil(total/this.limits)
                 this.isPage = 0
                 this.page_tmp = this.page
               },

               async changePage () {
                 this.loading = true
                 if(this.tab_list != 0){
                   this.offsets = (this.page*this.limits)-this.limits
                   if (this.page > 1 && this.page < this.pageLength ) {
                     if(this.page_tmp != this.page){
                       await this.get_china_order_success(4, this.offsets)
                       this.page_tmp = this.page
                     }
                   } else if(this.page == 1,this.pageLength ) {
                     if(this.page_tmp != this.page){
                       await this.get_china_order_success(4 ,this.offsets)
                       this.page_tmp = this.page
                     }
                   }
                 } else {
                   this.offsets = (this.page*this.limits)-this.limits
                   if (this.page > 1 && this.page < this.pageLength ) {
                     if(this.page_tmp != this.page){
                       await this.get_china_order(null, this.data_param ,this.offsets)
                       this.page_tmp = this.page
                     }
                   } else if(this.page == 1,this.pageLength ) {
                     if(this.page_tmp != this.page){
                       await this.get_china_order(null, this.data_param ,this.offsets)
                       this.page_tmp = this.page
                     }
                   }
                 }

                 this.loading = false
               },
             Searching () {
               if(this.ch_TaobaoOrderID == ''){this.ch_TaobaoOrderID = null}
               if(this.ch_ChinaOrderID == ''){this.ch_ChinaOrderID = null}
               if(this.ch_TrackingNo == ''){this.ch_TrackingNo = null}
               this.data_param = {
                 "TaobaoOrderID": this.ch_TaobaoOrderID,
                 "ChinaOrderID": this.ch_ChinaOrderID,
                 "TrackingNo": this.ch_TrackingNo
               }
               this.tab_list = 0

               this.get_china_order(null, this.data_param, null)
             },

            async  update_data_china_order (edit_china_order_id, StatusID, StatusID_tmp, TaobaoOrderID, ChinaPost, Remark, OrderItemList) {

               var edit_data_param = {
                 "StatusID_tmp": StatusID_tmp,
                 "StatusID": StatusID,
                 "edit_china_order_id" : edit_china_order_id,
                 "TaobaoOrderID": TaobaoOrderID,
                 "ChinaPost": ChinaPost,
                 "Remark": Remark,
                 "staff_id": this.staff_id,
                 "shop_id": this.shop_id,
                 "OrderItemList": OrderItemList,
               }

               this.loading = true
               let response = await axios.post(mhaoService+'mhao/update_data_china_order', {
                 'edit_data_param': edit_data_param
               })
               if (response.data.status == 'Success'){
                 this.get_china_order(this.tab_list, this.data_param, null)
               }
               this.loading = false
              },

              show_dialog_add_tracking (china_id) {
                this.add_china_order_id = china_id
                this.dialog_tracking = true
                this.add_tap = this.tab_list
                this.add_ShipDate = new Date().toISOString().substr(0, 10)
              },

              clear_data_tracking () {
                this.add_china_order_id = null
                this.add_TrackingNo = null
                this.add_ShipDate = null
                this.add_Remark = null
                this.time1 = '00:00'
              },

              async add_data_tracking () {

                if (this.add_TrackingNo == null || this.add_TrackingNo == '' ) {
                  this.$refs.add_TrackingNo.focus()
                  return
                } else if ( this.add_Remark == null || this.add_Remark == ''  ) {
                  this.$refs.add_Remark.focus()
                  return
                }

                var data_tracking_param = {
                  "china_order_id":this.add_china_order_id,
                  "TrackingNo":this.add_TrackingNo ,
                  "ShipDate":this.add_ShipDate + ' ' + this.time1 + ':00' ,
                  "Remark":this.add_Remark ,
                  "staff_id": this.staff_id
                }

                this.loading = true
                let response = await axios.post(mhaoService+'mhao/add_china_order_tracking', {
                  'data_tracking_param': data_tracking_param
                })
                if (response.data.status == 'Success'){
                  this.dialog_tracking = false
                  this.get_china_order(this.tab_list, this.data_param, null)
                }
                this.loading = false

              },

              delete_data_tracking_alert (Tracking_id) {
                this.$swal({
                  position: "top",
                  type: 'warning',
                  title: 'ลบ TrackingNo นี้ใช่หรือไม่ !!',
                  confirmButtonText: 'ยืนยัน',
                  cancelButtonText: 'ยกเลิก',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  allowOutsideClick: false
                }).then(async(result) => {
                  if (result.value) {
                    this.delete_data_tracking(Tracking_id)
                  }
                })
              },

              async delete_data_tracking (Tracking_id) {
                this.add_tap = this.tab_list
                var data_tracking_param = {
                  "china_order_id":Tracking_id,
                  "staff_id": this.staff_id
                }

                this.loading = true
                let response = await axios.post(mhaoService+'mhao/delete_china_order_tracking', {
                  'data_tracking_param': data_tracking_param
                })
                if (response.data.status == 'Success'){
                  this.dialog_tracking = false
                  this.get_china_order(this.tab_list, this.data_param, null)
                }
                this.loading = false

              },

              refresh () {
                this.$router.push('/mhao-chaina-order')
                window.location.reload()
              },
        }
    }
</script>
<style scoped>
    /* >>>.v-dialog {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */

    >>>.bex48-logo {
        width: 12.533vw;
        height: 11.2vw;
        display: block;
        /* margin: 8.8vw auto 1.6vw; */
        margin: 1.6vw auto 1.6vw;
    }

    >>>.slide-fade-enter-active {
      transition: all .3s ease;
    }
    >>>.slide-fade-leave-active {
      transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    >>>.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateY(10px);
      opacity: 0;
    }

    .packhai-border-table{
      border:1px solid rgba(0, 0, 0, 0.12);
      border-radius:4px;
    }
    .packhai-border-table thead{
      color:white;
    }
    .packhai-border-table thead tr th{

      font-weight: bold;
      font-size: 1rem;
    }
    .return-order-table{
      width: 100%;
    }
    .padding-for-order-dialog{
      padding: 0px !important ;
    }
    @media screen and (min-width: 768px) {
      .return-order-table {
        width: 300px;
      }

      .padding-for-order-dialog{
        padding: 15px !important;
      }
    }

    .packhai-border-preview {
      border-top: solid 1px black;
      border-left: solid 1px black;
      border-right: solid 1px black;
      border-bottom: solid 1px black;
      border-radius: 10px ;
    }

    .px-15{
      padding-left: 15px;
      padding-right:  15px;
    }

    .fz-12{
      font-size: 12px !important;
    }

    .fz-14{
      font-size: 14px !important;
    }

</style>
