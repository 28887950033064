<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>

      <loading :loading="loading" />

      <v-card class="v-card-bottom-30">
        <v-card-title>
          <h4>{{use_language.order}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.info_product_mhao}} </span>
          <v-spacer/>
          <v-btn color="primary" @click="OrderSelected()"><v-icon left>fa-cart-plus</v-icon> {{use_language.order_}}</v-btn>
        </v-card-title>
      </v-card>

      <v-card class="v-card-bottom-30">
        <v-card-title>
         <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="py-2 ml-3">{{use_language.search}}</span>
         <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
              <span>{{use_language.china_order_id}} : </span>
            </v-col>
            <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
              <v-text-field
                v-model="ch_ChinaOrderID"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="2" xl="1" class="pb-0">
              <span>{{use_language.status}} : </span>
            </v-col>
            <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
              <v-radio-group
                v-model="ch_status_chinaOrderItemID"
                row
              >
                <v-radio
                  label="ยังไม่สั่ง"
                  value="and oi.ChinaOrderItemID IS NULL"
                >
                    <template v-slot:label> <span>{{use_language.did_not_order}}</span> </template> 
                </v-radio>
                <v-radio
                  label="สั่งแล้ว"
                  value="and oi.ChinaOrderItemID IS NOT NULL"
                >                
                    <template v-slot:label> <span>{{use_language.ordered}}</span> </template> 
                 </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="2" xl="1" class="pb-0"></v-col>
            <v-col cols="12" md="3" lg="2" xl="2" class="pb-0">
              <v-btn @click="get_items_order_mhao_mhao()" color="primary" class="mr-3">{{use_language.search}}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="0" class="v-card-bottom-30" style="background-color: ;" v-for="item_order in data_mhao_mhao" :key="item_order.stock_id">
        <v-card-title elevation="1" style="background-color: #fff;">
          <v-icon size="20" color="info ">fa-box-open</v-icon>
          <span class="ml-3">{{item_order.stock_name}}</span>
          <span class="color-b3" v-if="item_order.Prop1_description != null && item_order.Prop1_description != '' "> {{ " / " + item_order.Prop1_description }} </span>
          <span class="color-b3" v-if="item_order.Prop2_description != null && item_order.Prop2_description != '' "> {{ " / " + item_order.Prop2_description }} </span>
          <span class="mx-2 text-primary">/ {{use_language.balance}} : {{ item_order.stock_have }} {{use_language.item}}</span>
          <!-- <span class="mx-2 text-info">/ กำลังสั่ง : 0 ชิ้น</span> -->
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text style="background-color: transparent;">
          <v-row>
            <v-col cols="12">
              <v-data-table v-model="selected" :single-select="singleSelect"  item-key="item_id"  show-select :headers="headers" :items="item_order.order_list" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                <template v-slot:item.ChinaOrderItemID="{ item }">
                   <span v-if="item.ChinaOrderItemID != null" class="text-info" > <h3>{{ china_order_fornat(item.ChinaOrderItemID) }}</h3> </span>
                </template>

                <template v-slot:item.num="{ item }">
                  {{ item_order.order_list.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.mh_status="{ item }">
                  {{item.mh_status}}
                </template>
                <template v-slot:item.order_id="{ item }">
                  {{ format_order_id(item.order_id) }}
                </template>
                <template v-slot:item.status="{ item }">
                   <v-btn v-if="item.ChinaOrderItemID != null"  @click="openMhaoDialog(item.ChinaOrderItemID, item.mh_status)" color="primary">{{use_language.status}}</v-btn>
                </template>
                <template v-slot:item.status_to_thai="{ item }">
                   <v-btn v-if="item.mh_status != 'สินค้าถึงไทย' "  @click="status_arrived_thai_in_stock(item)" color="primary">{{use_language.products_to_Thailand_have_already}}</v-btn>
                </template>

              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div v-if="data_mhao_mhao.length == 0" align="center">
        <span>{{use_language/no_information_found}}</span>
      </div>


      <v-dialog v-model="mhaoDialog" scrollable max-width="600px" persistent >
        <v-card elevation="1" class="ma-0">
          <v-card-title class="primary">
            <span style="color:white;">{{use_language.notify_mhao_mhao_status}}</span>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="mhaoDialog = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text style="height: 300px;">
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"><span> <b> {{use_language.status}} : </b></span></v-col>
              <v-col cols="12" md="9" xl="9" lg="9">
                <!-- {{ mh_selectStatus }} -->
                <v-select
                  v-model="mh_selectStatus"
                  ref="mh_selectStatus"
                  :items="mh_itemsStatus"
                  item-key="text"
                  item-value="value"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"> <span><b>  {{use_language.status_change_date}} : </b></span></v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      :value="startDate"
                      @change="value => startDate = value"
                      autocomplete="off"
                      prepend-inner-icon="mdi-calendar"
                      label="วันที่"
                      readonly
                      v-on="on"
                      hide-details
                      style="width: 290px;"
                    >
                    <template v-slot:label> <span>{{use_language.date}}</span> </template> 
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="pb-0">
                <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="time"
                        @change="value => time = value"
                        autocomplete="off"
                        label="เวลา"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >                    
                      <template v-slot:label> <span>{{use_language.time}}</span> </template> 
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="time"
                      full-width
                      @click:minute="$refs.menu.save(time)"
                    ></v-time-picker>
                  </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" xl="3" lg="3"></v-col>
              <v-col cols="12" md="9" xl="9" lg="9">
                <v-btn color="primary" @click="mh_notification_status()">{{use_language.status_notification}}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>


      <v-dialog v-model="dialog_checkbox_items" scrollable max-width="1200px" persistent >
        <v-card elevation="1" class="ma-0">
          <v-card-title class="primary">
            <span style="color:white;">{{use_language.number_products_ordered}}</span>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog_checkbox_items = false, selected=[], selected_tmp=[] ,get_items_order_mhao_mhao()"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text style="height: 700px;">
            <v-data-table :headers="header_checkbox" :items="this.selected_tmp" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
              <template v-slot:item.num="{ item }">
                {{ selected_tmp.indexOf(item) + 1 }}
              </template>

              <template v-slot:item.order_mhao_name="{ item }">
                <span class="ml-3">{{item.order_mhao_name}}</span>
                <span class="color-b3" v-if="item.Prop1_description != null && item.Prop1_description != '' "> {{ " / " + item.Prop1_description }} </span>
                <span class="color-b3" v-if="item.Prop2_description != null && item.Prop2_description != '' "> {{ " / " + item.Prop2_description }} </span>
              </template>

              <template v-slot:item.Description="{ item }">
                <v-text-field
                  ref="Description"
                  v-model="item.Description"
                  outlined
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:item.ProductLink="{ item }">
                <v-text-field
                  ref="ProductLink"
                  v-model="item.ProductLink"
                  outlined
                  hide-details
                ></v-text-field>
                <!-- <span v-if="(item.ProductLink == null && ProductLink_Valid == true) || item.ProductLink == '' && ProductLink_Valid == true">กรุณากรอก link สินค้า</span> -->
              </template>
              <template v-slot:item.quantity="{ item }">
                <!-- {{ format_number(item.quantity) }} -->
                <v-text-field
                  ref="quantity"
                  v-model="item.quantity"
                  type="number"
                  outlined
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text align="right">
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="add_order_chaina()"><v-icon left>fa-shopping-cart</v-icon>{{use_language.order_}}</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
</template>

<script>
    import * as easings from 'vuetify/es5/services/goto/easing-patterns'
    import Loading from '@/website/components/Loading'
    import axios from 'axios'
    import { mhaoService } from '@/website/global'
    import { format_number, format_order_id, format_price, isNumberWNoDot, formatMoney, china_order_fornat,get_languages} from '@/website/global_function'
    // import { shippingStatusDict } from '@/website/statusDict'
    // import VueBarcode from 'vue-barcode'

   export default {
        components: {
            Loading,
            // 'barcode': VueBarcode
        },

        data: () => ({
            page_loading: true,
            loading: false,
            use_language: null,
            set_language: null,

            singleSelect: false,
            selected: [],
            selected_tmp: [],

            headers: [
              { text: 'เลขใบสั่งซื้อ', align: 'center', sortable: false, value: 'ChinaOrderItemID', width:'120px' },
              { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
              { text: 'เลขออเดอร์', align: 'left', sortable: false, value: 'order_id' },
              { text: 'ID สินค้าเหมาๆ', align: 'left', value: 'order_mhao_id', sortable: false, width:'170px' },
              { text: 'สินค้า', align: 'left', value: 'order_mhao_name', sortable: false, width:'350px' },
              { text: 'จำนวน', align: 'right', value: 'quantity', sortable: false, width: '80px' },
              { text: 'ราคา', align: 'right', value: 'unit_price', sortable: false, width:'120px' },
              { text: 'สถานะ', align: 'center', value: 'mh_status', sortable: false, width:'120px' },
              { text: 'วันที่แจ้ง', align: 'center', value: 'mh_status_date', sortable: false, width:'120px' },
              { text: 'วันที่อัพเดท', align: 'center', value: 'mh_update_date', sortable: false, width:'120px' },
              { text: '', align: 'center', value: 'status', sortable: false, width:'70px' },
              { text: '', align: 'center', value: 'status_to_thai', sortable: false, width:'70px' },
            ],
            data_mhao_mhao: [],

            // dialog_checkbox_items
            header_checkbox : [
              { text: 'ลำดับ', align: 'center', sortable: false, value: 'num', width:'80px' },
              { text: 'สินค้า', align: 'left', value: 'order_mhao_name', sortable: false },
              { text: 'หมายเหตุ', align: 'left', value: 'Description', sortable: false, width: '320px' },
              { text: 'ลิงค์', align: 'left', value: 'ProductLink', sortable: false, width: '320px' },
              { text: 'จำนวน', align: 'right', value: 'quantity', sortable: false, width: '120px' },
            ],
            dialog_checkbox_items: false,

            // แจ้งสถานะ เหมาๆ
            mhaoDialog: false,
            ChinaOrderItemID: null,
            row_id : null,
            mh_line_item_id: null,

            mh_selectStatus : null,
            mh_itemsStatus :[
              {text: 'กรุณาเลือกสถานะ', value: null},
              {text: 'สั่งร้านจีนเรียบร้อย', value: 2},
              {text: 'ร้านค้าจีนส่งออก', value: 3},
              {text: 'สินค้าเข้าโกดังจีน', value: 4},
            ],

            // เปิด เลือกวันที่
            startDate: null,
            menuStartDate: false,
            checkStart: true,

            time: null,
            menu2: false,
            modal2: false,

            ProductLink_Valid: false,

            // ค้นหา
            data_param: null,
            ch_ChinaOrderID: null,
            ch_status_chinaOrderItemID: "and oi.ChinaOrderItemID IS NULL",

        }),

        computed: {

        },

        watch : {

          startDate: async function(newVal) {
            if(newVal){
              this.$refs.menuStartDate.save(this.startDate)
            }
          },
        },


        async created() {
            //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));


            this.shop_id = localStorage.getItem("shop_id")
            this.staff_id = localStorage.getItem("staff_id")
            if(this.shop_id != 117){
              this.$router.push('/order')
            }
            await this.get_items_order_mhao_mhao()
            this.page_loading = false
        },

        methods: {
            format_number,
            format_order_id,
            format_price,
            isNumberWNoDot,
            formatMoney,
            china_order_fornat,
            get_languages,
            allowedDates: val => val <= new Date().toISOString().substr(0, 10),

            formatDate (date) {
              if (date.length == 0) return null

              if (date.length == 1) {
                const [year, month, day] = date[0].split('-')
                return `${day}-${month}-${year}`
              } else {
                const [year_from, month_from, day_from] = date[0].split('-')
                const [year_to, month_to, day_to] = date[1].split('-')
                if (date[0] < date[1]) {
                  return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
                } else {
                  return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
                }
              }
            },

            set_zero (num) {
              num = parseInt(num)
              if ( num < 10 ) {
                num = '0'+num.toString()
              } else {
                num = num.toString()
              }
              return num
            },

            // status_eng_to_thai (test_eng) {
            //   if(test_eng != null){
            //     if(test_eng == 'shipping-from-chinese-shop'){
            //       return 'ร้านค้าจีนส่งมา'
            //     } else if (test_eng == 'arrive-at-chinese-warehouse') {
            //       return 'ถึงโกดังจีน'
            //     } else if (test_eng == 'shipping-from-chinese-warehouse') {
            //       return 'ส่งออกจากโกดังจีน'
            //     } else if (test_eng == 'arrive-at-thai-warehouse') {
            //       return 'ถึงโกดังไทย'
            //     }
            //   } else {
            //       return ''
            //   }
            // },



            openMhaoDialog (ChinaOrderItemID, status) {
              var date = new Date().toISOString().substr(0, 10)
              var current = new Date()
              var status_convert = null
              if(status=='สั่งแล้ว'){ status_convert = 2 }
              if(status=='รอรับสินค้า'){ status_convert = 3 }
              if(status=='สำเร็จ'){ status_convert = 4 }
              this.mh_selectStatus = status_convert
              this.ChinaOrderItemID = ChinaOrderItemID
              this.startDate = date
              this.time = this.set_zero(current. getHours().toString()) + ':' + this.set_zero(current. getMinutes().toString())

              this.mhaoDialog = true

            },

            status_arrived_thai_in_stock (item) {
              this.$swal({
                position: "top",
                type: 'warning',
                title: 'อัพเดทสถานะ "สินค้าถึงไทย" !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
              }).then(async(result) => {
                if (result.value) {
                  this.update_status_arrived_thai_in_stock(item)
                }
              })
            },

            async update_status_arrived_thai_in_stock (item) {
              this.loading = true
              let response = await axios.post(mhaoService+'mhao/mhao_update_stock_to_thai', {
                'MhaoGuidID' : item['MhaoGuidID'],
                'mh_line_item_id': item['mh_line_item_id'],
                'status_id': 5,
                'OrderItemTempID': item['item_temp_id']
              })
              if (response.data.status == 'Success'){
                this.get_items_order_mhao_mhao()
              }
              this.loading = false
            },


            async  get_items_order_mhao_mhao (payment_id) {
              var ch_ChinaOrderID_tem = null
              if (this.ch_ChinaOrderID == ''){
                 ch_ChinaOrderID_tem = null
               } else {
                 ch_ChinaOrderID_tem = this.ch_ChinaOrderID
               }
              this.data_param = {
                "ChinaOrderID": ch_ChinaOrderID_tem,
                "IsOrder": this.ch_status_chinaOrderItemID,
              }

              this.loading = true
              let response = await axios.post(mhaoService+'mhao/get_items_order_mhao_mhao', {
                'shop_id' : this.shop_id,
                'data_param': this.data_param
              })
              if (response.data.status == 'Success'){
                this.data_mhao_mhao = response.data.result
              }
              this.loading = false
             },

             async mh_notification_status () {
                if (this.mh_selectStatus == null){
                  this.$refs.mh_selectStatus.focus()
                  return
                }
                this.loading = true
                var full_date = this.startDate + ' ' + this.time

                let response = await axios.post(mhaoService+'mhao/mh_notification_status', {
                    'ChinaOrderItemID': this.ChinaOrderItemID,
                    'status_date': full_date,
                    'mh_selectStatus': this.mh_selectStatus
                })
                if (response.data.status == 'Success') {
                  if(response.data.message == null){
                    this.mhaoDialog = false
                    this.loading = false
                    this.get_items_order_mhao_mhao()
                  } else {
                    this.$swal({
                      type: 'warning',
                      title: 'แจ้งสถานะไม่สำเร็จ !!',
                      text: 'กรุณาลองใหม่',
                    })
                  }
                }
                this.loading = false
              },

            OrderSelected () {
              if (this.selected.length == 0 ){
                return
              }
              var data_tmp = []
              // this.selected_tmp = [...this.selected]
              this.loading = true
              for(var x=0; x < this.selected.length; x++) {
                if(this.selected[x]['ChinaOrderItemID'] == null){
                  if (this.selected[x]['quantity'] == 0){
                    this.selected[x]['quantity'] = 1
                  }

                  var Isfound = false
                  for(var k=0; k < data_tmp.length; k++) {
                    if(data_tmp[k]['stock_id'] == this.selected[x]['stock_id']) {
                      data_tmp[k]['quantity'] = parseInt(data_tmp[k]['quantity']) + parseInt(this.selected[x]['quantity'])
                      data_tmp[k]['items'].push(this.selected[x]['item_id'])
                      Isfound = true
                    }
                  }

                  if(Isfound == false) {
                    data_tmp.push(this.selected[x])
                    data_tmp[data_tmp.length-1]['items'] = []
                    data_tmp[data_tmp.length-1]['items'].push(this.selected[x]['item_id'])
                  }
                }
              }

              this.selected_tmp = data_tmp

              if (this.selected_tmp.length == 0 ){
                this.loading = false
                return
              } else {
                this.loading = false
                this.dialog_checkbox_items = true
              }
            },

            async add_order_chaina () {
              // this.ProductLink_Valid = true
               if(this.selected_tmp.length > 0){
                 for (var e = 0; e < this.selected_tmp.length; e++) {
                   if (this.selected_tmp[e]['ProductLink'] == null || this.selected_tmp[e]['ProductLink'] == ''){
                     this.$swal({
                       type: 'warning',
                       title: 'กรุณากรอก ลิงค์ สั่งซื้อสินค้า',
                     })
                     return
                   }
                 }
               }

              this.loading = true
              let response = await axios.post(mhaoService+'mhao/add_order_chaina', {
                'shop_id' : this.shop_id,
                'staff_id': this.staff_id,
                'order_data' : this.selected_tmp
              })
              if (response.data.status == 'Success'){
                this.dialog_checkbox_items = false
                this.selected_tmp=[]
                this.selected=[]
                this.get_items_order_mhao_mhao()
              }
              this.loading = false
             },
        }
    }
</script>
<style scoped>
    /* >>>.v-dialog {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */

    >>>.bex48-logo {
        width: 12.533vw;
        height: 11.2vw;
        display: block;
        /* margin: 8.8vw auto 1.6vw; */
        margin: 1.6vw auto 1.6vw;
    }

    >>>.slide-fade-enter-active {
      transition: all .3s ease;
    }
    >>>.slide-fade-leave-active {
      transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    >>>.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateY(10px);
      opacity: 0;
    }

    .packhai-border-table{
      border:1px solid rgba(0, 0, 0, 0.12);
      border-radius:4px;
    }
    .packhai-border-table thead{
      color:white;
    }
    .packhai-border-table thead tr th{

      font-weight: bold;
      font-size: 1rem;
    }
    .return-order-table{
      width: 100%;
    }
    .padding-for-order-dialog{
      padding: 0px !important ;
    }
    @media screen and (min-width: 768px) {
      .return-order-table {
        width: 300px;
      }

      .padding-for-order-dialog{
        padding: 15px !important;
      }
    }

    .packhai-border-preview {
      border-top: solid 1px black;
      border-left: solid 1px black;
      border-right: solid 1px black;
      border-bottom: solid 1px black;
      border-radius: 10px ;
    }

    .px-15{
      padding-left: 15px;
      padding-right:  15px;
    }

    .fz-12{
      font-size: 12px !important;
    }

    .fz-14{
      font-size: 14px !important;
    }

</style>
