<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>

    <loading :loading="loading" />
    <v-card class="v-card-bottom-30">
      <v-card-title>
        <h4>{{use_language.order}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1 mr-2">{{use_language.info_order}} {{ format_order_id(detail_ID) }}</span> <span v-if="this.detail_IsPreOrder == 1" class="text-info">(Pre-Order)</span>
        <v-spacer/>
        <v-btn color="primary" outlined @click="black_to_order_page()">{{use_language.close}}</v-btn>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="v-card-bottom-30" style="background-color: transparent;">

      <v-card-text class="px-0" style="background-color: transparent;">
        <v-row  v-show="this.detail_ID != ''">
          <v-col class="px-0 py-0 m-0 my-0">
            <v-card class="px-5 py-2 v-card-bottom-30" elevation="1">
              <v-row v-if="this.get_token != null " class="pb-3">
                <v-col cols="12"><h3>{{use_language.customer_url}}</h3></v-col>
                <v-col cols="12" md="10" class="pt-5">
                  <a :href="this.detail_Token" target="_blank">
                    {{ this.detail_Token }}
                    <input type="hidden" id="testing-code" style="width:100%" v-model="detail_Token"/>
                  </a>
                  <a class="pl-3" @click="copyToken()"><v-icon size="18" left>mdi-content-copy</v-icon></a>
                </v-col>


              </v-row>
            </v-card>

            <!-- คลัง และ สถานะ -->
            <div v-if="size =='xs' || size =='sm'">
              <v-card class="px-5 py-2 v-card-bottom-30" elevation="1">
                <v-row>

                  <v-col cols="6">
                    <h3>{{use_language.treasury}}</h3>
                  </v-col>
                  <v-col cols="6" class="py-0" align="right">
                    <v-btn v-if="detail_statusId < 40  "  :disabled="  !authorize" icon class="ma-0"  @click="can_edit_warehouse = !can_edit_warehouse"><v-icon size="18" >fa-edit</v-icon></v-btn>
                  </v-col>

                  <v-col cols="12"   v-if="can_edit_warehouse == true">
                    <v-row >
                      <v-col cols="12" md="4" >
                        <v-select
                          :value="Warehouse_List_selected"
                          @change="value => Warehouse_List_selected = value"
                          autocomplete="off"
                          :items="Warehouse_List"
                          item-key="id"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="8" ><v-btn color="primary" @click="shop_change_warehouse()">{{use_language.save}}</v-btn></v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12"  v-else >{{ this.Warehouse_List_selected_text }}</v-col>
                </v-row>
              </v-card>

              <v-card class="px-5 py-2 v-card-bottom-30" elevation="1" >
                <v-row>

                  <v-col cols="6">
                    <h3>{{use_language.status}}</h3>
                  </v-col>
                  <v-col cols="6" align="right">
                    <!-- <v-btn v-if="(canChangeStatusOrder == 1 && detail_statusId < 40 && detail_referenceSourceID != 3 && detail_referenceSourceID != 4 && detail_referenceSourceID != 5 && detail_referenceSourceID != 11) " color="primary" outlined class="mx-2" @click="deleteShipping()">{{use_language.cancel_order}}</v-btn> -->
                  </v-col>

                  <v-col cols="12"  v-if="AllowChangeOrderStatus">
                    <v-row  >
                      <v-col cols="12" md="4" >
                        <v-select
                          :value="detail_shiping_staus_update_select"
                          @change="value => detail_shiping_staus_update_select = value"
                          autocomplete="off"
                          :items="show_change_items_status"
                          item-key="text"
                          item-value="value"
                          label="สถานะใบสั่งซื้อ"
                          outlined
                        >
                          <template v-slot:label> <span>{{use_language.order_status}}</span> </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="8" ><v-btn color="primary" @click="shop_change_shipping_status()">{{use_language.save}}</v-btn></v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12"  v-else >
                    <span v-if="this.detail_statusId == 10" style="color: black;">{{ this.detail_statusName }}</span>
                    <span v-if="this.detail_statusId == 20" style="color: #E40078;">{{ this.detail_statusName }}</span>
                    <span v-if="this.detail_statusId == 30" style="color: #ff7ac0;">{{ this.detail_statusName }}</span>
                    <span v-if="this.detail_statusId == 40" style="color: orange;">{{ this.detail_statusName }}</span>
                    <span v-if="this.detail_statusId == 50" style="color: blue;">{{ this.detail_statusName }}</span>
                    <span v-if="this.detail_statusId == 60" style="color: #9acd32;">{{ this.detail_statusName }}</span>
                    <span v-if="this.detail_statusId == 70" style="color: green;">{{ this.detail_statusName }}</span>
                    <span v-if="this.detail_statusId == 90" style="color: red;">{{ this.detail_statusName }}</span>


                  </v-col>

                </v-row>
              </v-card>
            </div>
            <div v-else>
                <v-row  >
                  <v-col cols="6" class="ma-0 pa-0 pr-3">
                    <v-card class="px-5 py-2 v-card-bottom-30" elevation="1" style="max-height : 175px">
                      <v-row>

                        <v-col cols="6" >
                          <h3 style="margin-bottom: 21px">{{use_language.treasury}}</h3>
                        </v-col>
                        <v-col cols="6"  align="right">
                          <v-btn v-if="detail_statusId < 40 " :disabled="  !authorize" icon class="ma-0"  @click="can_edit_warehouse = !can_edit_warehouse"><v-icon size="18" >fa-edit</v-icon></v-btn>
                        </v-col>

                        <v-col cols="12" class="ma-0 pa-0" v-if="can_edit_warehouse == true">
                          <v-row class="ma-0 pa-0">
                            <v-col cols="8" md="8" class="py-4">
                                
                              <v-select
                                :value="Warehouse_List_selected"
                                @change="value => Warehouse_List_selected = value"
                                autocomplete="off"
                                :items="Warehouse_List"
                                item-key="id"
                                item-value="id"
                                item-text="name"
                                outlined
                                hide-details
                                dense
                              ></v-select>
                            </v-col>
                            <v-col cols="4" md="4" class="py-4" ><v-btn  color="primary" @click="shop_change_warehouse()">{{use_language.save}}</v-btn></v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="py-3 pb-10" v-else >{{ this.Warehouse_List_selected_text }} </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0 pl-3">
                    <v-card class="px-5 py-2 v-card-bottom-30" elevation="1" style="max-height : 175px" v-if="authorize">
                      <v-row>

                          <v-col cols="6" >
                            <h3 style="margin-bottom: 21px">{{use_language.status}}</h3>
                          </v-col>


                        <v-col cols="12" class="ma-0 pa-0" v-if="AllowChangeOrderStatus">
                          <v-row class="ma-0 pa-0">
                            <v-col cols="8" md="8" class="py-4">
                              <v-select
                                :value="detail_shiping_staus_update_select"
                                @change="value => detail_shiping_staus_update_select = value"
                                autocomplete="off"
                                :items="show_change_items_status"
                                item-key="text"
                                item-value="value"
                                outlined
                                hide-details
                                dense
                              ></v-select>
                            </v-col>
                            <v-col cols="4" md="4" class="py-4" align="right"><v-btn  :disabled="  !authorize" color="primary" @click="shop_change_shipping_status()">{{use_language.save}}</v-btn></v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="py-3 pb-10" v-else >
                          <span v-if="this.detail_statusId == 10" style="color: black;">{{ this.detail_statusName }}</span>
                          <span v-if="this.detail_statusId == 20" style="color: #E40078;">{{ this.detail_statusName }}</span>
                          <span v-if="this.detail_statusId == 30" style="color: #ff7ac0;">{{ this.detail_statusName }}</span>
                          <span v-if="this.detail_statusId == 40" style="color: orange;">{{ this.detail_statusName }}</span>
                          <span v-if="this.detail_statusId == 50" style="color: blue;">{{ this.detail_statusName }}</span>
                          <span v-if="this.detail_statusId == 60" style="color: #9acd32;">{{ this.detail_statusName }}</span>
                          <span v-if="this.detail_statusId == 70" style="color: green;">{{ this.detail_statusName }}</span>
                          <span v-if="this.detail_statusId == 90" style="color: red;">{{ this.detail_statusName }}</span>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
            </div>


            <v-card v-if="!platformReferenceSourceID.includes(detail_referenceSourceID) " class="px-5 py-2 v-card-bottom-30" elevation="1" >
              <v-row class="form-group">
                <v-col cols="9"><h3>{{use_language.sender_address}}</h3></v-col>
              </v-row>
              <v-row  class="form-group">
                <v-col cols="4" md="2" lg="1"><strong> {{use_language.sender_name}} : </strong></v-col>
                <v-col cols="8" md="10" lg="11" >{{ this.detail_ShopName }}</v-col>
              </v-row>
              <v-row  class="form-group">
                <v-col cols="4" md="2" lg="1"><strong> {{use_language.tel}} : </strong></v-col>
                <v-col cols="8" md="10" lg="11" >{{ this.detail_ShopPhone }}</v-col>
              </v-row>
              <v-row  class="form-group">
                <v-col cols="4" md="2" lg="1"><strong> {{use_language.address}} : </strong></v-col>
                <v-col cols="8" md="10" lg="11" >{{ this.detail_ShopAddress }}</v-col>
              </v-row>
            </v-card>


            <v-card v-if="!platformReferenceSourceID.includes(detail_referenceSourceID) || (platformReferenceSourceID.includes(detail_referenceSourceID)&&detail_isSellerOwnFleet)" class="px-5 py-5 v-card-bottom-30" elevation="1">
              <v-row class="form-group">
                <v-col cols="6"><h3>{{use_language.recipient_address}}</h3></v-col>
                <v-col cols="6" align="right">
                  <v-btn v-if="detail_statusId < 40" :disabled="!authorize"  icon class="ma-0"  @click="can_edit_shipaddress = !can_edit_shipaddress"><v-icon size="18" >fa-edit</v-icon></v-btn>
                </v-col>
              </v-row>
              <div v-if="can_edit_shipaddress == false">
                <v-row class="px-0">
                  <v-col cols="12" md="12"  lg="6" class="px-0">
                    <v-row  class="form-group">
                      <v-col cols="5" md="4" lg="2"><strong> {{use_language.recipient_name}} : </strong></v-col>
                      <v-col cols="7" md="18" lg="10" >{{ this.detail_ShipName }}</v-col>
                    </v-row>
                    <v-row  class="form-group">
                      <v-col cols="5" md="4" lg="2"><strong> {{use_language.tel}} : </strong></v-col>
                      <v-col cols="7" md="18" lg="10" >{{ this.detail_ShipPhone }}</v-col>
                    </v-row>
                    <v-row  class="form-group">
                      <v-col cols="5" md="4" lg="2"><strong> {{use_language.address}} : </strong></v-col>
                      <v-col cols="7" md="18" lg="10" >{{ this.detail_ShippingAddress }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col  cols="12" md="12" lg="6" class="px-0">
                    <v-row  class="form-group">
                      <v-col cols="5" md="4" lg="2"><strong> {{use_language.line}}: </strong></v-col>
                      <v-col cols="7" md="18" lg="10" >
                        <span v-if="this.detail_line != null">{{ this.detail_line }}</span>
                        <span v-else>-</span>
                      </v-col>
                    </v-row>
                    <v-row  class="form-group">
                      <v-col cols="5" md="4" lg="2"><strong> {{use_language.facebook}} : </strong></v-col>
                      <v-col cols="7" md="18" lg="10" >
                        <span v-if="this.detail_fackbook != null">{{ this.detail_fackbook }}</span>
                        <span v-else>-</span>
                      </v-col>
                    </v-row>
                    <v-row  class="form-group">
                      <v-col cols="5" md="4" lg="2"><strong> {{use_language.email}}  : </strong></v-col>
                      <v-col cols="7" md="18" lg="10" >
                        <span v-if="this.detail_email != null">{{ this.detail_email }}</span>
                        <span v-else>-</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-card-text>
                  <v-row row >
                    <v-col cols="12" md="12">
                      <v-row row >
                        <v-col cols="12" md="2" lg="1">
                        <h4>  {{use_language.name}} :</h4>
                        </v-col>
                        <v-col cols="12" md="10" lg="11">
                          <v-text-field
                            :value="ShipAddressManage.Name"
                            @change="value => ShipAddressManage.Name = value"
                            autocomplete="off"
                            ref="refShipAddressManageName"
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row row >
                    <v-col cols="12" md="12">
                      <v-row row >
                        <v-col cols="12" md="2" lg="1">
                          <h4>{{use_language.tel}} :</h4>
                        </v-col>
                        <v-col cols="12" md="10" lg="11">
                          <v-text-field
                            :value="ShipAddressManage.Phone"
                            @change="value => ShipAddressManage.Phone = value"
                            autocomplete="off"
                            ref="refShipAddressManagePhone"
                            maxlength="10"
                            outlined
                            hide-details
                            @keypress="isNumberWNoDot($event)"
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row row >
                    <v-col cols="12" md="12">
                      <v-row row >
                        <v-col cols="12" md="2" lg="1">
                        <h4>  {{use_language.address}} :</h4>
                        </v-col>
                        <v-col cols="12" md="10" lg="11">
                          <v-text-field
                            :value="ShipAddressManage.TextAddress"
                            @change="value => ShipAddressManage.TextAddress = value"
                            autocomplete="off"
                            ref="refShipAddressManageTextAddress"
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row row >
                    <v-col cols="12" md="12">
                      <v-row row >
                        <v-col cols="12" md="2" lg="1">
                          <h4>{{use_language.zipcode}} :</h4>
                        </v-col>
                        <v-col cols="12" md="10" lg="11">
                          <v-autocomplete
                            v-model="ShipAddressManage.Address"
                            ref="refShipAddressManageAddress"
                            placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                            :items="postcode_mapping"
                            :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.SubDistrict + ' ' + item.District"
                            outlined
                            @change="ShipAddressManage.PostcodeMappingID = ShipAddressManage.Address.Id"
                            required
                            return-object
                            hide-details
                            dense

                          >
                          <template v-slot:label> <span>{{use_language.all_address}}</span> </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row row >
                    <v-col cols="12" md="12">
                      <v-row row >
                        <v-col cols="12" md="2" lg="1">
                        <h4>  {{use_language.line}} :</h4>
                        </v-col>
                        <v-col cols="12" md="10" lg="11">
                          <v-text-field
                            :value="detail_edit_line"
                            @change="value => detail_edit_line = value"
                            autocomplete="off"
                            ref="refShipAddressManageTextAddress"
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row row >
                    <v-col cols="12" md="12">
                      <v-row row >
                        <v-col cols="12" md="2" lg="1">
                        <h4>  {{use_language.facebook}} :</h4>
                        </v-col>
                        <v-col cols="12" md="10" lg="11">
                          <v-text-field
                            :value="detail_edit_fackbook"
                            @change="value => detail_edit_fackbook = value"
                            autocomplete="off"
                            ref="refShipAddressManageTextAddress"
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row row >
                    <v-col cols="12" md="12">
                      <v-row row >
                        <v-col cols="12" md="2" lg="1">
                        <h4>  {{use_language.email}} :</h4>
                        </v-col>
                        <v-col cols="12" md="10" lg="11">
                          <v-text-field
                            :value="detail_edit_email"
                            @change="value => detail_edit_email = value"
                            autocomplete="off"
                            ref="refShipAddressManageTextAddress"
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12">
                      <v-row row >
                        <v-col class="pb-0" cols="12" md="12" align="left">
                          <v-btn dark color="primary" @click="EditDataShippingAddressManageID()" class="mr-1"><v-icon left >mdi-content-save</v-icon> {{use_language.save}}</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>

              <!-- แพลตฟอร์ม -->
            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1" v-if="orderItemTempList.length > 0">
              <v-row class="form-group">
                <v-col cols="9"><h3>{{use_language.platform}}</h3></v-col>
              </v-row>

              <v-row  class="form-group">
                <v-col cols="4" md="3" lg="2"><strong> {{use_language.reference_number}} : </strong></v-col>
                <v-col cols="8" md="9" lg="10" >
                  <div v-if="this.detail_ReferenceSourceID==6">
                    {{ this.detail_RealReferenceNumber }} ({{ this.detail_ReferenceNumber }})  
                  </div>
                  <div v-else>
                    {{ this.detail_ReferenceNumber }}  
                  </div>
                </v-col>
              </v-row>
              <v-row  class="form-group">
                <v-col cols="4" md="3" lg="2"><strong> {{use_language.channel}} : </strong></v-col>
                <v-col cols="8" md="9" lg="10" >
                  <v-img style="margin:5px;"
                      :src="this.detail_ChannelSourceLogoPic"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      max-width="30"
                      max-height="30"
                      @click="image_click(detail_ChannelSourceLogoPic, 0)"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row  class="form-group" v-if="(this.detail_referenceSourceID == 3 || this.detail_referenceSourceID == 4 || this.detail_referenceSourceID == 11) && marketplace_status != null">
                <v-col cols="4" md="3" lg="2"><strong> สถานะ : </strong></v-col>
                <v-col cols="8" md="9" lg="10" >
                  {{ marketplace_status }}
                </v-col>
              </v-row>
              <v-row  class="form-group" v-if="this.detail_TempSenderAddress != null">
                <v-col cols="4" md="3" lg="2"><strong> {{use_language.sender}} : </strong></v-col>
                <v-col cols="8" md="9" lg="10" >{{ this.detail_TempSenderAddress }}</v-col>
              </v-row>
              <v-row  class="form-group">
                <v-col cols="4" md="3" lg="2"><strong> {{use_language.recipient}} : </strong></v-col>
                <v-col cols="8" md="9" lg="10" >{{ this.detail_TempAddress }}</v-col>
              </v-row>
              <v-row  class="form-group">
                <v-col cols="12" md="3" lg="2"><strong> {{use_language.to_pack}} : </strong></v-col>
                <v-col cols="12" md="9" lg="10" style="overflow-x: auto">
                  <v-simple-table class="packhai-border-table">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="90px">{{use_language.tb_image}}</th>
                          <th class="text-left" width="650px">{{use_language.name}}</th>
                          <th class="text-left" width="150px">ลักษณะ</th>
                          <th class="text-left" width="100px">{{use_language.sku}}</th>
                          <th class="text-right" width="70px">{{use_language.quantity}}</th>
                          <th class="text-right" width="170px">{{use_language.unit_price}}</th>
                          <th class="text-right" width="170px">รวมราคา</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in orderItemTempList"
                          :key="item.name"
                        >
                          <td class="text-center">
                            <v-img
                            style="margin:5px;"
                            :src="item.imgUrl"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            width="50px"
                            height="50px"
                            @click="image_click(item.imgUrl, 0)"
                        ></v-img>
                          </td>
                          <td class="text-left">
                            <div v-if="item.notSku" class="color-red">
                              {{ item.name }}
                              <br/>
                              {{use_language.product_not_found_system}}
                            </div>
                            <div v-else>
                              {{ item.name }}
                            </div>
                          </td>
                          <td class="text-left">
                            <div >
                              {{ item.propDescription }}
                            </div>
                          </td>
                          <td class="text-left">
                            <div v-if="item.notSku" class="color-red">
                              {{ item.sku }}
                            </div>
                            <div v-else>
                              {{ item.sku }}
                            </div>
                          </td>
                          <td class="text-right">{{ item.quantity }}</td>
                          <td class="text-right">{{ format_price(item.unitPrice) }}</td>
                          <td class="text-right">{{ format_price(item.unitPrice * item.quantity)}}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                      <v-row align="end" style="height: 30px;">
                        <v-col cols="8" class="py-0" align="end"><strong>{{use_language.total_price}} :</strong></v-col>
                        <v-col cols="4" class="py-0" align="end">
                          <span v-if="this.detail_totalPrice != null">{{ format_price(this.detail_totalPrice) }} {{use_language.bath}} </span>
                          <span v-else> 0 {{use_language.bath}}</span>
                        </v-col>
                      </v-row>
                  
                       <v-row align="end" style="height: 30px;">
                        <v-col cols="8" class="py-0" align="end"><strong>ส่วนลดออเดอร์ :</strong></v-col>
                        <v-col cols="4" class="py-0" align="end">
                          <span v-if="this.detail_OrderDiscount != null">{{ format_price(this.detail_OrderDiscount*-1) }} {{use_language.bath}}</span>
                          <span v-else> 0 {{use_language.bath}}</span>
                        </v-col>
                      </v-row>
                      <v-row align="end" style="height: 30px;">
                        <v-col cols="8" class="py-0" align="end"><strong>ส่วนลด Seller voucher :</strong></v-col>
                        <v-col cols="4" class="py-0" align="end">
                          <span v-if="this.detail_OrderDiscount != null">{{ format_price(this.detail_SellerVoucher*-1) }} {{use_language.bath}}</span>
                          <span v-else> 0 {{use_language.bath}}</span>
                        </v-col>
                      </v-row>
                      <v-row align="end" style="height: 30px;">
                        <v-col cols="8" class="py-0" align="end"><strong>{{use_language.shipping_cost}} :</strong></v-col>
                        <v-col cols="4" class="py-0" align="end">
                          <span v-if="this.detail_DeliveryCost != null">{{ format_price(this.detail_DeliveryCost) }} {{use_language.bath}}</span>
                          <span v-else> 0 {{use_language.bath}}</span>
                        </v-col>
                      </v-row>
                      <v-row align="end" style="height: 30px;">
                        <v-col cols="8" class="py-0" align="end"><strong> ส่วนลดค่าจัดส่ง :</strong></v-col>
                        <v-col cols="4" class="py-0" align="end">
                          <span v-if="this.detail_DeliveryCost != null">{{ format_price(this.detail_SellerDeliveryDiscount*-1) }} {{use_language.bath}}</span>
                          <span v-else> 0 {{use_language.bath}}</span>
                        </v-col>
                      </v-row>
                      <v-row align="end" style="height: 30px;">
                        <v-col v-if="detail_VATType==0 || detail_VATType==1" cols="8" class="py-0" align="end"><strong> VAT (No VAT) :</strong></v-col>
                        <v-col v-if="detail_VATType==2  " cols="8" class="py-0" align="end"><strong> VAT (Included) :</strong></v-col>
                        <v-col v-if="detail_VATType==3 " cols="8" class="py-0" align="end"><strong> VAT (Excluded) :</strong></v-col>
                        <v-col cols="4" class="py-0" align="end">
                          <span v-if="this.detail_DeliveryCost != null">{{ format_price(this.detail_VATAmount) }} {{use_language.bath}}</span>
                          <span v-else> 0 {{use_language.bath}}</span>
                        </v-col>
                      </v-row>
                      <v-row align="end" style="height: 30px;"   >
                        <v-col cols="8" class="py-0" align="end"><strong>{{use_language.total_all}} :</strong></v-col>
                        <v-col cols="4" class="py-0" align="end">
                          <span  >{{ format_price(detail_orderTotalAmount ) }} {{use_language.bath}}</span>
                          
                        </v-col>
                      </v-row>
                      <v-row v-if="detail_PlatformVoucher!=0 && detail_PlatformVoucher!=null" align="end" style="height: 30px;"   >
                       
                        <v-col cols="12" class="py-0" align="end" style="color:grey">
                        **ออเดอร์นี้มีส่วนลดจาก platform อีก {{format_price(detail_PlatformVoucher) }} {{use_language.bath}} 
                        </v-col>
                      </v-row>


                </v-col>
              </v-row>
            </v-card>

            <v-card class="px-5 py-2 v-card-bottom-30" elevation="1">
              <v-row>
                <v-col cols="8" ><h3>{{use_language.parcel_format}}</h3></v-col>
                <v-col cols="4"  align="right">
                  <v-btn v-if="detail_statusId < 40" :disabled="!authorize"  icon class="ma-0"  @click="can_edit_data_cod = !can_edit_data_cod, edit_cod_amount = detail_CODAmount, edit_cod_bank = detail_ShopBankAccountID, can_edit_cod()"><v-icon size="18" >fa-edit</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row v-if="can_edit_data_cod == false"   >
                  <v-col>
                    <span v-if="this.detail_IsCOD == false"><v-chip color="info" outlined >{{use_language.ordinary}}</v-chip></span>
                    <span v-else ><v-chip color="primary">COD</v-chip> </span>
                    <span class="pl-4" v-if="this.detail_IsCOD == true && this.detail_referenceSourceID!=3 && this.detail_referenceSourceID!=4 && this.detail_referenceSourceID!=11" >  {{use_language.total_}} {{ this.detail_CODAmount }} {{use_language.bath}}</span>
                    <br>
                    <br>
                    <span v-if="this.detail_IsCOD == true &&this.detail_referenceSourceID!=3 && this.detail_referenceSourceID!=4 && this.detail_referenceSourceID!=11" >
                      <strong> {{use_language.bank}} : </strong>{{ this.detail_BankName }} {{ this.detail_AccountName }} {{ this.detail_AccountNumber }}
                    </span>

                  </v-col>
                </v-row>
                <v-row v-else  >
                  <v-col cols="12" >
                    <v-radio-group
                      v-model="cod_list_select"
                      row
                    >
                    <v-radio
                        v-for="n in cod_list"
                        :key="n.ID"
                        :label="n.text"
                        :value="n.ID"
                      ></v-radio>
                    </v-radio-group>
                    <br>
                    <v-divider/>
                  </v-col>
                  <v-col cols="12" v-if="cod_list_select == 0">
                    <v-row class="pl-10 pb-3">
                      <v-col cols="12" sm="12" lg="12">
                        <v-btn @click="order_update_iscod_status(1)" color="primary">บันทึก</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-row   >
                      <v-col cols="12" sm="2"  lg="1"><strong> {{use_language.total_}} : </strong></v-col>
                      <v-col cols="12" md="10" lg="11" >
                        <v-text-field
                          :value="edit_cod_amount"
                          @change="value => edit_cod_amount = value"
                          autocomplete="off"
                          ref="edit_cod_amount"
                          type="number"
                          outlined
                          hide-details
                          dense
                          style="max-width: 150px;"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row  >
                      <v-col cols="12" sm="2"  lg="1"><strong> {{use_language.bank}} : </strong></v-col>
                      <v-col cols="12" md="10" lg="11" >
                        <v-container
                        v-if="cod_bank_data.length > 0"
                          class="px-0"
                          fluid
                        >
                          <v-radio-group v-model="edit_cod_bank" ref="edit_cod_bank_2">
                              <v-radio
                                v-for="n in cod_bank_data"
                                :key="n.ID"
                                :label="`${n.text}`"
                                :value="n.ID"
                              ></v-radio>
                          </v-radio-group>
                        </v-container>
                        <div v-else>
                          {{use_language.no_bank_info}}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row  class=" pb-3">
                      <v-col cols="12">
                        <v-btn @click="order_update_iscod_status(1)" color="primary">บันทึก</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
            </v-card>

            <v-card class="px-5 py-2  v-card-bottom-30" elevation="1" v-if=" can_edit_product == false">
              <v-card-title>

              <!-- สินค้าที่สั่ง -->
              </v-card-title> 
              <v-row >
                <v-col cols="6"  ><h3>{{use_language.ordered_products}}</h3></v-col>
                <v-col cols="6"  align="right">
                  <v-btn icon  :disabled="!authorize"  @click="get_data_stock_for_edit(), edit_cod_amount = detail_CODAmount"><v-icon size="18" >fa-edit</v-icon></v-btn></v-col>
              </v-row>
              <v-row class="form-group">
                <v-col style="background: #fff ;" cols="12">
                  <v-simple-table v-if="orderItemInvoice_tmp.length != 0" class="packhai-border-table">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" width="120px">{{use_language.image}}</th>
                          <th class="text-left">{{use_language.product}}</th>
                          <th class="text-left">{{use_language.barcode}}</th>
                          <th class="text-left">{{use_language.sku}}</th>
                          <th class="text-right">{{use_language.quantity}}</th>
                          <th class="text-right">{{use_language.price_per_piece}}</th>
                          <th class="text-right">{{use_language.discount}}</th>
                          <th class="text-right">{{use_language.total_price}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item2 in orderItemInvoice_tmp" :key="item2.id">
                          <td class="text-center">
                            <div align="center">
                              <v-img style="margin:5px;"
                                :src="item2.photoLink"
                                aspect-ratio="1"
                                class="grey lighten-2"
                                width="50"
                                height="50"
                                @click="image_click(item2.photoLink, 0)"
                            ></v-img>
                            </div>

                          </td>
                          <td class="text-left">
                            {{ item2.productName }}
                            <span class="fn-11 color-b3">
                              {{ (item2.prop1Description !=null ? item2.prop1Description : "") + (item2.prop2Description != null && item2.prop2Description!='' ? ", "+item2.prop2Description : "") }}
                            </span>
                          </td>
                          <td class="text-left">{{item2.barcode}}</td>
                          <td class="text-left">{{ item2.sku }}</td>
                          <td class="text-right">{{ item2.quantity }}</td>
                          <td class="text-right">{{ item2.unitPrice }}</td>
                          <td class="text-right">{{ item2.discount }}</td>
                          <td class="text-right"><span v-if="item2.quantity != null">{{ format_price(item2.unitPrice * item2.quantity - item2.discount) }}</span></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div v-else>-</div>
                </v-col>
                <v-col cols="12" md="6" class="py-0" justify="end">
                </v-col>
                <v-col cols="12" md="6" class="py-0" justify="end" v-if="detail_order['shippingStatusID'] == 10">
                </v-col>
              </v-row>
              <v-row  v-if="orderItemTempList.length == 0 || (!platformReferenceSourceID.includes(this.detail_referenceSourceID))">
                <v-col cols="12">
                  <table style="width:100%">
                    <tr>
                      <td class="text-right pb-2">
                        <strong> <span v-if=" detail_VATType == null|| detail_VATType == 0 || detail_VATType == 1">{{use_language.total_price}}</span> <span v-if="detail_VATType == 2">{{use_language.price_including_tax}}</span> <span v-if="detail_VATType == 3">{{use_language.price_not_include_tax}} :</span> </strong>
                      </td>
                      <td class="text-right  pb-2" style="width: 150px;">
                        <span v-if="this.detail_totalPrice != null">
                        {{ format_price(this.detail_totalPrice) }} {{use_language.bath}}</span>
                        <span v-else> 0 {{use_language.bath}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td  class="text-right  pb-2">
                        <strong>{{use_language.shipping_cost}} :</strong>
                      </td>
                      <td class="text-right  pb-2" style="width: 150px;">
                        <span v-if="this.detail_DeliveryCost != null">{{ format_price(this.detail_DeliveryCost) }} {{use_language.bath}}</span>
                        <span v-else> 0 {{use_language.bath}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td  class="text-right  pb-2">
                        <strong>{{use_language.discount}} :</strong>
                      </td>
                      <td class="text-right  pb-2" style="width: 150px;">
                        <span v-if="this.detail_DeliveryCost != null">{{ format_price(this.detail_OrderDiscount*-1) }} {{use_language.bath}}</span>
                        <span v-else> 0 {{use_language.bath}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td  class="text-right  pb-2">
                        <strong v-if="this.detail_VATType == null|| this.detail_VATType == 0||this.detail_VATType == 1" >{{use_language.no_vat_}} :</strong>
                        <strong v-else-if="this.detail_VATType == 2">{{use_language.vat_included_}} :</strong>
                        <strong v-else-if="this.detail_VATType == 3">{{use_language.vat_excluded_}} :</strong>
                      </td>
                      <td class="text-right  pb-2" style="width: 150px;">
                        <span v-if="this.detail_DeliveryCost != null">{{ format_price(this.detail_VATAmount) }} {{use_language.bath}}</span>
                        <span v-else> 0 {{use_language.bath}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td  class="text-right  pb-2">
                        <strong>{{use_language.total_all}} :</strong>
                      </td>
                      <td class="text-right  pb-2" style="width: 150px;">
                        <span v-if="this.detail_sum_unintPrice_and_DeliveryCost != null && this.detail_VATType == 3">{{ format_price(parseFloat(this.detail_totalPrice) + parseFloat(this.detail_DeliveryCost) + parseFloat(this.detail_VATAmount)  - parseFloat(this.detail_OrderDiscount) )}} {{use_language.bath}}</span>
                        <span v-else-if="this.detail_sum_unintPrice_and_DeliveryCost != null && this.detail_VATType == 2">{{ format_price(parseFloat(this.detail_totalPrice) + parseFloat(this.detail_DeliveryCost) - parseFloat(this.detail_OrderDiscount) )}} {{use_language.bath}}</span>
                        <span v-else-if="this.detail_sum_unintPrice_and_DeliveryCost != null && this.detail_VATType == 1">{{ format_price(parseFloat(detail_totalPrice) + parseFloat(detail_DeliveryCost) + (detail_DeliveryCost==null ? 0 : parseFloat(detail_OrderDiscount)*-1)) }} {{use_language.bath}} </span>
                        <span v-else> 0 {{use_language.bath}} </span>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-card>


            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1" v-if="can_edit_product == true">
             
              <v-row class="form-group">
                <v-col cols="6"><h3>{{use_language.edit_product}}</h3></v-col>
                <v-col cols="6" align="right">
                  <v-btn icon class="ma-0"  @click="can_edit_product = false"><v-icon size="18" >fa-edit</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!IsAbleToEditOrderItem" class="form-group">
                <v-col cols="12" >
                
                <ul style="color:red">
                <li> 
                  การแก้ไขสินค้าในช่องนี้ เป็นเพียงการแก้ไขรายการที่จะแสดงในใบกำกับภาษีเท่านั้น
                </li>
                <li>
                  รายการสินค้าที่ต้องแพ็กและตัดสต๊อกจะไม่ถูกแก้ไขตาม
                </li>
                <li>
                  หากต้องการแก้ไขสินค้าที่ต้องแพ็กและตัดสต๊อก กรุณาแก้ไขที่หัวข้อ "สินค้าที่แพ็ก"
                </li>
                </ul>
                 
                </v-col>
              
              </v-row>
              <v-row class="form-group">
                <v-col cols="12" class="pa-0">
                  <v-card-text class="pa-0">
                      <v-row>
                        <v-col cols="12" md="5" lg="3" class="pt-0">
                          <v-select
                            v-if="detailOrderProduct_tmp.length!=0"
                            v-model="Warehouse_List_selected"
                            :items="Warehouse_List"
                            item-text="name"
                            item-value="id"
                            persistent-hint
                            return-object
                            dense hide-details
                            disabled
                            outlined
                            single-line
                          ></v-select>
                          <v-select
                            v-else
                            v-model="Warehouse_List_selected"
                            :items="Warehouse_List"
                            item-text="name"
                            item-value="id"
                            persistent-hint
                            return-object
                            dense hide-details
                            outlined
                            single-line
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="7" lg="9" class="py-0">
                          <v-btn color="primary" class="mr-1" outlined @click="OpenPopup(limits,0)">
                            <v-icon center >mdi-plus</v-icon>
                            {{use_language.select_product}}
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row>
                          <v-col class="pb-0">
                              <v-data-table
                                  :headers="header_edit_item_show"
                                  :items="detailOrderProduct_tmp"
                                  :items-per-page="10000"
                                  hide-default-footer
                                  no-data-text="กรุณาเลือกสินค้า"
                                  class="packhai-border-table packhai-table"
                              >
                                 <template v-slot:no-data> <span>{{use_language.please_select_product}}</span> </template>

                                  <template v-slot:item.Index="{ item }">
                                      {{ detailOrderProduct_tmp.indexOf(item) + 1 }}
                                  </template>
                                  <template v-slot:item.orderMasterId="{ item }">
                                      <v-text-field
                                          :value="item.orderMasterId"
                                          @change="value => item.orderMasterId = value"
                                          autocomplete="off"
                                          dense
                                          hide-details
                                          outlined
                                          ref="OrderItemTempID"
                                          type="number"
                                      ></v-text-field>
                                  </template>
                                  <template v-slot:item.photoLink="{ item }">
                                      <v-img :src="item.photoLink == null ? '' : item.photoLink" width="50" height="50" class="ma-1"></v-img>
                                  </template>
                                  <template v-slot:item.productName="{ item }">
                                    {{ item.productName }}
                                    <br v-if="item.prop1Description !=null && item.prop1Description!=''"/>
                                    <span class="fn-11 color-b3"  v-if="item.prop1Description !=null && item.prop1Description!=''">
                                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null && item.prop2Description !=''? ", "+item.prop2Description : "") }}
                                    </span>
                                    <br v-if="item.barcode !=null && item.barcode!=''"/>
                                    <span class="fn-11 color-b3" v-if="item.barcode !=null&& item.barcode!=''">
                                 
                                      barcode : {{item.barcode}}
                                    </span>
                                    <br  v-if="item.sku !=null&& item.sku!=''"/>
                                    <span class="fn-11 color-b3" v-if="item.sku !=null&& item.sku!=''">
                                      
                                       sku : {{item.sku}}
                                    </span>
                                    
                                  </template>
                                  <template v-slot:item.unitPrice="{ item }">
                                      <v-text-field
                                          :value="item.unitPrice"
                                          @change="value => item.unitPrice = value"
                                          autocomplete="off"
                                          dense
                                          hide-details
                                          outlined
                                          ref="untiPriceInput"
                                          type="number"
                                          @blur="unitPriceInput(item)"
                                      ></v-text-field>
                                  </template>
                                  <template v-slot:item.quantity="{ item }">
                                      <v-text-field
                                          :value="item.quantity"
                                          @change="value => item.quantity = value"
                                          autocomplete="off"
                                          dense
                                          hide-details
                                          outlined
                                          ref="quantity"
                                          type="number"
                                          @blur="quantityInput(item)"
                                      ></v-text-field>
                                  </template>
                                  <template v-slot:item.discountPercent="{ item }">
                                      <v-text-field
                                          v-model="item.discountPercent"
                                          :disabled="item.quantity == '' || item.quantity == null"
                                          autocomplete="off"
                                          dense
                                          hide-details
                                          outlined
                                          type="number"
                                          @input="calculateDiscountPercent(item)"
                                          @change="calculateTotalStockPrice()"
                                      ></v-text-field>
                                  </template>
                                  <template v-slot:item.discount="{ item }">
                                      <v-text-field
                                          v-model="item.discount"
                                          :disabled="item.discountPercent != '' || item.discountPercent != null"
                                          autocomplete="off"
                                          dense
                                          hide-details
                                          outlined
                                          type="number"
                                      ></v-text-field>
                                  </template>
                                  <template v-slot:item.itemPrice="{ item }">
                                      <span v-if="item.itemPrice != null">{{ formatMoney(item.itemPrice.toFixed(2)) }}</span>
                                      <span v-else>{{ item.itemPrice }}</span>
                                  </template>
                                  <template v-slot:item.Delete="{ item }">
                                      <v-btn icon color="red" @click="remove_data_product(item, item.stockShopId)"><v-icon>mdi-delete-outline</v-icon></v-btn>
                                  </template>
                              </v-data-table>
                          </v-col>
                      </v-row>
                      <v-row justify="end">
                          <v-col cols="12" lg="6" class="hidden-md-and-down pa-0" v-if="detail_IsCOD == true && IsCanEditCOD">
                            <v-row class="py-3">
                                <v-col lg="3" xl="2">
                                    <span style="color : red;"><b>ยอด COD :</b></span>
                                </v-col>
                                <v-col lg="3" xl="3">
                                  <v-text-field
                                    :value="edit_cod_amount"
                                    @change="value => edit_cod_amount = value"
                                    autocomplete="off"
                                    ref="edit_cod_amount"
                                    type="number"
                                    outlined
                                    hide-details
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col lg="7" xl="6">
                                </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" lg="6" class="pa-0">
                              <v-divider></v-divider>
                              <v-row class="py-3">
                                  <v-col cols="6" >
                                      มูลค่าสินค้ารวม
                                  </v-col>
                                  <v-col cols="6"   align="right">
                                      <span>{{ formatMoney(this.ProductPrice.toFixed(2)) }}</span>
                                  </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="py-3">
                                  <v-col cols="6"  >
                                      ภาษีมูลค่าเพิ่ม
                                  </v-col>
                                  <v-col cols="6"  align="end">
                                      {{ format_price(totalVat) }}
                                  </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="py-3">
                                  <v-col cols="8"  >
                                      ค่าจัดส่ง
                                  </v-col>
                                  <v-col cols="4" >
                                      <v-text-field
                                          :value="textField_deliveryCost"
                                          autocomplete="off"
                                          dense
                                          hide-details
                                          outlined
                                          type="number"
                                          @blur="calculateDiscountOrder()"
                                          @change="value => textField_deliveryCost = value"
                                      ></v-text-field>
                                  </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="justify-space-between">
                                  <v-col cols="8"  class="d-flex">
                                      ส่วนลด
                                      <v-select style="width:50px;"
                                          v-model="select_discountType"
                                          :items="['฿', '%']"
                                          dense
                                          hide-details
                                          outlined
                                          class="pl-2" 
                                          @change="calculateDiscountOrder()"
                                      ></v-select>
                                  </v-col>
                                  <v-col cols="4" >
                                      <v-text-field
                                          :value="textField_discount"
                                          autocomplete="off"
                                          dense
                                          hide-details
                                          outlined
                                          type="number"
                                          @blur="calculateDiscountOrder()"
                                          @change="value => textField_discount = value"
                                      ></v-text-field>
                                  </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="py-3">
                                  <v-col cols="4" md="2">
                                      ภาษีมูลค่าเพิ่ม
                                  </v-col>
                                  <v-col cols="8" md="10" align="end">
                                      {{ format_price(totalVat) }}
                                  </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="pb-4">
                                  <v-col cols="4" md="2">
                                      <h4>ยอดรวม</h4>
                                  </v-col>
                                  <v-col cols="8" md="10" align="end">
                                      <h4>{{ format_price((totalPrice + Number(textField_deliveryCost) - totalDiscount) + (select_vatType == 3 ? totalVat : 0)) }}</h4>
                                  </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row>
                                  <v-col class="pb-0">
                                      <v-select
                                          v-model="select_vatType"
                                          :items="[{text: use_language.no_tax, value: 1}, {text: use_language.price_including_tax, value: 2}, {text: use_language.price_not_include_tax, value: 3}]"
                                          dense
                                          hide-details
                                          item-text="text"
                                          item-value="value"
                                          outlined
                                          @change="calculateVAT()"
                                      ></v-select>
                                  </v-col>
                              </v-row>
                              <v-row>
                                  <v-col class="pb-0">
                                      <v-textarea
                                          :value="textarea_remarkProduct"
                                          dense
                                          hide-details
                                          outlined
                                          placeholder="หมายเหตุ"
                                          rows="2"
                                          @change="value => textarea_remarkProduct = value"
                                      >
                                        <template v-slot:label> <span>{{use_language.note}}</span> </template>
                                      </v-textarea>
                                  </v-col>
                              </v-row>
                              <v-row class="py-3 hidden-lg-and-up" v-if="detail_IsCOD == true && IsCanEditCOD">
                                  <v-col cols="4" md="2">
                                      <span style="color : red;"><b>ยอด COD :</b></span>
                                  </v-col>
                                  <v-col cols="8" md="10">
                                    <v-text-field
                                      :value="edit_cod_amount"
                                      @change="value => edit_cod_amount = value"
                                      autocomplete="off"
                                      ref="edit_cod_amount"
                                      type="number"
                                      outlined
                                      hide-details
                                      dense
                                    ></v-text-field>
                                  </v-col>
                              </v-row>
                          </v-col>
                      </v-row>

                      <v-row justify="end">
                        <v-col lg="5" align="right">
                          <v-btn color="primary" @click="update_data_product()">{{use_language.save}}</v-btn>
                        </v-col>
                      </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>

            <!-- สินค้าที่แพ็ก -->
            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1"  v-if=" can_edit_packaged == false">
              <v-row class="form-group">
                <v-col cols="6"><h3>{{use_language.packaged_goods}}</h3></v-col>
                <v-col cols="6" align="right">
                  <v-btn icon  :disabled="!authorize"  @click="btnEditPackaged()"><v-icon size="18" >fa-edit</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row class="form-group">
                <v-col style="background: #fff ;" cols="12">
                  <div class="mb-5" v-for="pac in packageList" :key="pac.id">
                    <v-row class="pa-0 ma-0 ">
                      <v-col cols="6" class="pa-0"> 
                        <b class="pr-2">Package #{{ pac.packageNo +' (PK' + pac.id+')' }}</b>
                      </v-col>
                      <v-col cols="6" class="pa-0 text-right"  >
                        <span v-if="pac.dueDeliveryDate!=null">วันที่ต้องจัดส่ง : {{ set_format_date_time(pac.dueDeliveryDate).substr(0,10)   }}</span>
                      </v-col> 
                    </v-row>
                    <v-simple-table v-if="pac.itemList.length != 0" class="packhai-border-table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center" width="120px">{{use_language.image}}</th>
                            <th class="text-left">{{use_language.product}}</th>
                            <th class="text-left" width="140px">{{use_language.barcode}}</th>
                            <th class="text-left" width="140px">{{use_language.sku}}</th>
                            <th v-if="IsHasSerial" class="text-right" width="120px">Serial No</th>
                            <th class="text-center" width="120px">{{use_language.quantity_need_to_pack}}</th>
                            <th class="text-center" width="120px">{{use_language.actual_number_of_packs}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item2 in pac.itemList" :key="item2.id">
                            <td class="text-center">
                              <div align="center">
                                <v-img style="margin:5px;"
                                  :src="item2.photoLink"
                                  aspect-ratio="1"
                                  class="grey lighten-2"
                                  width="50"
                                  height="50"
                                  @click="image_click(item2.photoLink, 0)"
                              ></v-img>
                              </div>

                            </td>
                            <td class="text-left">
                              {{ item2.productName }}
                              <span class="fn-11 color-b3">
                                {{ (item2.prop1Description !=null ? item2.prop1Description : "") + (item2.prop2Description != null ? " , "+item2.prop2Description : "") }}
                              </span>
                            </td>
                            <td class="text-left">{{item2.barcode}}</td>
                            <td class="text-left">{{ item2.sku }}</td>
                            <td v-if="IsHasSerial" class="text-right">{{ item2.serialNumber }}</td>
                            <td class="text-center">{{ formatMoney(item2.quantity) }}</td>
                            <td class="text-center">{{ formatMoney(item2.packedQuantity) }}</td>

                          
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div v-else>-</div>
                  </div>
                </v-col>
              </v-row>
            </v-card>

            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1"  v-if=" can_edit_packaged == true">
              <v-row class="form-group">
                <v-col cols="6"><h3>แก้ไข{{use_language.packaged_goods}}</h3></v-col>
                <v-col cols="6" align="right">
                  <v-btn icon  :disabled="!authorize"  @click="can_edit_packaged =false"><v-icon size="18" >fa-edit</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row class="form-group">
                <v-col style="background: #fff ;" cols="12">
                  <div class="mb-5" v-for="pac of packageList" :key="pac.id">
                    
                    
                    
                    <v-row class="pa-0 ma-0 ">
                      <v-col cols="6" class="pa-0"> 
                        <b class="pr-2">Package #{{ pac.packageNo +'(PK' + pac.id+')' }}</b>
                        <v-btn
                        class="mr-2"
                        color="primary"
                        outlined
                        :disabled="checkSplitBtn(pac)"
                        @click="btnSplitPackage(pac)"
                        >
                        แยกพัสดุ
                       </v-btn>
                        <v-btn
                        color="primary"
                        outlined 
                        @click="{OpenPopupAddPackage(pac,limits,0),packageNo = pac.packageNo}"
                        >
                        เพิ่มสินค้า
                      </v-btn>
                      </v-col>
                      
                      <v-col cols="6" class="pa-0" >
                        <table style="width:100%" v-if="!platformReferenceSourceID.includes(detail_referenceSourceID)">
                          <tr>
                            <td class="pa-0 text-right">
                              วันที่ต้องจัดส่ง :
                            </td>
                            <td class="pa-0" style="width:150px">
                              <date-time-picker v-model="pac.dueDeliveryDate" :isLabel="'วันที่ต้องจัดส่ง'" :isTime="false" />
                            </td>
                          </tr>
                        </table>
                      </v-col> 
                     
                    </v-row>
            

                    <v-row>
                      <v-col cols="12" class="pb-0 px-0">
                
              
                          <v-data-table
                              v-model="pac.select"
                              :headers="header_edit_item_packag_show"
                              :items="pac.itemList"
                              :items-per-page="10000"
                                item-key="productMasterItemId"
                              hide-default-footer
                              show-select
                              :ripple="false"  
                              class="packhai-border-table packhai-table"
                          >
                    
                              <template v-slot:no-data> <span>{{use_language.please_select_product}}</span> </template>
                              <template v-slot:item.data-table-select="{ isSelected, select }">
                                <v-simple-checkbox :ripple="false" class="data-table-select-column" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                              </template>
                              <template v-slot:header.data-table-select="{ on , props }">
                                  <v-simple-checkbox class="data-table-select-column"
                                    v-bind="props"
                                    v-on="on"
                                    :ripple="false"
                                  />
                              </template>
                              <template v-slot:item.productName="{ item }">
                                {{  item.productName }}
                                <span class="fn-11 color-b3">
                              {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null && item.prop2Description!='' ? ", "+item.prop2Description : "") }}
                            </span>
                              </template>
                              <template v-slot:item.photoLink="{ item }">
                                
                                  <img :src="item.photoLink" height="50"  width="50"  class="ma-1 "  @click="OpenPhoto(item.photoLink)"/>
                                
                              </template>
                              
                              <template v-slot:item.Delete="{  item , index}">
                                  <v-btn icon color="red" @click="{btnDeletePacked(item, index),packageNo =pac.packageNo}"><v-icon>mdi-delete-outline</v-icon></v-btn>
                              </template>
                          </v-data-table>
                      </v-col>
                    </v-row>

                    
                  </div>
                </v-col>
              </v-row>
              <v-row justify="end">
                      <v-col cols="4" lg="5" align="right" class="pt-4">
                        <v-btn color="primary" @click="btnSaveChangePackage()">{{use_language.save}}</v-btn>
                      </v-col>
                    </v-row>
            </v-card>


            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1">
              <v-row class="form-group">
                <v-col cols="6"><h3>{{use_language.note}}</h3></v-col>
                <v-col cols="6" align="right">
                  <v-btn icon :disabled="!authorize"  class="ma-0"  @click="can_edit_remark = !can_edit_remark, edit_detail_remark = detail_Remark"><v-icon size="18" >fa-edit</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row class="form-group">
                <v-col v-if="can_edit_remark == true"  cols="12" md="8" lg="6" >
                  <v-textarea
                    v-model="edit_detail_remark"
                    filled
                    auto-grow
                  ></v-textarea>
                  <br>
                  <v-btn @click="detail_edit_remark()" dark color="primary">{{use_language.save}}</v-btn>
                </v-col>
                <v-col v-else style="background: #fff ;" cols="12" >
                  <span v-if="this.detail_Remark == null">-</span> <span v-else>{{ this.detail_Remark }}</span>
                </v-col>
              </v-row>
            </v-card>


            <v-card class="px-5 py-2 v-card-bottom-30" elevation="1">
              <v-row>
                <v-col cols="8" ><h3>{{use_language.order_status_}}</h3></v-col>
                <v-col cols="4" ></v-col>
              </v-row>
              <div >
                <v-row v-for="(item, index) in order_status_history_list" :key="index"  >
                    <v-col cols="6" md="3"  >
                        {{ item.updateDateTime }}
                    </v-col>
                    <v-col cols="6" md="9">
                        {{ item.description }}
                    </v-col>
                </v-row>
              </div>
            </v-card>

            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1"  v-if="!platformReferenceSourceID.includes(this.detail_referenceSourceID)" >
              <v-row class="form-group">
                <v-col cols="6"><h3>{{use_language.sales_data}}</h3></v-col>
                <v-col cols="6" align="right">
                  <v-btn icon :disabled="!authorize || platformReferenceSourceID.includes(this.detail_referenceSourceID)"  class="ma-0" @click="editSaleman = !editSaleman"><v-icon size="18">fa-edit</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row class="form-group">
                <v-col cols="6" md="4" lg="2">
                  <strong> {{use_language.open_order_by}} : </strong>
                </v-col>
                <v-col cols="6" md="8" lg="10">
                  {{ detail_order.createdByStaffShopName }}
                </v-col>
              </v-row>
              <v-row class="form-group">
                <v-col cols="6" md="4" lg="2">
                  <strong> {{use_language.salesperson}} : </strong>
                </v-col>
                <v-col cols="6" md="8" lg="10" v-if="!editSaleman">
                  {{ detail_order.salemanName }}
                </v-col>
                <v-col cols="6" md="8" lg="10" v-else>
                  <v-select
                    v-model="staffShop"
                    :items="staffShopList"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    return-object
                    dense hide-details
                    outlined
                    single-line
                    style="max-width: 250px;"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="form-group">
                <v-col cols="6" md="4" lg="2">
                  <strong> {{use_language.channel}} : </strong>
                </v-col>
                <v-col cols="6" md="8" lg="10" v-if="!editSaleman">
                  <v-row justify-center>
                    <v-avatar v-if="detail_order.channelLogo!='' && detail_order.channelLogo!=null" size="25px" class="mr-2"><img :src="detail_order.channelLogo"/></v-avatar>
                    {{ detail_order.channelName }}
                  </v-row>
                </v-col>
                <v-col cols="6" md="8" lg="10" v-else>
                  <v-select
                    v-model="channel"
                    :items="channelList"
                    item-text="channelName"
                    item-value="id"
                    persistent-hint
                    return-object
                    dense hide-details
                    outlined
                    single-line
                    style="max-width: 250px;"
                  >
                  <template slot="selection" slot-scope="data">
                    <v-avatar v-if="data.item.logoPic!=null" size="25px" class="mr-2"><img :src="data.item.logoPic"/></v-avatar>
                    {{ data.item.channelName }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-avatar v-if="data.item.logoPic!=null" size="25px" class="mr-2"><img :src="data.item.logoPic"/></v-avatar>
                    {{ data.item.channelName }}
                  </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="form-group" v-if="orderItemTempList.length==0">
                <v-col cols="6" md="4" lg="2">
                  <strong> {{use_language.reference_number}} : </strong>
                </v-col>
                <v-col cols="6" md="8" lg="10" v-if="!editSaleman">
                  <span v-if="detail_order.referenceNumber != null">{{ detail_order.referenceNumber }}</span>
                  <span v-else> - </span>
                </v-col>
                <v-col cols="6" md="8" lg="10" v-else>
                  <v-text-field
                    :value="detail_order.referenceNumber"
                    @change="value => detail_order.referenceNumber = Trim_value(value)"
                    autocomplete="off"
                    dense hide-details
                    outlined
                    style="max-width: 250px;"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="form-group" v-if="editSaleman">
                <v-col cols="12">
                  <v-btn @click="SummitSaleman()" dark color="primary">{{use_language.save}}</v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1" v-if="!platformReferenceSourceID.includes(this.detail_referenceSourceID) && this.detail_IsCOD == false ">
              <v-row class="form-group">
                <v-col cols="6"><h3>{{use_language.transfer_notification}}</h3></v-col>
                <v-col cols="6" align="right">
                    <v-btn class="hidden-xs-only" outlined color="success" @click="btnOpenAddSlip()  , isFullscreen = false, can_edit_cod()"><v-icon  left>fa-plus</v-icon>{{use_language.add_payment_statement}}</v-btn>
                    <v-btn class="hidden-sm-and-up" outlined color="success" @click="btnOpenAddSlip(), isFullscreen = true, can_edit_cod()"><v-icon left>fa-plus</v-icon>{{use_language.add_payment_statement}}</v-btn>
                </v-col>
              </v-row>
              <v-row class="form-group">
                <v-col style="background: #fff ;overflow-x: auto" cols="12"  >
                  <v-simple-table class="packhai-border-table" style="min-width:1240px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" style="width:60px"></th>
                          <th class="text-center" style="width:60px"></th>
                          <th class="text-center" style="width:120px">{{use_language.due_payment}}</th>
                          <th class="text-center" style="width:150px">{{use_language.transfer_date}}</th>
                        
                          <th class="text-center" style="width:180px">{{use_language.bank}}</th>
                          <th class="text-center" style="width:90px">{{use_language.quantity}}</th> 
                          <th class="text-center"  style="width:90px">{{use_language.status}}</th>
                          <th class="text-center"  style="width:150px">{{use_language.remark}}</th>
                          <th class="text-center" style="width:90px">{{use_language.slip}}</th>
                          <th class="text-center"  style="width:150px">{{use_language.updated}}</th>
                          <th class="text-center"  style="width:90px">{{use_language.updated_by}}</th>
                       
                          <!-- <th class="text-center" v-if="detail_statusId == 20 && CanApprove ==1">{{use_language.delete}}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in detailOrderPayment"
                          :key="item.name"
                        >
                    
                          <td class="text-center"> <v-btn v-if="item.id!=0" icon @click="btnEditPayment(item)" color="primary"><v-icon class="fn-18" color="primary">fa-edit</v-icon></v-btn> </td>
                          <td class="text-center"> <v-btn v-if="item.id!=0" icon @click="delete_data_payment_alert(item)" color="primary"><v-icon  class="fn-18" color="red">fa-trash-alt</v-icon></v-btn> </td>
                          <td class="text-center">{{ set_format_date_time(item.dueDate).substr(0,10) }}</td>
                          <td class="text-center">{{ set_format_date_time(item.informDatetime) }}</td>
                         
                          <td class="text-left">{{ item.bankAccountText }}</td>
                          <td class="text-right">{{ format_price(item.amount) }}</td>
                          <td class="text-center">
                            <span v-if="item.statusID==1">ร่าง</span>
                            <span style="color:orange" v-if="item.statusID==2">รอชำระ</span>
                            <span style="color:purple" v-if="item.statusID==3">ติดตามยอด</span>
                            <span style="color:green" v-if="item.statusID==4">ชำระแล้ว</span>
                          </td>
                          <td class="text-left">{{  item.remark }}</td>
                          <td class="text-lecenterft">
                              <v-img v-if="item.photoLink!=null" style="margin:5px;"
                              :src="item.photoLink"
                              aspect-ratio="1"
                              class="grey lighten-2"
                              max-width="50"
                              max-height="50"
                              @click="OpenPhoto(item.photoLink, 0)"
                          ></v-img>
                          </td>
                          <td class="text-center">{{ set_format_date_time((item.updateDateTime == null? item.createdDatetime  :item.updateDateTime) ) }}</td>
                          <td class="text-center">{{  (item.updateByName == null? item.createdByName  :item.updateByName)}}</td>
                          <!-- <td class="text-center" style="width: 240px" v-if="detail_statusId == 20 && CanApprove ==1" >
                            <v-row class="pa-0" v-if="item.disApproved == null && item.approved == null ">
                              <v-col cols="12" md="6" class="pa-0 py-2">
                                <v-btn @click="approvePaymentAlert(item.id, item.orderMasterID), show_reject = false" dark color="success" class="mx-2"><v-icon left>fa-check-circle</v-icon>{{use_language.approve}}</v-btn>
                              </v-col>
                              <v-col cols="12" md="6" class="pa-0 py-2">
                                <v-btn @click="rejectPaymentAlert(item.id, item.orderMasterID), show_reject = true" dark outlined color="danger" class="mx-2"><v-icon left>fa-times-circle</v-icon>{{use_language.reject}}</v-btn>
                              </v-col>
                            </v-row>
                            <span v-else-if="item.approved != null" style="color: green;"> <b>{{use_language.approve}}</b></span>
                            <span v-else-if="item.disApproved != null" style="color: red;"><b>{{use_language.reject}}</b></span>
                          </td> -->
                        
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br>
                  <v-btn v-if="CanApprove ==1 && show_reject == true" @click="backStatusOrderToOpen()" dark color="danger" class="mx-2"><v-icon left>fa-undo-alt</v-icon>{{use_language.Bounce_order}}</v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1" >
              <v-row class="form-group">
                <v-col cols="6"><h3>ไฟล์แนบ</h3></v-col>
                <v-col cols="6" align="right">
                   <v-btn color="success" outlined @click="dialog_doc = true"><v-icon left>fa-plus</v-icon>{{use_language.Adddocument}}</v-btn>
                </v-col>
              </v-row>
              <v-row class="form-group">
                <v-col style="background: #fff ;" cols="12" >
                  <v-simple-table class="packhai-border-table">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">File url</th>
                          <th class="text-left">ประเภท</th>
                          <th class="text-left">ลบ</th>
                          <!-- <th class="text-center" v-if="detail_statusId == 20 && CanApprove ==1">{{use_language.delete}}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in fileAttach"
                          :key="item.id"
                        >
                          <td class="text-left">
                            <a :href="item.fileUrl" target="_blank">
                          {{  item.fileUrl }}
                          <input type="hidden" id="testing-code" style="width:100%" v-model="item.fileUrl"/>
                        </a>

                          </td>
                          <td class="text-left">{{ item.documentType }}</td>
                          <td class="text-center" width="60px"> 
                            <a @click="DeleteFileAttach(item.id)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br>
                  <v-btn v-if="CanApprove ==1 && show_reject == true" @click="backStatusOrderToOpen()" dark color="danger" class="mx-2"><v-icon left>fa-undo-alt</v-icon>{{use_language.Bounce_order}}</v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-dialog v-model="dialog_doc" persistent max-width="800">
              <v-card>
                  <v-card-title>
                      {{ use_language.Adddocument }}
                      <v-spacer />
                      <v-btn icon @click="dialog_doc = false">
                          <v-icon>mdi-close</v-icon>
                      </v-btn>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                      <v-row>
                          <v-col cols="12" sm="3" lg="3" class="pb-0">
                              <h4>{{ use_language.category_ }} :</h4>
                          </v-col>
                          <v-col cols="12" sm="9" lg="5" class="pb-0">
                            <v-select
                              v-model="input_doc_select"
                              ref="input_doc_select"
                              :items="[{'value': null,'name' : use_language.please_select_},{'value': 'ใบปะหน้า','name' : use_language.print_cover_sheet},{'value': 'ใบกำกับภาษี','name' : use_language.tax_invoice}]"
                              item-text="name"
                              item-value="value"
                              outlined
                              dense
                              hide-details="auto"
                            ></v-select>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col cols="12" sm="3"  lg="3" class="pb-0">
                              <h4>{{use_language.document}} :</h4>
                          </v-col>
                          <v-col cols="12" sm="9" lg="5" class="pb-0">
                              <v-file-input
                                  v-model="input_docFileUpload"
                                  ref="input_docFileUpload"
                                  accept="image/x-png,image/gif,image/jpeg, application/pdf"
                                  :placeholder="use_language.supports_"
                                  outlined
                                  hide-details="auto"
                                  dense
                                  prepend-icon=""
                                  prepend-inner-icon="mdi-file"
                              >
                              <template v-slot:label> <span>{{use_language.supports_}}</span> </template>
                                  <template v-slot:selection="{ text }">
                                      <v-chip
                                          small
                                          label
                                          color="primary"
                                      >
                                          {{ text }}
                                      </v-chip>
                                  </template>
                              </v-file-input>
                          </v-col>
                      </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="p2-5">
                      <v-btn
                          outlined
                          color="primary"
                          @click="submit_docFile()">
                          {{ use_language.Adddocument }}
                      </v-btn>
                  </v-card-actions>
              </v-card>
            </v-dialog>

            <div v-if="packageList!=null && packageList.length>0"> 
              <div  v-for="pac in packageList" :key="pac.id">
                <v-card elevation="1" class="v-card-bottom-30" v-if="detail_order['shippingStatusID'] >= 30">
                  <v-card-text>
                  
                    <v-row>
                      <v-col cols="12"><h3>{{use_language.box_pack}} #{{ pac.packageNo }}</h3></v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.weight}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.weight != null">{{ pac.weight }} {{use_language.kg}}</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.width_length_height}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.cubic1 != null && pac.cubic2 != null && pac.cubic3 != null">{{ pac.cubic1 +' x '+ pac.cubic2 +' x '+ pac.cubic3 }}</span>
                        <span v-else> - </span>
                        <span v-if="pac.boxName!=null && pac.boxName!=''" >
                          / กล่อง {{ pac.boxName }}
                        </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.freight}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.deliveryPrice != null">{{ pac.deliveryPrice }} {{use_language.bath}}</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.remote_area}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.remotePrice != null">{{ pac.remotePrice }} {{use_language.bath}}</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> % COD : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.codPercent">{{ pac.codPercent }} %</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.fee_cod}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.codCharged != null">{{ pac.codCharged }} {{use_language.bath}}</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.box_fee}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.boxPrice != null">{{ pac.boxPrice }} {{use_language.bath}}</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.pack_fee}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.packPrice != null">{{ pac.packPrice }} {{use_language.bath}}</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.bubble_fee}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.bubblePrice != null">{{ pac.bubblePrice }} {{use_language.bath}}</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.otherservice_fee}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.otherServicePrice != null">{{ pac.otherServicePrice }} {{use_language.bath}}</span>
                        <span v-else> - </span>
                      </v-col>
                      <v-col v-if="pac.vrichTransactionFee != null" cols="4" md="3" lg="2" class="form-group pb-0">
                        <b> {{use_language.vrichTransaction_fee}} : </b>
                      </v-col>
                      <v-col v-if="pac.vrichTransactionFee != null" cols="8" md="9" lg="10" class="pb-0">
                        <span >{{ detail_VrichTransactionFee }} {{use_language.bath}}</span>
                      </v-col>
                      <v-col cols="4" md="3" lg="2" class="form-group">
                        <b> {{use_language.discount}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-0">
                        <span v-if="pac.discount != null">{{ pac.discount }} {{use_language.bath}}</span>
                        <span v-else> - </span>
                      </v-col>
                    </v-row>
                     <v-divider>
                     </v-divider>
                    <v-row>
                      <v-col cols="4" md="3" lg="2" class="form-group ">
                        <b> {{use_language.transport}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10"  >
                        <div v-if="pac.trackStatus!=null" align="left" >
                          <v-img v-if=" pac.trackStatus.expressLogo != null" max-height="10vh" max-width="10vh"  :src="pac.trackStatus.expressLogo">
                          </v-img>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" md="3" lg="2" class="form-group  ">
                        <b> {{use_language.tracking_no}} : </b>
                      </v-col>
                      <v-col cols="8" md="9" lg="10" >
                        <div>
                          {{  pac.trackStatus.trackingNo  }}
                          <div class="pb-2" />
                          <div v-if="pac.trackStatus!=null" class="hidden-sm-and-down">
                            <div v-if="pac.trackStatus.status != null && pac.trackStatus.status.length>0">
                              <div v-for="(item, index) in pac.trackStatus.status" :key="index">
                                <span> {{formatDatetime(item.date) +" "+ item.description}} </span>
                              </div>
                            </div>

                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <div v-if="pac.trackStatus!=null" class="hidden-sm-and-up">
                      <div v-if="pac.trackStatus.status!=null && pac.trackStatus.status.length>0">
                        <v-row class="pb-2" v-for="(item, index) in pac.trackStatus.status" :key="index">
                          <v-col cols="4" class="pb-0" >
                            <span> {{formatDatetime(item.date)    }} </span>
                          </v-col>
                          <v-col cols="8" class="pb-0">
                            <span > {{ item.description }}</span>
                          </v-col>
                        </v-row>
                      </div>

                    </div>
                    <v-row class="pb-2">
                      <v-col cols="4" md="3" lg="2" class="form-group "> <b>สถานะพัสดุ : </b> </v-col>
                      <v-col cols="8" md="9" lg="10" class="pb-2">
                        <div v-if="pac.trackStatus!=null && pac.trackStatus.packageStatusName != ''">
                          <span v-if="pac.trackStatus.packageStatusID == 80" style="color: purple;">{{ pac.trackStatus.packageStatusName }} </span>
                          <span v-else-if="pac.trackStatus.packageStatusID == 90" style="color: orange;">{{ pac.trackStatus.packageStatusName }} </span>
                          <span v-else-if="pac.trackStatus.packageStatusID == 100" style="color: #ff7ac0;">{{ pac.trackStatus.packageStatusName }} </span>
                          <span v-else-if="pac.trackStatus.packageStatusID == 110" style="color: #ff7ac0;">{{ pac.trackStatus.packageStatusName }} </span>
                          <span v-else-if="pac.trackStatus.packageStatusID == 120" style="color:  #ff7ac0;">{{ pac.trackStatus.packageStatusName }} </span>
                          <span v-else-if="pac.trackStatus.packageStatusID == 130" style="color:  green;">{{ pac.trackStatus.packageStatusName }} </span>
                          <span v-else-if="pac.trackStatus.packageStatusID == 140" style="color:  red;">{{ pac.trackStatus.packageStatusName }} </span>
                          <span v-else>{{ pac.trackStatus.packageStatusName }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <div v-else>
              <v-card elevation="1" class="v-card-bottom-30" v-if="detail_order['shippingStatusID'] >= 30">
                <v-card-text>
                
                  <v-row>
                    <v-col cols="12"><h3>{{use_language.box_pack}} #{{ pac.packageNo }}</h3></v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.weight}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_Weight != null">{{ detail_Weight }} {{use_language.kg}}</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.width_length_height}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_Width != null && detail_Height != null && detail_Long != null">{{ detail_Width +' x '+ detail_Height +' x '+ detail_Long }}</span>
                      <span v-else> - </span>
                      <span v-if="detail_BoxName!=null && detail_BoxName!=''" >
                        / กล่อง {{ detail_BoxName }}
                      </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.freight}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_DeliveryPrice != null">{{ detail_DeliveryPrice }} {{use_language.bath}}</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.remote_area}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_RemotePrice != null">{{ detail_RemotePrice }} {{use_language.bath}}</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> % COD : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_CODPercent">{{ detail_CODPercent }} %</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.fee_cod}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_CODCharged != null">{{ detail_CODCharged }} {{use_language.bath}}</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.box_fee}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if=" detail_BoxPrice != null">{{ detail_BoxPrice }} {{use_language.bath}}</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.pack_fee}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_PackPrice != null">{{ detail_PackPrice }} {{use_language.bath}}</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.bubble_fee}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_BubblePrice != null">{{ detail_BubblePrice }} {{use_language.bath}}</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.otherservice_fee}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_OtherServicePrice != null">{{ detail_OtherServicePrice }} {{use_language.bath}}</span>
                      <span v-else> - </span>
                    </v-col>
                    <v-col v-if="detail_VrichTransactionFee != null" cols="4" md="3" lg="2" class="form-group pb-0">
                      <b> {{use_language.vrichTransaction_fee}} : </b>
                    </v-col>
                    <v-col v-if="detail_VrichTransactionFee != null" cols="8" md="9" lg="10" class="pb-0">
                      <span >{{ detail_VrichTransactionFee }} {{use_language.bath}}</span>
                    </v-col>


                    <v-col cols="4" md="3" lg="2" class="form-group">
                      <b> {{use_language.discount}} : </b>
                    </v-col>
                    <v-col cols="8" md="9" lg="10" class="pb-0">
                      <span v-if="detail_Discount != null">{{ detail_Discount }} {{use_language.bath}}</span>
                      <span v-else> - </span>
                    </v-col>
                  </v-row>
                
                </v-card-text>
              </v-card>
            </div>

            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1" v-if="this.detail_TrackingNo == null">
              <v-row>
                <v-col cols="6"><h3>{{use_language.transport}}</h3></v-col>
                <v-col cols="6" align="right">
                  <v-btn v-if="detail_statusId < 40 && !platformReferenceSourceID.includes(this.detail_referenceSourceID)" :disabled="!authorize"  icon class="ma-0"  @click="can_edit_data_express = !can_edit_data_express"><v-icon size="18" >fa-edit</v-icon></v-btn>
                </v-col>
                <div v-if="can_edit_data_express == true">

                  <v-col style="background: #fff ;" cols="12" >
                    <v-radio-group
                        v-model="ExpressUniqueID"
                        required
                    >
                        <v-radio
                           ExpressUniqueID
                            v-for="item in shop_express_list"
                            :key="item.uniqueID" 
                            :value="item.uniqueID.toString()"> 
                            <template v-slot:label>
                            <img style="width:60px" :src="item.logoPic"/>
                            <span class="pl-3">  {{ item.expressCompanyName }}</span>
                            </template>
                        ></v-radio>
                    </v-radio-group>
                    <br>
                    <v-btn color="primary" @click="update_shop_express_id()">{{use_language.save}}</v-btn>
                  </v-col>
                </div>
                <v-col v-else >
                  <div v-if="this.detail_ExpressCompanyID != null" align="left">
                    <span v-if="this.detail_ExpressCompanyID == 0">{{use_language.pick_up_goods_by_yourself}}</span>
                    <div v-else>
                      <v-img max-height="10vh" max-width="10vh"  :src="img_express_return(detail_ExpressCompanyID)"></v-img>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>

            

            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1" v-if="this.detail_RealTransferDatetime != null">
              <v-row>
                <v-col cols="12"><h3>{{use_language.date_of_deposit}}</h3></v-col>
                <v-col style="background: #fff ;" cols="12" >
                  {{ this.detail_RealTransferDatetime }}
                </v-col>
              </v-row>
            </v-card>

            <!-- รูปภาพแพ็กของ -->
            <v-card class="px-5 py-5 v-card-bottom-30" elevation="1" v-if="this.detail_statusId >= 30 && data_master_pic.length > 0">
              <v-row v-if="data_master_pic.length > 0">
                <v-col cols="12"><h3>{{use_language.product_pack_picture}}</h3></v-col>
                <v-col  style="background: #fff ;" cols="4" md="3" xl="2" lg ="2" v-for="item_pic in data_master_pic" :key="item_pic.id">
                  <v-card >
                        <v-img style="margin:5px;"
                        :src="item_pic.pic"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        @click="image_click(item_pic.pic, item_pic.mediaType)"
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="data_master_media.length > 0">
                <v-col cols="12"><h3>{{use_language.video_pack}}</h3></v-col>
                <div v-for="item_media in data_master_media" :key="item_media.id"  style=" width: 100%;">
                  <v-col cols="12" md="6" lg="5" xl="4">
                    <div class="hidden-xs-only">
                      <iframe :src="item_media.pic" allow="autoplay" scrolling="no" frameborder="0" height="400" style="position: relative; width: 100%;" ></iframe>
                    </div>
                    <div class="hidden-sm-and-up">
                      <iframe :src="item_media.pic" allow="autoplay" scrolling="no" frameborder="0" height="200" style="position: relative; width: 100%;" ></iframe>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="7" xl="8"></v-col>
                </div>
              </v-row>

            </v-card>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- เพิ่มรายการชำระเงิน -->
    <v-dialog v-model="dialog_add_slip" :fullscreen="isFullscreen"  scrollable max-width="800" min-width="800" persistent >
      <v-card class="my-0">
        <v-card-title  >
          <span v-if="input_transferId==0">{{use_language.add_payment_notification}}</span>
          <span v-else>{{use_language.edit_payment_notification}}</span>
          <v-spacer />
          <v-btn icon  @click="btnClosePayment()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text  >
          <v-row  >
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="3" lg="2" class="pb-0">
                    <h4>สถานะ :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                  <v-radio-group row v-model="input_transferStatusID">
                    <v-radio  label="ร่าง" value="1"> </v-radio>
                    <v-radio  label="รอชำระ" value="2"> </v-radio> 
                    <v-radio  label="ชำระแล้ว" value="4"> </v-radio>
                  </v-radio-group>
                 
                  </v-col>
              </v-row>
              <v-row>
                  <v-col cols="12" md="3" lg="2" class="pb-0">
                      <h4>{{use_language.transfer_amount}} :</h4>
                  </v-col>
                  <v-col cols="12" sm="9" lg="10" class="pb-0">
                      <v-text-field
                          :value="input_transferAmount"
                          @change="value => input_transferAmount = value"
                          autocomplete="off"
                          ref="input_transferAmount"
                          type="number"
                          outlined
                          hide-details
                          dense
                      ></v-text-field>
                  </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" lg="2" class="pb-0">
                    <h4>วันที่ดิวชำระ :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                 
                  <v-menu
                          v-model="menu_due_date"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                          <template v-slot:activator="{ on }">
                              <v-text-field
                                  :value="computed_due_date"
                                  @change="value => computed_due_date = value"
                                  autocomplete="off"
                                  ref="select_dueDate"
                                  outlined
                                  prepend-icon=""
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  hide-details
                                  dense
                                  v-on="on"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="select_dueDate"
                              no-title 
                              @input="menu_due_date = false"
                          ></v-date-picker>
                      </v-menu>
                </v-col>
              </v-row>
              <v-row v-if="input_transferStatusID=='4'">
                  <v-col cols="12" md="3" lg="2" class="pb-0">
                      <h4>{{use_language.real_payment_date}} :</h4>
                  </v-col>
                  <v-col cols="12" md="5" lg="4" class="pb-0">
                      <v-menu
                          v-model="menu_transfer_date"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                          <template v-slot:activator="{ on }">
                              <v-text-field
                                  :value="computed_transfer_date"
                                  @change="value => computed_transfer_date = value"
                                  autocomplete="off"
                                  ref="select_transferDate"
                                  outlined
                                  prepend-icon=""
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  hide-details
                                  dense
                                  v-on="on"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="select_transferDate"
                              no-title 
                              @input="menu_transfer_date = false"
                          ></v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="6" md="2" lg="3"  class="pb-0">
                      <v-select :value="select_transferHour" hide-details dense @change="value => select_transferHour = value" label="ชั่วโมง" autocomplete="off" ref="select_transferHour" :items="transferHourItems" outlined></v-select>
                  </v-col>
                  
                  <v-col cols="6" md="2" lg="3"  class="pb-0">
                      <v-select :value="select_transferMinute" hide-details dense @change="value => select_transferMinute = value" label="นาที" autocomplete="off" ref="select_transferMinute" :items="transferMinuteItems" outlined></v-select>
                  </v-col>
              </v-row>
              
              <v-row v-if="input_transferStatusID=='4'">
                <v-col cols="12" md="3" lg="2" class="pb-0">
                    <h4>{{use_language.bank}} :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                  <v-select
                        v-model="paymentBank"
                        :items="shop_bank_account_to_show"
                        item-text="fullBankName"
                        item-value="id"
                        persistent-hint
                        dense hide-details
                        outlined
                        return-object

                        style="width:100%"
                      ></v-select>
                     
                  </v-col>
              </v-row>
              <v-row >
                <v-col cols="12" md="3" lg="2" class="pb-0">
                    <h4>{{use_language.remark}} :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                  <v-text-field
                      v-model="input_transferRemark"
                      autocomplete="off"
                      outlined
                      hide-details
                      dense
                  ></v-text-field>
                     
                  </v-col>
              </v-row>
              <v-row v-if="input_transferStatusID=='4'">
                  <v-col cols="12" md="3" lg="2" class="pb-0">
                      <h4>{{use_language.attach_slip}} :</h4>
                  </v-col>
                  <v-col cols="12" sm="9" lg="10" class="pb-0">
                      <v-file-input
                          v-model="input_transferFileUpload"
                          ref="input_transferFileUpload"
                          outlined
                          hide-details
                          dense
                          prepend-icon=""
                          prepend-inner-icon="mdi-file-image"
                      >
                          <template v-slot:selection="{ text }">
                              <v-chip
                                  small
                                  label
                                  color="primary"
                              >
                                  {{ text }}
                              </v-chip>
                          </template>
                      </v-file-input>
                  </v-col>
              </v-row>
             
            
             
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
            <v-card-actions class="py-5" style="justify-content: flex-end;">
                <v-btn
                    outlined
                    color="primary"
                    @click="btnSavePayment()"
                >
                    {{use_language.save}}
                </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addOrderPopup.Dialog" scrollable :max-width="addOrderPopup.MaxWidth" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <table  style="width: 100%">
            <tr>
              <td style="width: 120px;">
                <v-select dense outlined hide-details   max-width="50px"
                  label="ค้นหาโดย"
                  v-model="StockSearchType"
                  :items="['ทั้งหมด', 'ชื่อ', 'sku', 'barcode']"
                ></v-select>
              </td>
              <td  style="width: 140px;">
                
              <v-select dense outlined hide-details 
                label="ประเภท"
                v-model="StockIsProductSet"
                :items="['ทั้งหมด', 'สินค้าไม่จัดเซ็ท', 'สินค้าจัดเซ็ท' ]"
              ></v-select>
              </td>
              <td class="px-2 hidden-xs-only" >
                <v-text-field
                  ref="refSearchStockModelPopup"
                  :value="searchStockModelPopup"
                  autocomplete="off"
                  class="pl-2"
                  dense
                  hide-details
                  @change="value => searchStockModelPopup = Trim_value(value)"
                  @keyup.enter="PopupsearchStock(limits,0)"
                ></v-text-field>
              </td>
              <td style="width: 40px;" class="hidden-xs-only">
                <v-btn color="primary" class="mr-1 px-2" @click="PopupsearchStock(limits,0)">
                  <v-icon center >mdi-magnify</v-icon>
                    {{use_language.search}}
                </v-btn>
              </td>
              <td style="width: 40px;">
                <v-btn icon @click="addOrderPopup.Dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
              </td>
            </tr>
          </table> 
        </v-card-title>
        <v-divider/>

        <!-- PC -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only" :style="{height: addOrderPopup.MaxHeight+'px'}">
          <v-data-table
            :headers="addOrderPopup.headers"
            :items="addOrderPopup.Data"
            item-key="id"
            :items-per-page="90000000000"
            hide-default-footer
            class="elevation-0 packhai-checkbox-select"
          >
          <template v-slot:item.selectProduct="{ item }">
            <v-checkbox v-model="item.selectProduct" color="red" hide-details @click="ProductSelectCheckbox(item,addOrderPopup.Data.indexOf(item))"></v-checkbox>
          </template>
          <template v-slot:item.photoLink="{ item }">
            <v-btn v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
              <img :src="item.photoLink" height="30">
            </v-btn>
          </template>
          <template v-slot:item.name="{ item }">
            <v-row class="mb-3 mt-3">
              <v-col cols="12" align="left" class="pa-0">
                <div class="fn-13" style="font-weight: bold ">
                  {{ item.name }}</div>
              </v-col>
        
              <v-col cols="12" align="left" class="pa-0" v-if="item.productCode!=null && item.productCode!=''">
                <span class="color-gray-13">
                    {{!item.productCode ? "" :  use_language.number_product + " : "}}  {{ item.productCode }}
                </span>
              </v-col>
        
        
              <v-col cols="12" align="left" class="pa-0" v-if="item.prop1Description!=null && item.prop1Description!=''">
                <span class="color-gray-13">
                  {{  use_language.type+ " : "+ item.prop1Description + (item.prop2Description==null? "":", "+item.prop2Description)}}
                </span>
              </v-col>
            
            
              <v-col cols="12" align="left" class="pa-0">
                <span class="color-gray-13">{{!item.barcode ? "" : use_language.barcode +" : "}} {{ item.barcode }}   </span>
              </v-col>

          

          
              <v-col cols="12" v-if="item.isProductSet" align="left" class="pa-0">
                <span  class="fn-13" >
                  <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
                    {{use_language.set_products}}
                </span>
              </v-col>
            </v-row>
              
          </template>
          <template v-slot:item.unitPrice="{ item }">
            <span v-if="item.unitPrice==null || item.unitPrice==0">   </span>
            <span v-else>  {{ format_price(item.unitPrice==null || item.unitPrice=='' || item.unitPrice==0 ? '' : item.unitPrice) }} </span>
          </template>
          <template v-slot:item.quantityAvailable="{ item }">
            <span  > {{ formatMoney(item.quantityAvailable) }} </span>
          </template>
          </v-data-table>
        </v-card-text>
        <!-- PC -->

        <!-- Mobile -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-sm-and-up" :style="{height: addOrderPopup.MaxHeight+'px'}">
          <table  style="width: 100%" class="hidden-sm-and-up">
              <tr>
                
               
                <td class="px-2">
                  <v-text-field
                    ref="refSearchStockModelPopup"
                    :value="searchStockModelPopup"
                    autocomplete="off"
                 
                    dense
                    hide-details
                    @change="value => searchStockModelPopup = Trim_value(value)"
                    @keyup.enter="PopupsearchStock(limits,0)"
                  ></v-text-field>
                </td>
                <td style="width: 40px;">
                  <v-btn color="primary" class="mr-1 px-2" @click="PopupsearchStock(limits,0)">
                    <v-icon center >mdi-magnify</v-icon>
                      {{use_language.search}}
                  </v-btn>
                </td>
               
              </tr>
            </table>
          <v-data-iterator
            :items="addOrderPopup.Data"
            :items-per-page="900000000"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-card v-for="item in props.items" :key="item.id" class="mt-4"   >
                <v-card-text class="pa-0" >
                  <v-row>


                    <v-col cols="12">

                      <b> {{ item.name }}</b>
                    </v-col>
                    <v-col cols="3">
                      <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)"   >
                        <img :src="item.photoLink" style="width:100%">
                      </a>
                      <a v-else   style="width:100%" >
                        <span class="bold-color-gray-12"> No Picture </span>
                      </a>
                    </v-col>
                    <v-col cols="9" >
                      <v-row class="pa-0">
                        <v-col cols="12" class="pa-0">
                          <span v-if="item.prop1Description!=null && item.prop1Description!= ''"> {{use_language.type}} : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col cols="12" class="pa-0">
                          <span v-if="item.sku!=null && item.sku!=''">  {{use_language.sku}} : {{ item.sku }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col cols="12" class="pa-0">
                          <span v-if="item.barcode!=null && item.barcode!=''">  {{use_language.barcode}} : {{ item.barcode }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col cols="12" class="pa-0">
                          <span  >  {{use_language.selling_price}} : {{ format_price(  item.salePrice ) }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col cols="12" class="pa-0">
                          <span  >  {{use_language.quantity}} : {{ formatMoney(item.quantityAvailable) }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col cols="12" v-if="item.isProductSet" align="left" class="pa-0">
                        <span  class="fn-13" >
                          <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
                            {{use_language.set_products}}
                        </span>
                        </v-col>
                      </v-row>


                      
                    </v-col>
                    <v-col cols="12">
                      <v-btn v-if="item.selectProduct" style="width:100%" left  depressed color="primary" @click="ProductSelect(item, addOrderPopup.Data.indexOf(item)  )"> <v-icon left> mdi-checkbox-multiple-marked-circle-outline</v-icon> {{use_language.choose}} </v-btn>
                      <v-btn v-else outlined   depressed color="primary" style="width:100%" @click="ProductSelect(item, addOrderPopup.Data.indexOf(item))  " > {{use_language.choose}} </v-btn>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-data-iterator>
        </v-card-text>
        <!-- Mobile -->

        <div v-if="pageLength>1 && addOrderPopup.Data.length>0" class="text-center pa-1" @click="changePage()">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="pageTotalVisible"
            color="error"
          ></v-pagination>
        </div>

        <v-divider/>
        <div class="height-57 pa-2 " align="right">
          <v-btn color="primary" class="mr-2" :loading="addOrderPopup.loading" @click="Submit_addOrderPopup()">
              {{use_language.add_product}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_photo" max-width="1200">
      <v-card class="my-0">
        <v-card-text>
          <v-row v-show="media_type == 0">
            <v-col cols="12">
              <v-img v-if="dialog_photo_link != null" :src="dialog_photo_link" aspect-ratio="1.5" contain></v-img>
            </v-col>
          </v-row>
          <v-row v-show="media_type == 1">
            <v-col cols="12">
                <iframe src="https://drive.google.com/file/d/1MryzbX1BLDrJofcvM5X8eIfApWEd-bS5/preview" width="640" height="480" allow="autoplay"></iframe>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_not_enough_stock" persistent scrollable max-width="800">
        <v-card elevation="1" class="ma-0">
          <v-card-title >
            <v-spacer/>
            <v-btn icon @click="dialog_not_enough_stock=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <!-- <v-divider/> -->
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <span style="color: red">*** {{ dialog_massage }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-data-table
                    :headers="[
                        { text: 'รูป', value: 'photoLink', align: 'center', sortable: false },
                        { text: 'สินค้า', value: 'name', align: 'left', sortable: false, width: 200 },
                        { text: 'จำนวนที่มี', value: 'quantityAvailable', align: 'right', sortable: false },
                        { text: 'ที่ต้องใช้', value: 'quantityRequest', align: 'right', sortable: false },
                        { text: 'ขาดสต๊อก', value: 'quantityShortage', align: 'right', sortable: false, width: 10 }
                    ]"
                    :items="orderItemProductResponse"
                    :items-per-page="10000"
                    hide-default-footer
                    class="packhai-border-table packhai-table"
                >
                <template v-slot:item.photoLink="{ item }">
                    <img v-if="item.photoLink != null" style="width: 50px" :src="item.photoLink" @click="image_click(item.photoLink)"/>
                </template>
                <template v-slot:item.name="{ item }">
                  <span>{{ item.name }}</span><br>
                  <span style="color: #A9A9A9">SKU : {{ item.sku }}</span><br>
                  <span style="color: #A9A9A9">ลักษณะ : {{ item.propDescription }}</span>
                </template>
                <template v-slot:item.quantityAvailable="{ item }">
                    {{ format_number(item.quantityAvailable) }}
                </template>
                <template v-slot:item.quantityRequest="{ item }">
                    {{ format_number(item.quantityRequest) }}
                </template>
                <template v-slot:item.quantityShortage="{ item }">
                    <span style="color: red">{{ format_number(item.quantityShortage) }}</span>
                </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
    </v-dialog>

      <!-- แยกพัสดุ -->
  <v-dialog
    v-model="splitPackagePopup.dialog"
    scrollable
    :max-width="splitPackagePopup.MaxWidth"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card elevation="1" class="ma-0">
      <v-card-title class="height-57 pa-0">
        <div class="v-list-item">
          <div class="v-list-item__content pa-2">
            ยืนยันพัสดุที่ต้องการแยก
          </div>
          <div>
            <v-btn icon @click="splitPackagePopup.dialog = false"
              ><v-icon color="danger">mdi-close</v-icon></v-btn
            >
          </div>
        </div>
      </v-card-title>
      <v-card>
        <v-row class="item-center">
          <v-col cols="2"><span>ประเภทการย้าย :</span></v-col>
          <v-col cols="6" >
                    <v-radio-group
                      v-model="splitPackageDataSet.radio"
                      row
                      hide-details
                    >
                    <v-radio
                        label="แยกกล่องใหม่"
                        value="0"
                      ></v-radio>
              <v-row>
                   <v-radio
                        label="ใส่ในกล่องที่"
                        value="1"
                      ></v-radio>
                   
                    <v-col cols="5">
                      <v-text-field
                      v-model.number="splitPackageDataSet.moveTo"
                      autocomplete="off"
                       
                      outlined
                      hide-details
                      dense
                      :disabled="splitPackageDataSet.radio != 1"
                      type="number"
                      >
                    </v-text-field>
                    </v-col>
                    
            </v-row>
            </v-radio-group>
                  
          </v-col>
        </v-row>
        <v-card-text class="overflow-x-auto pt-1 pb-2 hidden-xs-only" :style="{height: splitPackagePopup.MaxHeight+'px'}">
          <v-data-table
          :headers="splitPackageDataSet.headers"
          :items="splitPackageDataSet.dataSet"
           
          fixed-header
          :items-per-page="9999999999"
          hide-default-footer
          class="elevation-1 packhai-border-table packhai-table"
          item-value="productName"
          item-key="stockShopId"
          
        >
          <template v-slot:item.photoLink="{ item }">
            <a
              v-if="item.photoLink != null && item.photoLink != ''"
              icon
              @click="openPhoto(item.photoLink)"
            >
              <v-img
                style="margin: 0px 0px 0px 20px"
                v-if="item.photoLink != '' && item.photoLink != null"
                :src="item.photoLink"
                aspect-ratio="1"
                class="grey lighten-2"
                max-width="50"
                max-height="50"
              ></v-img>
            </a>
          </template>
          <template v-slot:item.moveQuantity="{ item }">
              <v-text-field
                  v-model.number="item.moveQuantity"
                  @change="value => item.moveQuantity = value"
                  autocomplete="off"
                  dense
                  hide-details
                  outlined
                  ref="quantity"
                  type="number"
              ></v-text-field>
          </template>
        </v-data-table>
        </v-card-text>
        
      </v-card>

      <v-divider />
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            เลือกทั้งหมด {{ splitPackageDataSet.dataSet.length }} รายการ
          </v-col>
          <v-col cols="6" align="right">
            <v-btn color="primary" @click="btnSubmitSplitPackage()">
              ดำเนินการ
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
   
   <v-dialog v-model="addPackagePopup.Dialog" scrollable :max-width="addPackagePopup.MaxWidth" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
        <div class="v-list-item">
          <div class="pa-2">
            ค้นหา 
          </div>
          <div class="v-list-item__content pa-2">
            <v-text-field
              v-model="addPackagePopup.searchStockModelPopup"
              autocomplete="off"
              class="pl-2"
              dense
              hide-details
               @keyup.enter="btnSearchPackagek(addPackagePopup.limits,0)"
                
            ></v-text-field>
          </div>
          <div class="pa-2">
            <v-btn
              :elevation="1"
              color="primary"
              class="pa-2"
              dark
              @click="btnSearchPackagek(addPackagePopup.limits,0)"
            >
              <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
              ค้นหา
            </v-btn>
          </div>
          <div>
            <v-btn icon @click="addPackagePopup.Dialog = false"
              ><v-icon color="danger">mdi-close</v-icon></v-btn
            >
          </div>
        </div>
      </v-card-title>
        <v-divider/>

        <!-- PC -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only" :style="{height: addPackagePopup.MaxHeight+'px'}">
          <v-data-table
            v-model="addPackagePopup.SelectPackage"
            :headers="addPackagePopup.headers"
            :items="addPackagePopup.Data"
            item-key="id"
            :items-per-page="90000000000"
            hide-default-footer
            show-select
            class="elevation-0 packhai-checkbox-select"
          >
          <template v-slot:header.data-table-select="{ on, props }">
            <span>เลือก</span>
          </template>
          <template v-slot:item.selectProduct="{ item }">
            <v-checkbox   color="red" hide-details ></v-checkbox>
          </template>
          <template v-slot:item.photoLink="{ item }">
            <v-btn v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
              <img :src="item.photoLink" height="30">
            </v-btn>
          </template>
          <template v-slot:item.name="{ item }">
            <v-row class="mb-3 mt-3">
              <v-col cols="12" align="left" class="pa-0">
                <div class="fn-13" style="font-weight: bold ">
                  {{ item.name }}</div>
              </v-col>
        
              <v-col cols="12" align="left" class="pa-0" v-if="item.productCode!=null && item.productCode!=''">
                <span class="color-gray-13">
                    {{!item.productCode ? "" :  use_language.number_product + " : "}}  {{ item.productCode }}
                </span>
              </v-col>
         
        
              <v-col cols="12" align="left" class="pa-0" v-if="item.prop1Description!=null && item.prop1Description!=''">
                <span class="color-gray-13">
                  {{  use_language.type+ " : "+ item.prop1Description + (item.prop2Description==null? "":", "+item.prop2Description)}}
                </span>
              </v-col>
            
            
              <v-col cols="12" align="left" class="pa-0">
                <span class="color-gray-13">{{!item.barcode ? "" : use_language.barcode +" : "}} {{ item.barcode }}   </span>
              </v-col>

          

          
              <v-col cols="12" v-if="item.isProductSet" align="left" class="pa-0">
                <span  class="fn-13" >
                  <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
                    {{use_language.set_products}}
                </span>
              </v-col>
            </v-row>
              
          </template>
          <template v-slot:item.packedQuantity="{ item }">
                  <v-text-field
                  v-model.number="item.packedQuantity"
                      autocomplete="off"
                      dense
                      hide-details
                      outlined
                      ref="quantity"
                      type="number"
                  ></v-text-field>
            </template>
          <template v-slot:item.unitPrice="{ item }">
            <span v-if="item.unitPrice==null || item.unitPrice==0">   </span>
            <span v-else>  {{ format_price(item.unitPrice==null || item.unitPrice=='' || item.unitPrice==0 ? '' : item.unitPrice) }} </span>
          </template>
          <template v-slot:item.quantityAvailable="{ item }">
            <span  > {{ formatMoney(item.quantityAvailable) }} </span>
          </template>
          </v-data-table>
        </v-card-text>
        <!-- PC -->

  
         
        <div v-if="addPackagePopup.pageLength>1 && addPackagePopup.Data.length>0" class="text-center pa-1" @click="btnChangePagePackage()">
          <v-pagination
            v-model="addPackagePopup.page"
            :length="addPackagePopup.pageLength"
            :total-visible="addPackagePopup.pageTotalVisible"
            color="error"
          ></v-pagination>
        </div>

        <v-divider/>
        <div class="height-57 pa-2 " align="right">
          <v-btn color="primary" class="mr-2" :loading="addPackagePopup.loading" @click="btnSubmitAddPackagePopup()">
              {{use_language.add_product}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from "sweetalert2"
  import {  customerWebsite,  generalService_dotnet, orderService_dotnet, shopService_dotnet, stockService_dotnet, logistic_Service, ecomService_dotnet } from '@/website/global'
  import { formatDatetime,format_number, format_order_id, format_price, isNumberWNoDot, formatMoney, china_order_fornat, Trim_value, Trim_rules,get_languages } from '@/website/global_function'
  // import { shippingStatusDict } from '@/website/statusDict'
  // import VueBarcode from 'vue-barcode'
  import { token_api  } from '@/website/backend/token'
  import dateTimePicker from "@/website/components/dateTimePicker"
  import moment from "moment";
 

  export default {
      components: {
          Loading,
          dateTimePicker,
          // 'barcode': VueBarcode
      },
      
      data: () => ({
        dialog_doc: false,
        input_doc_select : null,
        input_docFileUpload : null,

        //
        header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
          shop_id : localStorage.getItem("shop_id"),
          StaffShopID : localStorage.getItem("staff_id"),
          StaffBranchID : localStorage.getItem("staff_branch_id"),
          platformReferenceSourceID : [3,4,5,11],
          page_loading: true,
          loading: false,
          use_language: null,
          set_language: null,
          authorize:false,
          isPackhaiWarehouse:false,
          FindStockPopupResultCount:0,
          orderItemList:null,
          orderItemTempList:null,
          orderItemInvoiceList :null,
          orderItemInvoice_tmp:null,
          packageList:null,
          paymentBank:null,
          input_transferRemark:null,
          // แสดงภาพ
          ShowPhoto: false,
          ShowPhotoLink: '',
          fileAttach:[],
          // คลัง
          Warehouse_List: [],
          Warehouse: {},
          StockSearchType:'ทั้งหมด',
          StockIsProductSet:'ทั้งหมด',
          // สำหรับแก้ไข
          Warehouse_List_selected: null,
          Warehouse_List_selected_text: null,
          dialog_not_enough_stock:false,
          orderItemProductResponse : [],
          dialog_massage:'',
          order_detail_token : null,
          IsChangeStatusStage: true,
          IsApproveStage: true,
          IsDetailStage: true,
          IsCanEditCOD:true,
          // แก้ไข จำนวน ราคา เหมาๆ
          dialog_edit_item_mhao : false,
          edit_item_mhao_id : null,
          edit_item_mhao_quantity : null,
          edit_item_mhao_unit_price : null,

          IsHasSerial:false,
          canChangeStatusOrder: null,
          can_edit_warehouse: false,
          can_edit_status: false,

          // add slip
          dialog_add_slip: false,

          // edit product
          dialog_edit_stock :false,
          isFullscreen: false,

          StockList: [],
          searchStockModel: null,
          hideNoDataSearchStock: true,
          searchInputStock: null,
          productList: [],
          totalVat: 0,
          textField_deliveryCost: null,
          select_discountType: "฿",
          textField_discount: null,
          totalDiscount: 0,
          totalPrice: 0,
          select_vatType: 1,
          vat_percent: 7,
          textarea_remarkProduct: null,
          AllowChangeOrderStatus:false,
        

          // detail
          detail_order: null,

          detail_order_product: null,
          detail_order_box: null,

          detailOrder:[],
          detail_CreatedateTime: '',

          detailOrderProduct:[], 
          detailOrderProduct_tmp:[],
          detailOrderBox:[],
          detailOrderPayment:[],
          detailOrderPayment_temp:[],
          detail_ID:'',
          detail_statusId : '',
          detail_statusName : '',
          detail_ShopName: '',
          detail_ShopPhone: '',
          detail_ShopAddress:'',
          detail_ShipName: '',
          detail_ShipPhone: '',
          detail_line: '',
          detail_fackbook: '',
          detail_email: '',
          detail_edit_line: null,
          detail_edit_fackbook: null,
          detail_edit_email: null,

          detail_OrderDiscount : 0,
          detail_VATAmount : 0,
          detail_VATType : 1,

          detail_parcelStatusId:'',
          detail_parcelStatusName:'',
          detail_ShippingAddress:'',
          detail_BankName:'',
          detail_AccountNumber:'',
          detail_AccountName:'',
          detail_Remark:'',
          detail_BoxName:'',
          detail_Weight:'',
          detail_Width:'',
          detail_Height:'',
          detail_Long:'',
          detail_BoxPrice: '',
          detail_BubblePrice: '',
          detail_OtherServicePrice: '',
          detail_VrichTransactionFee: '',
          detail_CODPercent: '',
          detail_CODCharged: '',
          detail_Discount: '',
          detail_RemotePrice: '',
          detail_DeliveryPrice: '',
          detail_PackPrice: '',
          detail_TrackingNo: '',
          detail_ChannelName: '',
          detail_StaffName: '',
          detail_totalPrice: '',
          detail_orderTotalAmount:'',
          detail_DeliveryCost: '',
          detail_Token: '',
          detail_IsCOD: '',
          detail_CustomerType: '',
          get_token: '',
          detail_CODAmount: '',
          detail_SellerVoucher: '',
          detail_PlatformVoucher: '',
          detail_SellerDeliveryDiscount: '',
          detail_isSellerOwnFleet:false,

          detail_sum_unintPrice: '',
          detail_sum_unintPrice_and_DeliveryCost: '',
          detail_RealTransferDatetime: '',
          detail_ExpressCompanyID: '',
          detail_SalemanName: null,
          detail_ShopBankAccountID : null,
          data_pic: [],
          detail_TempSenderAddress: null,

          // transfer
          detail_CustomerTransferBankID : '',
          detail_CustomerTransferAmount: '',
          detail_CustomerTransferPhotoLink : '',
          detail_CustomerTransferCreatedDatetime : '',
          detail_CustomerTransferInformDatetime : '',

          detail_CustomerTransferBankName : '',
          detail_CustomerTransferAccountNumber : '',
          detail_CustomerTransferAccountName : '',
          detail_IsPreOrder: null,

          detail_ShippingAddressID: null,

          // shopee 
          detail_referenceSourceID: null,
          ReferenceNumber: null,
          // TempSenderAddress: null,
          TempAddress: null,
          ChannelSourceLogoPic: null,

          detail_shiping_staus_update_select: null,
          IsHasItemInvoice:false,
          IsAbleToEditOrderItem:false,
          marketplace_status: null,

          // changStatus
          change_items_status: [
            {text: 'เปิดออร์เดอร์', value: 10},
            {text: 'รอตรวจสอบยอด', value: 20},
            {text: 'รอแพ็คสินค้า', value: 30},
            {text: 'กำลังแพ็ค', value: 40},
            {text: 'แพ็คเรียบร้อย', value: 50},
            {text: 'เตรียมจัดส่ง', value: 60},
            {text: 'จัดส่งให้ขนส่งแล้ว', value: 70},
            {text: 'ยกเลิก', value: 90},
          ],

          show_change_items_status: [],

          mhao_change_items_status: [
            {text: 'เปิดออร์เดอร์', value: 10},
            {text: 'รอตรวจสอบยอด', value: 20},
            {text: 'รอแพ็คสินค้า', value: 30},
            {text: 'ยกเลิก', value: 90},
          ],

          order_status_history_list: [],

          change_items_status_select: null,

          dialog_photo: false,
          dialog_photo_link: null,


          // แก้ไขข้อมูล remark
          edit_detail_remark: null,
          can_edit_remark: false,
          can_edit_product: false,
          can_edit_packaged: false,

          // แก้ไขที่อยู่
          can_edit_shipaddress: false,
          // postcode_mapping: PostcodeMapping,
          postcode_mapping: [],
          ShipAddressManage: {
            "ID":null,
            "Name": null,
            "Phone": null,
            "TextAddress": null,
            "Address": null,
            "PostcodeMappingID":null,
            "ShopCustomerID": null
          },

          // แก้ไข cod ธนาคาร
          can_edit_data_cod: false,
          edit_cod_amount: null,
          edit_cod_bank: null,
          cod_bank_data: [],
          shop_bank_account:[],
          shop_bank_account_to_show:[],
          cod_list_select: null,
          cod_list :[{"ID":0, "text":"ธรรมดา"},{"ID":1, "text":"COD"}],

          // แก้ไข express
          can_edit_data_express : false,
          ExpressUniqueID : null,
          shop_express_list : [],
          paymentTypeIDList:[{id:1,name:'ร่าง'},{id:1,name:'รอชำระ'},{id:2,name:'ตามยอด'},{id:3,name:'จ่ายแล้ว'}],

          currentShopCustomerID:null,
          input_transferStatusID : "2",
          input_transferId : 0,
          input_transferDueDate : '',
          input_transferFileUpload : null,
          input_transferPhotoLink : null,
          input_transferAmount : null,
          menu_transfer_date : null,
          menu_due_date:null,
          select_transferDate : null,
          select_dueDate : null,
          select_transferHour : null,
          select_transferMinute : null,
          transferHourItems: [
              '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
              '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
          ],
          transferMinuteItems: [
              '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
              '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
              '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
              '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
              '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
              '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
          ],

          data_list_payment:[],

          // windowSize
          size: 'lg',
          window: {
            width: 0,
            height: 0,
          },

          // paginations
          page: 1,
          pageTemp: 1,
          pageLength: 1,
          pageTotalVisible: 9,
          limits: 15,
          offsets: 0,

          // popup
          Popupsearch: null,
          searchStockModelPopup: null,
          addOrderPopup: {
            "MaxWidth": "50%",
            "MaxHeight": 90,
            "Dialog": false,
            "headers": [
              { text: 'เลือก',align: 'center',sortable: false,value: 'selectProduct', width: '50px'},
              // { text: 'No',align: 'center',sortable: false,value: 'No', width: '60px'},
              { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '60px'},
              { text: 'ชื่อ',align: 'left',sortable: false,value: 'name'},
              // { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1'},
              // { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', width: '90px'},
              { text: 'SKU',align: 'center',sortable: false,value: 'sku'},
              // { text: 'Barcode',align: 'center',sortable: false,value: 'barcode'},
              { text: 'ราคา',align: 'right',sortable: false,value: 'unitPrice', width: '90px'},
              { text: 'คงเหลือ',align: 'right',sortable: false,value: 'quantityAvailable', width: '90px'},
            ],
            "Select_Product": [],
            "Data": [],
            "ProductID_List": [],
            "loading": false,
          },

          // แจ้งสถานะ เหมาๆ
          mhaoDialog: false,
          row_id : null,
          mh_line_item_id : null,
          mh_selectStatus : null,
          mh_itemsStatus :[
            {text: 'กรุณาเลือกสถานะ', value: null},
            {text: 'สั่งร้านจีนเรียบร้อย', value: 2},
            {text: 'ร้านค้าจีนส่งออก', value: 3},
            {text: 'สินค้าเข้าโกดังจีน', value: 4},
          ],

          data_master_pic : [],
          data_master_media : [],
          media_type: null,

          // เปิด เลือกวันที่
          startDate: null,
          menuStartDate: false,
          checkStart: true,

          time: null,
          menu2: false,
          modal2: false,

          // endDate: null,
          // menuEndDate: false,
          // checkEnd: true,
          // ปิด เลือกวันที่
          header_edit_item_show: [],
          header_edit_item:[
              { text: '#', value: 'Index', align: 'center', sortable: false, width: 10 },
              { text: 'รูป', value: 'photoLink', align: 'center', sortable: false, width: 10 },
              { text: 'ชื่อสินค้า', value: 'productName', align: 'left', sortable: false },
              { text: 'ราคาขาย', value: 'unitPrice', align: 'center', sortable: false},
              { text: 'จำนวน', value: 'quantity', align: 'center', sortable: false },
              { text: 'ส่วนลด%', value: 'discountPercent', align: 'center', sortable: false },
              { text: 'ส่วนลด', value: 'discount', align: 'center', sortable: false },
              { text: 'ราคารวม', value: 'itemPrice', align: 'right', sortable: false },
              { text: '', value: 'Delete', align: 'center', sortable: false, width: 10 }
          ],
          header_edit_item_packag_show:[
               { text: 'ภาพ', value: 'photoLink', align: 'center', sortable: false , width: '100px' ,},
              { text: 'สินค้า', value: 'productName', align: 'left', sortable: false ,  },
              { text: 'บาร์โค้ด', value: 'barcode', align: 'left', sortable: false, width: '140px'},
              { text: 'SKU', value: 'sku', align: 'left', sortable: false , width: '140px' },
              { text: 'จำนวน', value: 'quantity', align: 'center', sortable: false , width: '100px'},
              { text: '', value: 'Delete', align: 'center', sortable: false, width: '50px' }
          ],

          ProductPrice: 0,

          // edit channel list
          editSaleman: false,
          channelList: [],
          channel: {},
          staffShopList: [],
          staffShop: {},

          IsHaveProductSet: false,

          // เรียกข้อมูล order
          order: null,

          statusExpress: null,

          //แยกออเดอร์
          splitPackageDataSet: {
          headers: [
            {
              text: "รูป",
              align: "center",
              sortable: false,
              value: "photoLink",
              width: "50px",
            },
            {
              text: "ชื่อ",
              align: "center",
              sortable: false,
              value: "productName",
              width: "50px",
            },
            {
              text: "SKU",
              align: "center",
              sortable: false,
              value: "sku",
              width: "50px",
            },
            {
              text: "Barcode",
              align: "center",
              sortable: false,
              value: "barcode",
              width: "50px",
            },
            {
              text: "จำนวนที่มี",
              align: "center",
              sortable: false,
              value: "quantity",
              width: "50px",
            },
            {
              text: "จำนวนที่ต้องการแยก",
              align: "center",
              sortable: false,
              value: "moveQuantity",
              width: "50px",
            },
          ],
          dataSet: [],
          selected: [],
          radio:'0',
          moveTo:null
        },
        splitPackagePopup: {
          dialog: false,
          MaxWidth: "60%",
          MaxHeight: 250,
        },
        addPackagePopup: {
            "MaxWidth": "50%",
            "MaxHeight": 400,
            "Dialog": false,
            "headers": [
              // { text: 'เลือก',align: 'center',sortable: false,value: 'selectProduct', width: '50px'},
              // { text: 'No',align: 'center',sortable: false,value: 'No', width: '60px'},
              { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '60px'},
              { text: 'ชื่อ',align: 'left',sortable: false,value: 'name'},
              { text: 'คงเหลือ',align: 'right',sortable: false,value: 'quantityAvailable', width: '90px'},
              { text: 'จำนวน',align: 'right',sortable: false,value: 'packedQuantity', width: '90px'},
            ],
            "Select_Product": [],
            "Data": [],
            "ProductID_List": [],
            "loading": false,
            
            // paginations
            page: 1,
            pageTemp: 1,
            pageLength: 1,
            pageTotalVisible: 9,
            limits: 15,
            offsets: 0,
            ResultCount:0,
            Popupsearch: null,
            SelectPackage:[],

          },
          packageNo:null,
      }),

      computed: {

          ////////////////////////////////////////////////////////////////

          computed_transfer_date() {
              if(this.select_transferDate == null) {
                return null
              }else {
                return this.formatDate(this.select_transferDate)
              }
          },
          computed_due_date() {
              if(this.select_dueDate == null) {
                return null
              }else {
                return this.formatDate(this.select_dueDate)
              }
          },
      },

      watch : {
        cod_list_select: function (val) {
          if(val == 0){
             this.edit_cod_bank = this.detail_ShopBankAccountID
             this.input_transferFileUpload = null
             this.input_transferAmount = null
             this.select_transferDate = null
             this.select_transferHour = null
             this.select_transferMinute = null
             this.data_list_payment = []
          }
        },
        startDate: async function(newVal) {
          if(newVal){
            this.$refs.menuStartDate.save(this.startDate)
          }
        },

        Warehouse_List_selected_text: function (val) {
          if(!this.isPackhaiWarehouse){
            this.show_change_items_status = this.change_items_status
          } else {
            this.show_change_items_status = this.mhao_change_items_status
          }
        },
      },

      destroyed() {
        // windowSize
        window.removeEventListener('resize', this.handleResize)
      },

      async created() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.codeFacebook = urlParams.get('code')

        ///เก็บภาษามาไว้ใน use_language
        this.use_language = await this.get_languages(localStorage.getItem("set_language"));

        //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
        this.get_authorize_staffshop();



        this.shop_id = localStorage.getItem("shop_id")
        //แบบเก่าแก้ไขไปบางส่วนที่ใช้แล้ว ชื่อ authorize
        this.canChangeStatusOrder = JSON.parse(localStorage.getItem('permission'))['ChangeStatusOrder']
      
        this.CanApprove = JSON.parse(localStorage.getItem("permission"))['ApproveOrder']

        this.header_edit_item_show = this.header_edit_item
        await this.get_postcode_mapping()
        // windowSize
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
        if (this.window.width<960){this.addOrderPopup.MaxWidth='100%'}
        else{this.addOrderPopup.MaxWidth='70%'}
        if (this.size=='xs' ){
          this.limits = 5
          this.addOrderPopup.MaxHeight = (90*this.window.height)/100
          this.window.widthname = this.window.width-35-90-70-30
        }else{
          this.addOrderPopup.MaxHeight = (this.addOrderPopup.MaxHeight*this.window.height)/100
          // cal limits
          this.limits = parseInt( (this.addOrderPopup.MaxHeight-253)/80)
        }
        this.detail_ID = this.$route.query.id
        if (typeof(this.detail_ID) ==='undefined') {
          this.order_detail_token = this.$route.query.token
          this.detail_ID  = await this.get_orderid_by_token(this.order_detail_token)
        }
     
        if(this.detail_ID != null){
          await this.ShowDetailOrder_tmp(this.detail_ID)
          await this.get_order_master_pic_for_pack(this.detail_ID)
          document.title ="ออเดอร์ : "+ this.format_order_id(this.detail_ID)

        }else {
          window.close()
        }

        // get staff channel list
        this.getStaffShop()
        this.getChannelList()
        this.getFileAttach()
        // get shop express
        if (this.detail_statusId <= 30) {
          this.get_shop_express_data(this.shop_id)
        } 

        this.show_reject = false
        this.page_loading = false
      },

      methods: {
        get_languages,

        async getStaffShop(){
          try {
            let response = await axios.post(shopService_dotnet+'Shop/get-shop-staff', {
              "shopID": localStorage.getItem('shop_id'),
            }, {headers: this.header_token})
            if (response.status==200) {
              this.staffShopList = response.data
              this.staffShop = this.staffShopList.filter(x => x.id == this.detail_order.salemanID)[0]
            }
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "get-shop-staff เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }

        },
        async getFileAttach(){
          try {
            let response = await axios.post( orderService_dotnet + 'Order/get-order-file-attach',
              { "orderID": this.detail_ID },
              { headers: this.header_token }
            )
            if (response.status == 200) {
              this.fileAttach = response.data.data
            }
          } catch (e) {
            this.$swal({
              position: "top",
              type: "info",
              title: "get-shop-staff เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
              text: e.message,
            })
          }

        },

        async submit_docFile() {
          if(this.input_doc_select == null){
            this.$refs.input_doc_select.focus()
            return
          }
          if(this.input_docFileUpload == null){
            this.$refs.input_docFileUpload.focus()
            return
          }

          var typeID = null
          if (this.input_doc_select == 'ใบปะหน้า') {
            typeID = 1
          } else {
            typeID = 2
          }
          var url_file = await this.upload_file_py(this.input_docFileUpload)
          var ext = url_file.substr(url_file.length - 3)

          let response = await axios.post(
            orderService_dotnet + 'Order/add-order-file-attach',
            {
              "id": 0,
              "orderMasterID": parseInt(this.detail_ID),
              "createdDatetime": new Date().toISOString(),
              "documentType": this.input_doc_select,
              "documentTypeID": typeID,
              "extension": ext, //jpg png pdf
              "fileUrl": url_file

            },
            { headers: this.header_token }
          )
          if (response.data.status == 'success') {
            this.fileAttach = response.data.data
            this.dialog_doc = false
            this.input_docFileUpload = null
            this.input_doc_select = null
          } else {
            this.$swal({
              position: "top",
              type: "error",
              text: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
            })
          }
        },

        async upload_file_py (file_data) {
          var from_data = new FormData()
          from_data.append('bucket', 'Doc_file')
          from_data.append('file', file_data)
          // อัพโหลดภาพ
          let UploadFileResponse = await axios.post(
            generalService_dotnet+'General/upload-file-with-bucket',
            from_data,
            {
              headers: { "Content-Type": "multipart/form-data","Authorization" : "Bearer " + token_api }
            }
          )

          if (UploadFileResponse.status == 200 && UploadFileResponse.data.status=="success" ) {
              return UploadFileResponse.data.file_path
          } else {
            this.$swal({
              position: "top",
              type: "error",
              text: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
            })
          }
        },

        async DeleteFileAttach(id){
          Swal.fire({
            type: "warning",
            title: "ต้องการลบ ใช่หรือไม่",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              this.loading=true;
              try {
                let response = await axios.get(orderService_dotnet+'Order/delete-order-file-attach?ID='+id,   {headers: this.header_token})
                this.loading=false;
                if (response.status==200 && response.data.status=="success") {
                  this.fileAttach = response.data.data 
                } else {
                  Swal.fire({
                    type: "error",
                    title: response.data.error_description
                  });
                }
              } catch (e) {
                this.loading=false;
                Swal.fire({
                  type: "error",
                  title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                })
              }
            }
          });
        },

        async getChannelList() {
          try {
            let response = await axios.post(shopService_dotnet+'Shop/get-shop-channel', {
              "shopID": localStorage.getItem('shop_id'),
            }, {headers: this.header_token})
            if (response.status==200) {
              this.channelList = response.data
              this.channel = this.channelList.filter(x => x.id == this.detail_order.referenceSourceID)[0]
            }
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "get-shop-channel เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }

        },

        async SummitSaleman(){
          try {
            this.loading = true
            let response = await axios.post(orderService_dotnet+'Order/update-sale-channel', {
              "Id": this.detail_order.id,
              "salemanID": this.staffShop.id,
              "referenceSourceID": this.channel.id,
              "referenceNumber": this.detail_order.referenceNumber == '' ? null : this.detail_order.referenceNumber,
            }, {headers: this.header_token})
            this.loading = false
            if (response.status==200) {
              Swal.fire({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: 'top',
                type: 'success',
                title: 'Success'
              })
              this.detail_order.salemanID = this.staffShop.id
              this.detail_order.salemanName = this.staffShop.name
              this.detail_order.referenceSourceID = this.channel.id
              this.detail_order.channelName = this.channel.channelName
              this.detail_order.channelLogo = this.channel.logoPic
              this.editSaleman = false
            } else {
              this.loading = false
              Swal.fire({
                position: "top",
                type: "error",
                text: "ไม่สามารถทำรายการได้",
              })
            }
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "update-sale-channel เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }

        },

          formatDatetime,
          format_number,
          format_order_id,
          format_price,
          isNumberWNoDot,
          formatMoney,
          china_order_fornat,
          Trim_value,
          Trim_rules,

          can_edit_cod(){
            if(this.detail_IsCOD == true){
              this.cod_list_select = 1
            } else {
              this.cod_list_select = 0
            }

          },

          allowedDates: val => val <= new Date().toISOString().substr(0, 10),

          formatDate (date) {
            date = [date]
            if (date.length == 0) return null

            if (date.length == 1) {
              const [year, month, day] = date[0].split('-')
              return `${day}-${month}-${year}`
            } else {
              const [year_from, month_from, day_from] = date[0].split('-')
              const [year_to, month_to, day_to] = date[1].split('-')
              if (date[0] < date[1]) {
                return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
              } else {
                return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
              }
            }
          },

          // windowSize
          handleResize() {
            this.window.width = window.innerWidth
            this.window.height = window.innerHeight
            if (this.window.width < 600) {
              this.size = 'xs'
            } else if (this.window.width >= 600 && this.window.width < 960) {
              this.size = 'sm'
            } else if (this.window.width >= 960 && this.window.width < 1264) {
              this.size = 'md'
            } else if (this.window.width >= 1264 && this.window.width < 1904) {
              this.size = 'lg'
            } else if (this.window.width >= 1904) {
              this.size = 'xl'
            }
          },
          OpenPhoto(Photo){
            this.ShowPhotoLink = Photo
            this.ShowPhoto = true
          },
          Opentap(PhotoLink){
            window.open(PhotoLink);
          },

          async get_shop_express_data (shop_id) {

            try {
                            
              let response1 = await   axios.post(shopService_dotnet + 'Shop/get-shop-express-addorder',{'shopID': this.shop_id},{ headers: this.header_token})
              
              this.shop_express_list = response1.data;

              const myArray = this.ExpressUniqueID.split("_");
              var expressCompanyID = parseInt(myArray[2])
              var shopExpressID = parseInt(myArray[1])
              var branchExpressID = parseInt(myArray[0])
              if( branchExpressID==0){
                 branchExpressID=null
              }
              if( shopExpressID==0){
                 shopExpressID=null
              }
              this.ExpressUniqueID =null;
              if(branchExpressID==null && shopExpressID==null){
                for (let index = 0; index < this.shop_express_list.length; index++) {
                   if(expressCompanyID == this.shop_express_list[index].expressCompanyID){
                    this.ExpressUniqueID =  this.shop_express_list[index].uniqueID;
                    index = this.shop_express_list.length;
                   }
                }
              }
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "detail-order-get-express-for-edit เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }

          },


          async update_shop_express_id () {
            try {

              if(this.ExpressUniqueID=="" || this.ExpressUniqueID==null){
                this.$swal({ 
                  type: "warning",
                  title: "กรุณาเลือกขนส่ง!" 
                })
                return;
              }
             
              const myArray = this.ExpressUniqueID.split("_");
              var expressCompanyID = parseInt(myArray[2])
              var shopExpressID = parseInt(myArray[1])
              var branchExpressID = parseInt(myArray[0])
              if( branchExpressID==0){
                 branchExpressID=null
              }
              if( shopExpressID==0){
                 shopExpressID=null
              }
              if(branchExpressID==null||shopExpressID==null){
                 this.$swal({ 
                  type: "warning",
                  title: "กรุณาเลือกขนส่ง!" 
                })
                return;
              }
              this.loading = true
              let response = await axios.post(orderService_dotnet+'Order/edit-order-express-company',
                { "orderMasterID": this.detail_ID, 
                  "expressCompanyID":expressCompanyID,
                  "branchExpressID":branchExpressID,
                  "shopExpressID":shopExpressID,
                } , 
                { headers: this.header_token}
              )
              if(response.data.status=="success"){
                  this.can_edit_data_express = false
                  this.ShowDetailOrder_tmp(this.detail_ID)
              }else{
                this.loading = false
                this.$swal({ 
                  type: "warning",
                  title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: response.data.error_description,
                })
              }
              this.loading = false
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "warning",
                  title: "detail-order-get-express-for-edit เกิดข้อผิดพลาดกรูณาติดต่อ IT !!"
              })
            }

          },

          remove_data_product (data_product, StockID) {
            // ลบข้อมูลใน this.detailOrderProduct_tmp
            for(var n=0; n < this.detailOrderProduct_tmp.length ; n++){
              if(StockID == this.detailOrderProduct_tmp[n]['stockShopId'] ){
                this.detailOrderProduct_tmp.splice(n, 1)
                break;
              }

              if(this.addOrderPopup.ProductID_List.length > 0){
                for (var i = 0; i < this.addOrderPopup.ProductID_List.length; i++) {
                  this.addOrderPopup.ProductID_List
                  if(this.addOrderPopup.ProductID_List[i] == StockID ){
                      this.addOrderPopup.ProductID_List.splice(i, 1)
                  }
                }
              }



            }

            // ลบข้อมูลใน this.addOrderPopup.ProductID_List
            // this.addOrderPopup.ProductID_List.splice(this.addOrderPopup.ProductID_List.indexOf(StockID), 1)

            for (var f =0; f < this.detailOrderProduct_tmp.length; f++){
              if(this.detailOrderProduct_tmp[f]['stockShopId'] == data_product['stockShopId']){
                this.detailOrderProduct_tmp.splice(f, 1)
                break;
              }
            }

            this.calculateTotalStockPrice()
          },

          async update_data_product () {
            try {
              this.loading = true
              let self = this
              self.orderItem = []

              var string_alert = ""
              var orderItem_dict = {}
              for (var i = 0; i < self.detailOrderProduct_tmp.length; i++) {
              
                orderItem_dict = {
                    "stockShopId": self.detailOrderProduct_tmp[i].stockShopId,
                    "quantity": self.detailOrderProduct_tmp[i].quantity,
                    "unitPrice": self.detailOrderProduct_tmp[i].unitPrice,
                    "discountPercent": self.detailOrderProduct_tmp[i].discountPercent,
                    "discount": self.detailOrderProduct_tmp[i].discount,
                    "isLock": true,
                    "isProductSet":self.detailOrderProduct_tmp[i].isProductSet,
                    "productMasterID": self.detailOrderProduct_tmp[i].productMasterId,
                    "productMasterItemID": self.detailOrderProduct_tmp[i].productMasterItemId
                }
                if(this.IsHasItemInvoice){
                  orderItem_dict.id =self.detailOrderProduct_tmp[i].id;
                }else{
                  orderItem_dict.id =self.detailOrderProduct_tmp[i].orderItemId;
                }
                self.orderItem.push(orderItem_dict)
                
              }
              
              var isCod=false;
              var codAmount=null;
              if(this.IsCanEditCOD){
                isCod = this.detail_IsCOD;
                if(isCod){
                  codAmount=this.edit_cod_amount; 
                 
                }else{
                  codAmount=null 
                }

                if(codAmount==null || codAmount==0){
                  isCod=false;
                }

              }else{
                isCod = this.detail_IsCOD
                codAmount=this.detail_CODAmount;
              }
              var isConfirm=true;
              if(this.detail_CODAmount != codAmount)
              {
                await Swal.fire({ 
                  type: "question", 
                  title:"ยอดเงิน COD มีการเปลี่ยนแปลง",
                  text: "คุณต้องการเปลี่ยนยอด COD จาก "+this.detail_CODAmount+" เป็น "+codAmount +" กรุณายืนยัน",
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: "ยกเลิก",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  allowOutsideClick: false,
                }).then(async (result) => {
                  if (!result.value) {
                    isConfirm=false;
                  }});
              } 
              if(isConfirm)
              { 

                var orderMasterParam_tmp = {
                    "orderMasterID": this.detail_ID, 
                    "isCod":isCod,
                    "codAmount":codAmount,
                    "vatPercent": this.vat_percent,
                    "deliveryCost": this.textField_deliveryCost,
                    "orderDiscountPercent": (this.select_discountType == '฿' ? null :  this.textField_discount ),
                    "orderDiscount": (this.select_discountType == '%' ? this.totalDiscount :  this.textField_discount ),
                    "vatType": this.select_vatType,
                    "vatAmount": this.totalVat,
                    "totalPrice": this.totalPrice
                } 
           
                
                let response = await axios.post(orderService_dotnet+'Order/detail-order-edit-items',{
                  "orderItemInvoice" : self.orderItem,
                  "orderMasterParam": orderMasterParam_tmp,
                  "staffShopID": this.StaffShopID,
                  "staffBranchID": this.StaffBranchID
                } , { headers: this.header_token})
                self.loading = false
                if(response.data.orderItemProductResponse != null && response.data.orderItemProductResponse.length >0)
                {
                
                   
                  // ขึ้นเตือนสินค้าสต๊อกไม่พอ
                  self.dialog_not_enough_stock = true
                  self.orderItemProductResponse = []
                  self.dialog_massage = response.data.error_description
                  self.orderItemProductResponse = response.data.orderItemProductResponse
                }else if (response.data.status=="error"){
                  this.$swal({
                      position: "top",
                      type: "info",
                      title: "detail-order-edit-items เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                      text: response.data.status,
                  })
                }else{
                  Swal.fire({
                    toast: true,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'top',
                    type: 'success',
                    title: 'แก้ไขสำเร็จ'
                  })
                  this.ShowDetailOrder_tmp(this.detail_ID)
                  this.can_edit_product=false;
                }                  
              }
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "detail-order-edit-items เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }

          },

          discountPercent(item) {
              if (item.discountPercent != "") {
                  var discount = (Number(item.unitPrice)*Number(item.quantity)*Number(item.discountPercent))/100
                  item.discount = discount.toFixed(2)
              } else {
                  item.discount = null
              }
          },

          async unitPriceInput(item) {

              if (item.unitPrice == "") {
                  await this.$swal({
                      position: "top",
                      type: "info",
                      text: "กรุณาใส่ราคาสินค้า",
                  })
                  item.VueComponentFocus.unitPrice()
              } else {
                  item.unitPrice = Number(item.unitPrice).toFixed(2)
                  this.calculateDiscountPercent(item)
                  this.calculateTotalStockPrice()
              }
          },

          async quantityInput(item) {
              var quantity = item.quantity
              if (quantity == "" || quantity == null) {
                  await this.$swal({
                      position: "top",
                      type: "info",
                      text: "กรุณาใส่จำนวนสินค้า",
                  })
                  item.VueComponentFocus.quantityInput()
              } else {
                  this.calculateDiscountPercent(item)
                  this.calculateTotalStockPrice()
              }
          },

          calculateDiscountPercent(item) {
              var discountPercent = Number(item.discountPercent)
              if (!Number.isNaN(discountPercent) && discountPercent != 0) {
                  var price = Number(item.unitPrice)
                  var quantity = Number(item.quantity)
                  item.discount = Number((price * quantity * discountPercent) / 100).toFixed(2)
              } else {
                  item.discountPercent = null
                  item.discount = null
              }
          },

          calculateTotalStockPrice() {
              this.totalPrice = 0
              var price = 0
              var quantity = 0
              var discountPercent = 0
              var discount = 0
              for (var i=0; i<this.detailOrderProduct_tmp.length; i++) {
                  price = Number(this.detailOrderProduct_tmp[i].unitPrice)
                  quantity = Number(this.detailOrderProduct_tmp[i].quantity)
                  if (quantity > 0 && price >= 0) {
                      discount = (Number.isNaN(Number(this.detailOrderProduct_tmp[i].discount)) ? 0 : Number(this.detailOrderProduct_tmp[i].discount))
                      // this.productList[i]['itemPrice'] = price * quantity - discount
                      this.detailOrderProduct_tmp[i]['itemPrice'] = price * quantity - discount
                      this.totalPrice += price * quantity - discount
                  }
              }
              this.ProductPrice =  this.totalPrice
              this.calculateDiscountOrder()
              this.calculateVAT()

          },

          calculateVAT() {
              this.totalVat = 0
              // Set COD Amount
              this.vat_percent = 0
              this.textfield_codAmount = (this.totalPrice + Number(this.textField_deliveryCost) - this.totalDiscount).toFixed(2)
              if (this.select_vatType == 2) {
                  this.vat_percent = 7
                  this.totalVat = ((this.totalPrice + Number(this.textField_deliveryCost) - this.totalDiscount) * this.vat_percent) / 107
              }
              else if (this.select_vatType == 3) {
                  this.vat_percent = 7
                  this.totalVat = ((this.totalPrice + Number(this.textField_deliveryCost) - this.totalDiscount) * this.vat_percent) / 100
                  // Set COD Amount
                  this.textfield_codAmount = (Number(this.textfield_codAmount) + this.totalVat).toFixed(2)
              }

              // สำหรับ cod Amount
              this.edit_cod_amount = (this.totalPrice + Number(this.textField_deliveryCost) - this.totalDiscount) + (this.select_vatType == 3 ? this.totalVat : 0)
          },

          calculateDiscountOrder() {
              if (this.select_discountType == "฿") {
                  this.totalDiscount = Number(this.textField_discount)
              } else {
                  this.totalDiscount = ((this.totalPrice + Number(this.textField_deliveryCost)) * Number(this.textField_discount)) / 100
              }
              this.calculateVAT()
          },


          black_to_order_page () {
            window.close()
          },

          set_format_date_time (val) {
            if(val != null){
              var date_tmp = val.substring(0, 10)
              const date_split = date_tmp.split('-');
              var time_tmp = val.substring(11, 19)
              var set_dattime = date_split[2]+'-'+date_split[1]+'-'+date_split[0] +' '+ time_tmp
              return set_dattime
            } else {
              return ""
            }
          },

          bank_id_to_text (id){
            for (var e =0; e < this.cod_bank_data.length; e++){
              if(this.cod_bank_data[e]['ID'] == id){
                return this.cod_bank_data[e]['text']
              }
            }
          },


          async get_orderid_by_token (token) {

            try {
              let response = await axios.post(orderService_dotnet+'Order/get-order-master',{"token": token} , { headers: this.header_token})
              this.order_token = response.data.id
              return this.order_token
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "get-order-master เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }

          },

          approvePaymentAlert(payment_id){
              this.$swal({
                position: "top", type: 'warning', title: 'ต้องการอนุมัติยอดโอน นี้ใช่หรือไม่ !!', confirmButtonText: 'ยืนยัน', cancelButtonText: 'ยกเลิก', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', allowOutsideClick: false
              }).then(async(result) => { if (result.value) {  this.ApprovePayment(payment_id)} })
          },

          async ApprovePayment (payment_id) {
            try {
              this.loading = true
              let response = await axios.post(orderService_dotnet+'Order/detail-order-approv-order-payment',{
                "paymentID": payment_id,
                "orderMasterID": this.detail_ID,
                "staffShopID": this.StaffShopID
              } , { headers: this.header_token})

                if(response.data.status){

                  this.ShowDetailOrder_tmp(this.detail_ID)
                }
              this.loading = false
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "detail-order-approv-order-payment เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }
          },

          rejectPaymentAlert(payment_id){
              this.$swal({
                position: "top", type: 'warning', title: 'ต้องการปฎิเสธยอดโอน นี้ใช่หรือไม่ !!', confirmButtonText: 'ยืนยัน', cancelButtonText: 'ยกเลิก', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', allowOutsideClick: false
              }).then(async(result) => { if (result.value) {  this.RejectPayment(payment_id)} })
          },

          async RejectPayment (payment_id) {
            try {
              this.loading = true
              let response = await axios.post(orderService_dotnet+'Order/detail-order-reject-order-payment',{
                "paymentID": payment_id,
                "orderMasterID": this.detail_ID,
                "staffShopID": this.StaffShopID
              } , { headers: this.header_token})

                if(response.data.status){

                  this.ShowDetailOrder_tmp(this.detail_ID)
                }
              this.loading = false
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "detail-order-reject-order-payment เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }

          },

          async backStatusOrderToOpen () {
            try {
              this.loading = true
              let response = await axios.post(orderService_dotnet+'Order/detail-order-back-status-order-to-open',{
                "orderMasterID": this.detail_ID,
                "staffShopID": this.StaffShopID
              } , { headers: this.header_token})

              if(response.data.status){

                this.ShowDetailOrder_tmp(this.detail_ID)
              }
              this.loading = false
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "detail-order-back-status-order-to-open เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }
          },


          async get_shopAddress_loadpage (val) {
            try {
              let res1 = await axios.post(shopService_dotnet+'ShopAddress/get-shop-address', {"id": val },
              { headers: this.header_token})
              this.detail_ShopPhone = res1.data.phone
              this.detail_ShopAddress = res1.data.address +' '+ res1.data.subDistrict + ' ' + res1.data.district + ' ' + res1.data.province + ' ' + res1.data.postcode

              if(this.detail_TempSenderAddress == null){
                this.detail_TempSenderAddress = res1.data.name +' '+  res1.data.phone +' '+ this.detail_ShopAddress
              }
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "get-shop-address เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }

          },

          async get_shippingAddress_loadpage (val) {

            try {
              // getshippingAddress
              let res2 = await axios.post(shopService_dotnet+'Shop/get-shipping-address-list', {"id": val, "isAscending": true },
              { headers: this.header_token})
              this.detail_line = res2.data[0].customerLine
              this.detail_fackbook = res2.data[0].customerFacebook
              this.detail_email = res2.data[0].customerEmail

              this.detail_edit_line = res2.data[0].customerLine
              this.detail_edit_fackbook = res2.data[0].customerFacebook
              this.detail_edit_email = res2.data[0].customerEmail

              this.detail_ShippingAddress = res2.data[0].address +' '+ res2.data[0].subDistrict + ' ' + res2.data[0].district + ' ' + res2.data[0].province + ' ' + res2.data[0].postcode

              if(this.detail_TempAddress == null){
                this.detail_TempAddress = res2.data[0].name +' '+  res2.data[0].phone +' '+ this.detail_ShippingAddress
              }
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "get-shipping-address-list เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }

          },

          async get_order_shop_bank_by_id (val) {
            try {
              // getshippingAddress
              let res = await axios.post(orderService_dotnet+'Order/get-order-shop-bank-by_id', {"shopBankID": val},
              { headers: this.header_token})
              this.detail_BankName = res.data[0].bankNameTH
              this.detail_AccountNumber = res.data[0].accountNumber
              this.detail_AccountName = res.data[0].accountName
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "get-order-shop-bank-by_id เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }

          },
          async GetOrderPayment(OrderID){
            var response = await axios.post(orderService_dotnet + 'Order/get-order-payment',{'orderID': OrderID},{ headers: this.header_token});
            this.detailOrderPayment = response.data.items;
            this.detailOrderPayment_temp = JSON.parse(JSON.stringify(response.data.items));

            if(this.detailOrderPayment.length>1){
              var sumAmount = this.detailOrderPayment.reduce((partialSum, a) => partialSum + a.amount, 0);
              this.detailOrderPayment.push({amount:sumAmount,id:0 });
            }
          
          },

          async ShowDetailOrder_tmp (OrderID) {

            await axios.all([
              axios.post(shopService_dotnet + 'Shop/get-warehouse-list',{'shopID':this.shop_id},{ headers: this.header_token}),
              axios.post(orderService_dotnet + 'Package/get-order-package-info',{'orderID': OrderID},{ headers: this.header_token}), 
              axios.post(orderService_dotnet + 'Order/get-order-payment',{'orderID': OrderID},{ headers: this.header_token}),
              axios.post(orderService_dotnet + 'Order/get-order-master',{'orderID': OrderID},{ headers: this.header_token}), 
              axios.post(orderService_dotnet + 'Order/get-order-satus-history',{'orderID': OrderID},{ headers: this.header_token}), // order_status_history_list
              axios.get(logistic_Service + 'Logistic/get-track-status?OrderMasterID='+OrderID, { headers: this.header_token}),
            ]).then(axios.spread((res_WarehouseList, res_PackageList,   res_OrderPaymentList, res_OrderMasterDict, res_StatusHistory,res_TrackStatus) => {
              var detail_order = res_OrderMasterDict.data
              this.detail_order = res_OrderMasterDict.data
              this.orderItemList = res_PackageList.data.orderItemList;
              this.orderItemTempList = res_PackageList.data.orderItemTempList;
              this.orderItemInvoiceList = res_PackageList.data.orderItemInvoiceList;

              if(this.orderItemInvoiceList==null||this.orderItemInvoiceList.length==0){
                this.orderItemInvoice_tmp = this.orderItemList;
              }else{
                this.orderItemInvoice_tmp=this.orderItemInvoiceList
              }
              this.packageList = res_PackageList.data.packageList;

              this.packageList.forEach(element => {
                element.itemList = this.orderItemList.filter(packageno=>packageno.packageNo ==element.packageNo );
                var trackStatusTemp = res_TrackStatus.data.packages.filter(packageno=>packageno.packageNo ==element.packageNo );
                if(trackStatusTemp.length>0){
                  element.trackStatus =trackStatusTemp[0];
                }
                
              }); 
              this.packageList.sort((a, b) => a.packageNo - b.packageNo); 
              
              let stockShopIdList = this.orderItemList.map(a => a.stockShopId);
           
              const duplicates = stockShopIdList.filter((item, index) => stockShopIdList.indexOf(item) !== index)
              if(duplicates.length==0 && this.packageList.length==1
                && this.detail_order.shippingStatusID<=40
                && this.packageList[0].packageStatusID<80
              ){
                this.IsAbleToEditOrderItem=true;
              }else{
                this.IsAbleToEditOrderItem=false;
              }
              if(res_OrderMasterDict.data.shopID != this.shop_id){
                window.close();
              }
              this.orderItemList.forEach(item => {
                if(item.serialNumber !=null){
                  this.IsHasSerial=true;
                }
              });
              // getshopAddress
              if(detail_order.shopAddressID){
                this.get_shopAddress_loadpage(detail_order.shopAddressID)
              }
              // getshippingAddress
              if(detail_order.shippingAddressID){
                this.get_shippingAddress_loadpage(detail_order.shippingAddressID)
              }


              if(detail_order.shopBankAccountID != null){
                this.get_order_shop_bank_by_id(detail_order.shopBankAccountID)
              } else {
                this.detail_BankName = null
                this.detail_AccountNumber = null
                this.detail_AccountName = null
              }


              this.change_items_status_select = detail_order['shippingStatusID']
              this.detail_ID = detail_order['id']
              this.detail_statusId = detail_order['shippingStatusID']
              this.detail_shiping_staus_update_select = detail_order['shippingStatusID']

              this.detail_statusName = detail_order['shippingStatusName']
              this.detail_ShopName = detail_order['senderName']
              this.detail_ShipName = detail_order['receiverName']

              this.detail_parcelStatusId = detail_order['parcelStatusID']
              this.detail_parcelStatusName = detail_order['parcelStatusName']
              this.detail_ShipPhone = detail_order['receiverPhone']
              this.detail_Remark = detail_order['remark']
              this.detail_BoxName = detail_order['boxName']
              this.detail_Weight = detail_order['weight']
              this.detail_Width = detail_order['cubic1']
              this.detail_Long = detail_order['cubic2']
              this.detail_Height = detail_order['cubic3']
              this.detail_BoxPrice = detail_order['boxPrice']
              this.detail_BubblePrice = detail_order['bubblePrice']
              this.detail_OtherServicePrice = detail_order['otherServicePrice']
              this.detail_VrichTransactionFee = detail_order['vrichTransactionFee']
              this.detail_CODPercent = detail_order['codPercent']
              this.detail_CODCharged = detail_order['codCharged']
              this.detail_Discount = detail_order['discount']
              this.detail_RemotePrice = detail_order['remotePrice']
              this.detail_DeliveryPrice = detail_order['deliveryPrice']
              this.detail_PackPrice = detail_order['packPrice']
              this.detail_IsPreOrder = detail_order['isPreOrder']
              this.detail_totalPrice = parseFloat(detail_order['totalPrice'] == null ? 0 : detail_order['totalPrice'] )
              this.detail_DeliveryCost = parseFloat(detail_order['deliveryCost'] == null ? 0 : detail_order['deliveryCost'] )
              this.vat_percent = detail_order['vatPercent']
              this.textField_deliveryCost = parseFloat(detail_order['deliveryCost'] == null ? 0 : detail_order['deliveryCost'])
              this.select_vatType = detail_order['vatType']
              if(detail_order['vatType'] == null){
                this.select_vatType = 2
              }

              for (var items1 of res_WarehouseList.data) {
                if(detail_order.warehouseID == items1.id){
                  this.Warehouse_List_selected = items1.id
                  this.Warehouse_List_selected_text = items1.name
                  this.isPackhaiWarehouse = items1.isPackhaiWarehouse
                  break;
                }
              }


              if(detail_order['orderDiscountPercent'] == null){
                this.select_discountType ='฿'
                  this.textField_discount = detail_order['orderDiscount']
              } else {
                this.select_discountType ='%'
                this.textField_discount = detail_order['orderDiscountPercent']
              }

              this.detail_OrderDiscount = parseFloat(detail_order['orderDiscount'] == null ? 0 : detail_order['orderDiscount'])
              this.detail_VATAmount = parseFloat(detail_order['vatAmount'] == null ? 0 : detail_order['vatAmount'] )
              this.detail_VATType = this.select_vatType

              // set data tmp
              this.vat_percent_tmp = JSON.parse(JSON.stringify(this.vat_percent))
              this.textField_deliveryCost_tmp = JSON.parse(JSON.stringify(this.textField_deliveryCost))
              this.select_discountType_tmp = JSON.parse(JSON.stringify(this.select_discountType))
              this.textField_discount_tmp = JSON.parse(JSON.stringify(this.textField_discount))
              this.select_vatType_tmp = JSON.parse(JSON.stringify(this.select_vatType))

              this.detail_TrackingNo = detail_order['trackingNo']
              this.get_token = detail_order['token']
              this.detail_IsCOD = detail_order['isCOD']
              this.detail_CustomerType = detail_order['customerType']
              this.detail_CODAmount =  detail_order['codAmount']
              this.detail_RealTransferDatetime = detail_order['realTransferDatetime']
              this.detail_Token = customerWebsite + 'purchase-order='+this.get_token
              this.detail_ExpressCompanyID = detail_order['expressCompanyID']
              this.detail_SellerVoucher = detail_order['totalSellerDiscount']
              this.detail_orderTotalAmount = detail_order['orderTotalAmount']
              this.detail_SellerDeliveryDiscount = detail_order['sellerDeliveryDiscount']
              this.detail_PlatformVoucher = detail_order['totalPlatformDiscount']
              this.detail_isSellerOwnFleet = detail_order['isSellerOwnFleet']
               
              if(this.detail_isSellerOwnFleet==null){
                this.detail_isSellerOwnFleet=false;
              }


              this.ExpressUniqueID = (detail_order.branchExpressID==null? "0":detail_order.branchExpressID.toString())
                +"_"+(detail_order.shopExpressID==null? "0":detail_order.shopExpressID.toString())
                +"_"+(detail_order.expressCompanyID==null? "0":detail_order.expressCompanyID.toString());
              
            

              this.detail_StaffName = detail_order['createdByStaffShopName']
              this.detail_SalemanName = detail_order['salemanName']

              this.detail_ShopBankAccountID = detail_order['shopBankAccountID']

              // data shopee 
              this.detail_referenceSourceID = detail_order['referenceSourceID']
              this.detail_ReferenceSourceID = detail_order['referenceSourceID']
              this.detail_ReferenceNumber = detail_order['referenceNumber']
              this.detail_RealReferenceNumber = detail_order['realReferenceNumber']
              this.detail_TempSenderAddress = detail_order['tempSenderAddress']
              this.detail_TempAddress = detail_order['tempAddress']
              this.detail_ChannelSourceLogoPic = detail_order['channelLogo']

              if(this.detail_referenceSourceID == 3 || this.detail_referenceSourceID == 4 || this.detail_referenceSourceID == 11 ){
                this.get_status_order_marketplace(this.detail_ID)
              }

              if(this.detail_referenceSourceID == 3 || this.detail_referenceSourceID == 4 || this.detail_referenceSourceID == 11){
                this.AllowChangeOrderStatus=false;
              }
              else 
              {
                if(this.isPackhaiWarehouse)
                {
                  if(this.detail_statusId<=30){
                    this.AllowChangeOrderStatus=true;
                  }else{
                    this.AllowChangeOrderStatus=false;
                  }
                }else
                {
                  this.AllowChangeOrderStatus=true;
                }
              } 
              // shipAddress
              if(detail_order['shippingAddressID'] != null){
                this.detail_ShippingAddressID = detail_order['shippingAddressID']
                this.getDataShippingAddressManageID(detail_order['shippingAddressID'])
              }

              // get bank data
              this.get_bank_cod(detail_order)

              if(this.orderItemList.length != 0 || this.orderItemInvoiceList.length != 0) {

                
                // ถ้ามีสินค้าเป็น set ใช้ข้อมูลจาก order item invoice
                this.IsHaveProductSet = false
                for (var ic = 0; ic < this.orderItemInvoiceList.length; ic++) {
                  if(this.orderItemInvoiceList[ic].isProductSet == true){
                    this.IsHaveProductSet = true
                    break;
                  }
                }

          
                this.detail_sum_unintPrice_and_DeliveryCost = 0
                for(var i=0; i< this.orderItemInvoiceList.length; i++) {
                  var item_quantity = this.orderItemInvoiceList[i]['quantity'] == null ? 0 : this.orderItemInvoiceList[i]['quantity']
                  var item_unitPrice = this.orderItemInvoiceList[i]['unitPrice'] == null ? 0 : this.orderItemInvoiceList[i]['unitPrice']
                  this.detail_sum_unintPrice += parseFloat(item_quantity)*parseFloat(item_unitPrice)
                }
                this.detail_sum_unintPrice_and_DeliveryCost = parseFloat(this.detail_sum_unintPrice)+parseFloat(this.detail_DeliveryCost)

                // add data StockList
                this.get_v_stock_shop_by_id()
              }

              // get tracking status

              // Select Warehouse
              this.Warehouse_List = res_WarehouseList.data
              this.Warehouse = res_WarehouseList.data[0]

              //  orderPayment
              this.detailOrderPayment = res_OrderPaymentList.data.items;
              this.detailOrderPayment_temp = JSON.parse(JSON.stringify(res_OrderPaymentList.data.items));
              if(this.detailOrderPayment.length>1){
                var sumAmount = this.detailOrderPayment.reduce((partialSum, a) => partialSum + a.amount, 0);
                this.detailOrderPayment.push({amount:sumAmount,id:0 });
              }

              // data_pic
              this.data_pic =  res_OrderMasterDict.data.imagePack

              // order status history
              this.order_status_history_list = res_StatusHistory.data
              for (let index = 0; index <   this.order_status_history_list.length; index++) {
                if(this.order_status_history_list[index].staffName != null && this.order_status_history_list[index].staffName!=""){
                  this.order_status_history_list[index].description = this.order_status_history_list[index].description+" "+"("+this.order_status_history_list[index].staffName+")"
                }
                
              }
            }));

            try {
              if(this.detail_TrackingNo!=null && this.detail_TrackingNo!="" && this.detail_ExpressCompanyID!=0){
                this.statusExpress = null
                let statusResposne = await axios.get(logistic_Service + 'Logistic/get-track-status?OrderMasterID='+OrderID, { headers: this.header_token})
                this.statusExpress = statusResposne.data
              }
            } catch (error) {
              //do nothing
              this.statusExpress = null

            }

            if(this.platformReferenceSourceID.includes(this.detail_referenceSourceID)){
              this.IsCanEditCOD=false;
            }

          },

          async get_status_order_marketplace (order_id) {
            try {
              let response = await axios.get(ecomService_dotnet+'General/check-marketplace-order-by-id?OrderMasterID='+ order_id.toString(), { headers: this.header_token})
              this.marketplace_status = response.data.data
            } catch (e) {
              this.marketplace_status = null
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "check-marketplace-order-by-id เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }
          },


          async detail_edit_remark (order_id) {
            try {
              if (this.edit_detail_remark == ''){this.edit_detail_remark = '-'}

              this.loading = true
              let response = await axios.post(orderService_dotnet+'Order/edit-order-remark',{"orderMasterID": this.detail_ID, "remark":this.edit_detail_remark} , { headers: this.header_token})
              if(response.data.status=="success"){
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'แก้ไข หมายเหตุ เรียบร้อย'
                })
                this.can_edit_remark = false
                this.edit_detail_remark = null
                this.ShowDetailOrder_tmp(this.detail_ID)
                this.loading = false
              }else{
                this.loading = false
                this.$swal({ 
                  type: "warning",
                  title: "ไม่สามารถทำรายการได้!",
                  text:response.data.error_description,
                })
              }
              this.loading = false
            } catch (e) {
              this.$swal({ 
                  type: "warning",
                  title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }
          },

          async shop_change_shipping_status () {
            try {
              this.loading = true
              let response = await axios.post(orderService_dotnet+'Order/change-order-status',{
                "orderID": this.detail_ID,
                "shippingStatusID": this.detail_shiping_staus_update_select,
                "staffShopID": this.StaffShopID
              } , { headers: this.header_token})
              if(response.data.status == 'success'){
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'บันทึกข้อมูลเรียบร้อย'
                })
                this.ShowDetailOrder_tmp(this.detail_ID)
                this.loading = false
              } else {
                this.loading = false
              }
            } catch (e) {
              this.$swal({
                  position: "top",
                  type: "info",
                  title: "change-order-status เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                  text: e.message,
              })
            }

          },

          copyTrackingCode () {
            if(this.detail_ExpressCompanyID == 2){
              window.open('https://th.kerryexpress.com/en/track/?track='+this.detail_TrackingNo)
            }else if (this.detail_ExpressCompanyID == 3) {
              window.open('https://track.thailandpost.co.th/')
            }else if (this.detail_ExpressCompanyID == 4) {
              window.open('https://track.thailandpost.co.th/')
            }else if (this.detail_ExpressCompanyID == 5) {
              window.open('https://track.thailandpost.co.th/')
            }else if (this.detail_ExpressCompanyID == 6) {
              window.open('https://www.nimexpress.com/web/p/tracking')
            }else if (this.detail_ExpressCompanyID == 10) {
              window.open('https://www.scgexpress.co.th/tracking')
            }else if (this.detail_ExpressCompanyID == 11) {
              window.open('https://www.jtexpress.co.th/index/query/gzquery.html')
            }else if (this.detail_ExpressCompanyID == 12) {
              window.open('https://www.ninjavan.co/th-th/tracking')
            }else if (this.detail_ExpressCompanyID == 13) {
              window.open('https://www.dhl.com/th-th/home/tracking.html')
            }
         },

         image_click(photo_link, mediaType) {
           this.media_type = mediaType
           if(mediaType == 0){
             this.dialog_photo_link = photo_link
             this.dialog_photo = true
           }
           // else {
           //   this.dialog_photo_link = photo_link.replace("preview", "view")
           // }



         },

         deleteShipping () {
           this.$swal({
             position: "top",
             type: 'warning',
             title: 'ต้องยกเลิก Order นี้ใช่หรือไม่ !!',
             // text: this.myKeyword.textD1[this.language]+' '+ name +' '+ this.myKeyword.textD2[this.language],
             confirmButtonText: 'ยืนยัน',
             cancelButtonText: 'ยกเลิก',
             showCancelButton: true,
             confirmButtonColor: '#3085d6',
             cancelButtonColor: '#d33',
             allowOutsideClick: false
           }).then(async(result) => {
             if (result.value) {

               let response = await axios.post(orderService_dotnet+'Order/cancel-order', {
                 "orderID": this.detail_ID,
                 "staffShopID": this.StaffShopID,
                 "staffBranchID": null
               },
               { headers: this.header_token})
               if(response.data.status){
                 this.$swal({
                   toast: true,
                   timer: 1500,
                   showConfirmButton: false,
                   position: 'top',
                   type: 'success',
                   title: 'ยกเลิกสำเร็จ'
                 })

                 this.ShowDetailOrder_tmp(this.detail_ID)
               } else {
                 this.$swal({
                     position: "top",
                     type: "info",
                     title: "cancel-order เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                     // text: e.message,
                 })
               }

             }
           })
         },

         async getDataShippingAddressManageID (addressID) {

           try {
             this.loading = true

             let res2 = await axios.post(shopService_dotnet+'Shop/get-shipping-address-list', {"id": addressID, "isAscending": true },
             { headers: this.header_token})
             if(res2.data.length > 0){
               var ShipAddressManage = res2.data[0]
               this.ShipAddressManage.ID = ShipAddressManage.id
               this.ShipAddressManage.Name = ShipAddressManage.name
               this.ShipAddressManage.Phone = ShipAddressManage.phone
               this.ShipAddressManage.TextAddress = ShipAddressManage.address
               this.ShipAddressManage.ShopCustomerID = ShipAddressManage.shopCustomerID

               if(ShipAddressManage['postcodeMappingId'] != null){
                 for (var i = 0; i < this.postcode_mapping.length; i++) {
                   if (this.postcode_mapping[i]['Id'] ==  ShipAddressManage['postcodeMappingId']){
                     this.ShipAddressManage.PostcodeMappingID = ShipAddressManage['postcodeMappingId']
                     this.ShipAddressManage.Address = {
                        "Id": this.postcode_mapping[i]['Id'],
                        "SubDistrict": this.postcode_mapping[i]['SubDistrict'],
                        "District": this.postcode_mapping[i]['District'],
                        "Province": this.postcode_mapping[i]['Province'],
                        "Postcode": this.postcode_mapping[i]['Postcode']
                     }
                     break;
                   }
                 }
               } else {
                 this.ShipAddressManage.Address = {
                    "Id": ShipAddressManage['postcodeMappingId'],
                    "SubDistrict": ShipAddressManage['subDistrict'],
                    "District": ShipAddressManage['district'],
                    "Province": ShipAddressManage['province'],
                    "Postcode": ShipAddressManage['postcode']
                 }
               }
             }

             this.loading = false
           } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "get-shipping-address-list เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }

         },

         async EditDataShippingAddressManageID () {
             // var ShipAddressManage_tmp = 0
             // if(this.ShipAddressManage.ID != null){
             //   ShipAddressManage_tmp = this.ShipAddressManage.ID
             // }
             if(this.ShipAddressManage.ID != null){
               try {
                 this.loading = true
                 await axios.all([
                   axios.post(shopService_dotnet + 'Customer/update-customer-address',{
                      "id": this.ShipAddressManage.ID,
                      "shopCustomerID": this.ShipAddressManage.ShopCustomerID,
                      "name": this.ShipAddressManage.Name,
                      "phone": this.ShipAddressManage.Phone,
                      "address": this.ShipAddressManage.TextAddress,
                      "postcodeMappingID": this.ShipAddressManage.PostcodeMappingID,
                      "isDefault": true
                   },{ headers: this.header_token}),
                   axios.post(shopService_dotnet + 'Customer/update-customer-info',{
                      "id": this.ShipAddressManage.ShopCustomerID,
                      "name": this.ShipAddressManage.Name,
                      "phone": this.ShipAddressManage.Phone,
                      "line": this.detail_edit_line,
                      "facebook": this.detail_edit_fackbook,
                      "email": this.detail_edit_email
                   },{ headers: this.header_token})
                 ]).then(axios.spread((res1, res2)  => {
                   this.can_edit_shipaddress = false

                   this.ShowDetailOrder_tmp(this.detail_ID)

                 }));
                 this.loading = false
               } catch (e) {
                 this.$swal({
                     position: "top",
                     type: "info",
                     title: "update-customer-address || update-customer-info  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                     text: e.message,
                 })
               }

           } else {
             try {
               this.loading = true
               let res2 = await axios.post(shopService_dotnet+'Customer/add-customer-address',{
                  "id": 0,
                  "shopID": localStorage.getItem('shop_id'),
                  "orderMasterID": this.detail_ID,
                  "shipName": this.ShipAddressManage.Name,
                  "shipPhone": this.ShipAddressManage.Phone,
                  "shipAddress": this.ShipAddressManage.TextAddress,
                  "shipPostcodeMappingID": this.ShipAddressManage.PostcodeMappingID,
                  "cusName": this.ShipAddressManage.Name,
                  "cusPhone": this.ShipAddressManage.Phone,
                  "cusFacebook": this.detail_edit_fackbook,
                  "cusLine": this.detail_edit_line,
                  "cusEmail": this.detail_edit_email
                },{ headers: this.header_token})
                this.loading = false
                this.can_edit_shipaddress = false
                this.ShowDetailOrder_tmp(this.detail_ID)
             } catch (e) {
               this.$swal({
                   position: "top",
                   type: "info",
                   title: "add-customer-address เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                   text: e.message,
               })
             }
           }

         },

        async  get_bank_cod () {
          try {
            this.loading = true
            let res2 = await axios.post(shopService_dotnet+'Shop/get-shop-bank-list', {"shopID": this.shop_id},
            { headers: this.header_token})
            var bank_list_tmp = []
            this.shop_bank_account =res2.data;
            if(res2.data != null && res2.data.length != 0){
              for (var i = 0; i < res2.data.length; i++) {
                var back_dict = {
                  "ID": res2.data[i]['id'],
                  "text": res2.data[i]['fullBankName']
                }
                bank_list_tmp.push(back_dict)
              }
            }else {
              this.cod_bank_data = []
            }

            this.cod_bank_data = bank_list_tmp
            this.loading = false
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "get-shop-bank-list  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }

         },

 
     

      

      async  order_update_iscod_status (type) {
        if(type == 1){
          var codAmount = null
          var shopBankAccountID = null
          if(this.cod_list_select == 1){
            if(this.edit_cod_amount == null || this.edit_cod_amount == ''){
                 this.$refs.edit_cod_amount.focus()
                 return
             } else if (this.edit_cod_bank == null) {
                 alert('กรุณาเลือกธนาคาร !!')
                 return
             }
             codAmount = this.edit_cod_amount
             shopBankAccountID = this.edit_cod_bank
          }

          try {
            this.loading = true
            let response = await axios.post(orderService_dotnet+'Order/detail-order-edit-status-iscod', {
               "orderMasterID": this.detail_ID,
               "isCOD": this.cod_list_select,
               "codAmount": (codAmount ==""? 0 : codAmount),
               "shopBankAccountID": shopBankAccountID
             },
            { headers: this.header_token})
            if(response.data.status){
              this.can_edit_data_cod = false

              this.ShowDetailOrder_tmp(this.detail_ID)
            }
            this.loading = false
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "detail-order-edit-status-iscod  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }
        } else {

          try {
            let cod= this.edit_cod_amount==""?"0":this.edit_cod_amount
            let response2 = await axios.post(orderService_dotnet+'Order/detail-order-edit-status-iscod', {
               "orderMasterID": this.detail_ID,
               "isCOD": cod=="0"? 0:1,
               "codAmount": this.edit_cod_amount==""?0:this.edit_cod_amount,
               "shopBankAccountID": this.detail_ShopBankAccountID
             },
            { headers: this.header_token})
            this.ShowDetailOrder_tmp(this.detail_ID)
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "detail-order-edit-status-iscod  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }
        }

       },

       delete_data_payment_alert (item) {
         this.$swal({
         
           type: 'question',
           title: 'ต้องการลบ',
           text: 'ยอด : '+item.amount+" ใช่หรือไม่?",
           confirmButtonText: 'ยืนยัน',
           cancelButtonText: 'ยกเลิก',
           showCancelButton: true,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           allowOutsideClick: false
         }).then(async(result) => {
           if (result.value) {
            await  this.delete_data_payment(item)
           }

         })
       },

       async  delete_data_payment (item) {
         try {
           this.loading = false
          //  let response = await axios.post(orderService_dotnet+'Order/detail-order-delete-order-payment', {
          //    "id": item.id
          //  },
          //  { headers: this.header_token})
          //  if(response.data.status){

          //    if(item.photoLink != null){
          //      let response2 = await axios.post(generalService_dotnet+'General/delete-file', {
          //        "url": item.photoLink
          //      },
          //      { headers: this.header_token})
          //    }

          //    this.ShowDetailOrder_tmp(this.detail_ID)
          //  }

           let response = await axios.post(orderService_dotnet+'Order/delete-order-payment?id='+item.id +"&staffShopID="+this.StaffShopID,
           { headers: this.header_token})
           if(response.data.status){

            

             this.ShowDetailOrder_tmp(this.detail_ID)
           }
           this.loading = false
         } catch (e) {
           this.$swal({
               position: "top",
               type: "info",
               title: "detail-order-delete-order-payment  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
               text: e.message,
           })
         }

       },


        async  get_data_stock_for_edit () {
          
           if(this.orderItemInvoiceList.length==0){
            this.detailOrderProduct_tmp = [...this.orderItemList]
            this.detailOrderProduct_tmp.forEach(element => {
              element.isProductSet=false;
            });
           }else{
            this.detailOrderProduct_tmp = [...this.orderItemInvoiceList]
            this.IsHasItemInvoice=true;
           }

           

           this.vat_percent = JSON.parse(JSON.stringify(this.vat_percent_tmp))
           this.textField_deliveryCost = JSON.parse(JSON.stringify(this.textField_deliveryCost_tmp))
           this.select_discountType = JSON.parse(JSON.stringify(this.select_discountType_tmp))
           this.textField_discount = JSON.parse(JSON.stringify(this.textField_discount_tmp))
           this.select_vatType = JSON.parse(JSON.stringify(this.select_vatType_tmp))

           this.calculateTotalStockPrice()
           this.can_edit_product = true
           // this.dialog_edit_stock = true
         },


         async changePage() {
           if (this.pageLength!=1 && this.pageTemp!=this.page){
             this.offsets = (this.page*this.limits)-this.limits
             this.loading = true
             await this.getSelectProduct(this.limits,this.offsets,this.Popupsearch,this.page,false)
             this.pageTemp = this.page
           }
         },
         async PopupsearchStock(limits,offsets){
           if (this.searchStockModelPopup!=null){
             this.Popupsearch = this.searchStockModelPopup.trim()
             this.loading = true
             if (this.Popupsearch.length>0){
               this.getSelectProduct(limits,offsets,this.Popupsearch,1,true)
             }else{
               this.getSelectProduct(limits,offsets,null,1,true)
             }
           }
         },
         async OpenPopup(limits,offsets){
           
          
           this.loading = true
           this.searchStockModelPopup = null
           this.addOrderPopup.Select_Product = []
           this.addOrderPopup.Data = []
           // this.addOrderPopup.ProductID_List = []
           this.addOrderPopup.loading = false
           this.Popupsearch = null
           for (var i in this.detailOrderProduct_tmp){
             if (!this.addOrderPopup.ProductID_List.includes(this.detailOrderProduct_tmp[i]["stockShopId"])){
               if(this.detailOrderProduct_tmp[i]["stockShopId"] != null){
                 this.addOrderPopup.ProductID_List.push(this.detailOrderProduct_tmp[i]["stockShopId"])
               }
             }
           }

           this.page = 1
           this.pageTemp = 1
           this.getSelectProduct(limits,offsets,this.searchStockModelPopup,this.page,true)
         },
         async getSelectProduct(limits,offsets,searchstock,page,isNeedResultCount){
           try {
             this.loading = true
             let keyword = (this.StockSearchType == "ทั้งหมด"? searchstock:null);
            let name = (this.StockSearchType == "ชื่อ"? searchstock:null);
            let sku = (this.StockSearchType == "sku"? searchstock:null);
            let barcode = (this.StockSearchType == "barcode"? searchstock:null);
             let response = await axios.post(stockService_dotnet+'Stock/get-stock-list-addorder', {
                "shopID": this.shop_id,
                "keyword": keyword,
                "sku": sku,
                "name": name,
                "barcode": barcode,
                "warehouseID": this.Warehouse_List_selected,
                "isNeedResultCount": isNeedResultCount,
                "isProductSet":(this.StockIsProductSet=="ทั้งหมด"? null: (this.StockIsProductSet=="สินค้าไม่จัดเซ็ท"?false :true )),
                "skipStockShopIDList": this.addOrderPopup.ProductID_List,
                "skip": offsets,
                "take": limits,
             },
             { headers: this.header_token})


             if (response.status == 200){
                if(isNeedResultCount){
                  this.FindStockPopupResultCount = response.data.resultCount;
                }
                 this.pageLength =  (Math.ceil(this.FindStockPopupResultCount/this.limits))
                 if (this.pageLength<=0){this.pageLength=1}
                 this.page = page
                 this.pageTemp = page

                 var StockShopID_list = []
                 if (this.size=='xs' || this.size=='sm'){
                    // await this.SetUp_Product()
                    // var StockShopID_list = []

                      for (var a in response.data.stockList){

                      response.data.stockList[a]['unitPrice'] = response.data.stockList[a]['salePrice']
                     
                      if (StockShopID_list.includes(response.data.stockList[a]["id"])){
                        response.data.stockList[a]["selectProduct"] = true
                      } else {
                        response.data.stockList[a]["selectProduct"] = false
                      }

                    }
                  } else {
                    for (var b in response.data.stockList){
                     
                      response.data.stockList[b]['unitPrice'] = response.data.stockList[b]['salePrice']
                     
                    }
                  }
                    this.addOrderPopup.Data = response.data.stockList
                    for (var i in this.addOrderPopup.Select_Product){
                      StockShopID_list.push(this.addOrderPopup.Select_Product[i]["id"])
                    }
                 this.loading = false
                 this.addOrderPopup.Dialog = true
             } else {
                 this.loading = false
                 this.$swal({
                   position: "top",
                   type: "error",
                   text: "ไม่สามารถทำรายการได้",
                 })
             }
           } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "get-stock-list-addorder  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }
         },
         ProductSelect(item,index){
           this.addOrderPopup.Data[index]["selectProduct"] = !item["selectProduct"]
           if (item["selectProduct"] == true){ //นำเข้า Select_Product
             this.addOrderPopup.Select_Product.splice(0, 0, item)
           }else{ //นำออก Select_Product
             var data = this.addOrderPopup.Select_Product
             for (var i in data){
               if (data[i]["id"] == item["id"]){
                 this.addOrderPopup.Select_Product.splice(i,1)
                 break
               }
             }
           }
         },
         ProductSelectCheckbox(item,index){
           if (item["selectProduct"]){ //นำเข้า Select_Product
             this.addOrderPopup.Select_Product.splice(0, 0, item)
           }else{ //นำออก Select_Product
             var data = this.addOrderPopup.Select_Product
             for (var i in data){
               if (data[i]["id"] == item["id"]){
                 this.addOrderPopup.Select_Product.splice(i,1)
                 break
               }
             }
           }
         },
         SetUp_Product(){
           var StockShopID_list = []
           for (var i in this.addOrderPopup.Select_Product){
             StockShopID_list.push(this.addOrderPopup.Select_Product[i]["id"])
           }
           for (var a in this.addOrderPopup.Data){

             if (StockShopID_list.includes(this.addOrderPopup.Data[a]["id"])){
               this.addOrderPopup.Data[a]["selectProduct"] = true
             } else {
               this.addOrderPopup.Data[a]["selectProduct"] = false
             }
           }
         },
         async Submit_addOrderPopup(){
           this.addOrderPopup.loading = true
           for (var i in this.addOrderPopup.Select_Product){
             await this.searchStockModel_push(this.addOrderPopup.Select_Product[i])
           }
           this.addOrderPopup.loading = false
           this.addOrderPopup.Dialog = false
           
           
         },
         async searchStockModel_push(product){
             if (product != null) {
               var IsFound = false
               if(product.isProductSet == true || product.isProductSet == 1)
                {
                  for (var i2 = 0; i2 < this.detailOrderProduct_tmp.length; i2++) 
                  {
                    if((this.detailOrderProduct_tmp[i2].isProductSet == true || this.detailOrderProduct_tmp[i2].isProductSet == 1 ) && this.detailOrderProduct_tmp[i2].productMasterItemId == product.productMasterItemId ){
                      IsFound = true ;
                      break ;
                    }
                  }
                }else{
                  for (var i1 = 0; i1 < this.detailOrderProduct_tmp.length; i1++) 
                  {
                    if(this.detailOrderProduct_tmp[i1].stockShopId == product.id){
                      IsFound = true
                      break;
                    }
                  }
                } 

               if(!IsFound){
                 var data_set ={
                   "orderItemTempID": null,
                   "discount": null,
                   "discountPercent": null,
                   "productName": product['name'],
                   "orderMasterId": this.detail_ID,
                   "photoLink": product['photoLink'],
                   "quantity": 1,
                   // "stockID": value['ID'],
                   "stockShopId": product['id'],
                   "unitPrice": product['unitPrice'],
                   "prop1Description": product['prop1Description'],
                   "sku": product['sku'],
                   "barcode": product['barcode'],
                   "prop2Description": product['prop2Description'],
                   "id": 0,
                   "orderItemId":0,
                   "isProductSet" : product['isProductSet']==0? false:true,
                   "productMasterId": product['productMasterId'],
                   "productMasterItemId": product['productMasterItemId']
                 }
                 // this.productList.push(value)
                 this.detailOrderProduct_tmp.push(data_set)
                 // this.detailOrderProduct_tmp_insert.push(data_set)

                 this.calculateTotalStockPrice()
               }
             }
         },

         async get_v_stock_shop_by_id () {
           if(this.detailOrderProduct_tmp.length == 0) return
           this.addOrderPopup.ProductID_List = []
           for(var d=0; d< this.detailOrderProduct_tmp.length; d++){
             this.addOrderPopup.ProductID_List.push(this.detailOrderProduct_tmp[d]['stockShopId'])
           }
            
           
         },

         copyToken () {
           // this.detail_Token
           let TokenCodeToCopy = document.querySelector('#testing-code')
           TokenCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
           TokenCodeToCopy.select()

           try {
             var successful = document.execCommand('copy');
             var msg = successful ? 'สำเร็จ' : 'ไม่สำเร็จ';
             this.$swal({
               toast: true,
               timer: 1500,
               showConfirmButton: false,
               position: 'top',
               type: 'success',
               title: 'คัดลอก' + msg
             })
           } catch (err) {
             this.$swal({
               type: 'warning',
               title: 'เกิดข้อผิดพลาดบางอย่าง',
               text: 'คัดลอกไม่สำเร็จ !!',
             })
           }

           /* unselect the range */
           TokenCodeToCopy.setAttribute('type', 'hidden')
           window.getSelection().removeAllRanges()
         },

         set_zero (num) {
           num = parseInt(num)
           if ( num < 10 ) {
             num = '0'+num.toString()
           } else {
             num = num.toString()
           }
           return num
         },

         status_eng_to_thai (test_eng) {
           if(test_eng != null){
             if(test_eng == 'shipping-from-chinese-shop'){
               return 'ร้านค้าจีนส่งมา'
             } else if (test_eng == 'arrive-at-chinese-warehouse') {
               return 'ถึงโกดังจีน'
             } else if (test_eng == 'shipping-from-chinese-warehouse') {
               return 'ส่งออกจากโกดังจีน'
             } else if (test_eng == 'arrive-at-thai-warehouse') {
               return 'ถึงโกดังไทย'
             }
           } else {
               return ''
           }
         },

        openMhaoDialog (MhaoGuidID, mh_line_item_id, row_id, status) {
          var date = new Date().toISOString().substr(0, 10)
          var current = new Date()
          var status_convert = null
          if(status=='สั่งแล้ว'){ status_convert = 2 }
          if(status=='รอรับสินค้า'){ status_convert = 3 }
          if(status=='สำเร็จ'){ status_convert = 4 }
          this.mh_selectStatus = status_convert
          this.MhaoGuidID = MhaoGuidID
          this.row_id = row_id
          this.startDate = date
          this.mh_line_item_id = mh_line_item_id
          this.mhaoDialog = true
          this.time = this.set_zero(current. getHours().toString()) + ':' + this.set_zero(current. getMinutes().toString())

        },


         img_express_return (express_id) {
           if (express_id != null ) {
             if(express_id == 1){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/BESTSmallLogo.JPG'
             }
             else if(express_id == 2){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/kerry_ExpressLogo.png'
             }
             else if(express_id == 3){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/EMSSmallLogo.JPG'
             }
             else if(express_id == 4){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/thaipost.jpg'
             }
             else if(express_id == 5){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/thaipost.jpg'
             }
             else if(express_id == 6){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/NimSmallLogo.JPG'
             }
             else if(express_id == 7){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/FlashSmallLogo.JPG'
             }
             else if(express_id == 8){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/shopee.png'
             }
             else if(express_id == 9){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/lazada.png'
             }
             else if(express_id == 10){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/SCGSmallLogo.JPG'
             }
             else if(express_id == 11){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/JandTSmallLogo.png'
             }
             else if(express_id == 12){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/ninjavan_logo.png'
             }
             else if(express_id == 13){
               return 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/dhl.PNG'
             }
           }else {
             return null
           }
         },

         async get_postcode_mapping () {
           try {
             var postcode_mapping = JSON.parse(localStorage.getItem('postcodeMapping_dotnet'))
             if(postcode_mapping === 'undefined'){
               postcode_mapping = null
             } else if ( postcode_mapping == 'null') {
               postcode_mapping = null
             } else if ( postcode_mapping == null) {
               postcode_mapping = null
             } else if (postcode_mapping == '') {
               postcode_mapping = null
             }
             if(postcode_mapping != null){
               this.postcode_mapping = postcode_mapping
             } else {
               await axios.post(generalService_dotnet+'General/get-postcodemapping', {},
               { headers: this.header_token})
               .then(response =>
                 {
                   localStorage.setItem('postcodeMapping_dotnet', JSON.stringify(response.data))
                   this.postcode_mapping = response.data
                 })
             }
           } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "get-postcodemapping  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }
         },

        open_dialog_update_item_mhao (item) {
          this.dialog_edit_item_mhao = true
          this.edit_item_mhao_id = item['itemId']
          this.edit_item_mhao_quantity = item['Quantity']
          this.edit_item_mhao_unit_price = item['UnitPrice']
        },



        async check_stock_item_product_set (productMasterID, quantity) {
          try {
            let response = await axios.post(stockService_dotnet+'Stock/check-stock-avalible-in-product-set', {
              "productMasterID": productMasterID,
              "quantity": quantity
            },
            { headers: this.header_token})
            return response.data
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "check-stock-avalible-in-product-set  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }
        },

        async get_order_master_pic_for_pack (orderID) {
          try {
            let response = await axios.post(orderService_dotnet+'Order/get-order-master-pic',{ "orderID": orderID } , { headers: this.header_token})

            if(response.data != null){
              for (var item of response.data) {
                if(item.mediaType == 0){
                  this.data_master_pic.push(item)
                }else {
                  this.data_master_media.push(item)
                }
              }
            }
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "get-order-master-pic  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }
        },

        async shop_change_warehouse () {

          try {
            for (var item of this.Warehouse_List) {
              if(this.Warehouse_List_selected == item.id){
                this.Warehouse_List_selected_text = item.name ;
                break;
              }
            }

            this.loading = true
            let response = await axios.post(orderService_dotnet+'Order/change-warehouse',{
              "orderMasterID": this.detail_ID ,
              "warehouseID": this.Warehouse_List_selected,
            } , { headers: this.header_token})
            this.loading = false
            this.can_edit_warehouse = false
            this.ShowDetailOrder_tmp(this.detail_ID)
          } catch (e) {
            this.$swal({
                position: "top",
                type: "info",
                title: "change-warehouse  เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                text: e.message,
            })
          }

        },
        async get_authorize_staffshop () {
        if(localStorage.getItem("320") == 2 )
        {
         this.authorize = true
        }
        },


        
        async getSelectPackage(limits,offsets,searchstock,page,isNeedResultCount,ProductIDList){
           try {               
             return await axios.post(stockService_dotnet+'Stock/get-stock-list-addorder', {
                "shopID": this.shop_id,
                "keyword": searchstock,
                "sku": null,
                "name": null,
                "barcode": null,
                "warehouseID": this.Warehouse_List_selected,
                "isNeedResultCount": isNeedResultCount,
                "isProductSet":false,
                "skipStockShopIDList": ProductIDList,
                "skip": offsets,
                "take": limits,
             },
             { headers: this.header_token})
             .then(async (res) => {
              return res.data;
             })
            .catch((error) => {
              this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: error,
             })
              return false;
            });

            
           } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }
         },
         async UpdateSplitPackageForOrderDetailPage(body) {
        
          
          return await axios
            .post(
              orderService_dotnet +"Package/split-package-for-order-detail-page",body,
              { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data
            })
            .catch((error) => {
              this.loading = false;
              this.AlertError();
              return false;
            });
        },
        async btnDeletePacked(item,index){
        try {

          this.packageList[item.packageNo-1].itemList.splice(index, 1);
        
          let newObj = this.packageList[item.packageNo - 1].itemList.filter(( i) => i !== index)
          
          this.$set(this.packageList[item.packageNo - 1],"itemList", newObj);  

          // หลังจากแยกเสร็จให้ ปรับรูปตาราง
          let sortPackageList = this.packageList.filter( x => x.itemList.length > 0 )
            let indexSort = 1;
              for(let sort of sortPackageList )
              {
                  sort.packageNo = indexSort;
                  sort.id = sort.id ==  null? indexSort : sort.id;
                  let setpackageNo =   sort.itemList.map(Sort => {
                    return { ...Sort, packageNo: indexSort };
                  });
                  sort.trackStatus = indexSort
                  sort.itemList = setpackageNo
                
                
                  indexSort++;
                
              } 
              this.packageList = sortPackageList
          
          this.$forceUpdate();
        } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }

        },
        async btnSplitPackage(itemList){
          
          let cloneItem = [...itemList.select];
            
           
          cloneItem.forEach(element => {
            element.moveQuantity = element.quantity;
          });
          this.splitPackageDataSet.dataSet = cloneItem;

          this.splitPackagePopup.dialog = true;
        },
        async btnSubmitSplitPackage(){
           
          let checkQuantityPack = this.splitPackageDataSet.dataSet.filter( c=> 
          { return c.quantity < c.moveQuantity ||c.moveQuantity == null || c.moveQuantity == "" || c.moveQuantity == 0} )
          if(checkQuantityPack.length > 0){
            this.alertError("จำนวนที่ต้องการแยกไม่ถูกต้อง")
            return false
          }
          if(this.splitPackageDataSet.radio == '1' && !this.splitPackageDataSet.moveTo  ){
              this.alertError("กรุณาใส่กล่องที่ย้าย")
              return false
            }            
          if(this.splitPackageDataSet.radio == '1' && this.splitPackageDataSet.moveTo  ){
            // if(this.packageList.length == this.splitPackageDataSet.moveTo ){
            //   this.alertError("ไม่สามารถย้ายใส่กล่องกล่องเดิมได้")
            //   return false
            // }
            if(  this.splitPackageDataSet.moveTo == 0 ){
              this.alertError("กรุณากรอกกล่องที่ต้องการย้ายให้ถูกต้อง")
              return false
            }
            if(this.packageList.length < this.splitPackageDataSet.moveTo-1  ){
              // console.log("this.packageList.length",this.packageList.length);
              // console.log("this.splitPackageDataSet.moveTo",this.splitPackageDataSet.moveTo);
                            
              this.splitPackageDataSet.radio = '0'
              // this.splitPackageDataSet.moveTo = this.packageList.length+1
              // this.alertError("ย้ายใส่กล่องไม่ถูกต้อง")
              // return false
            }
            
            
          }
          this.splitPackageDataSet.dataSet.forEach(element => {
            element.moveQuantity =parseInt( element.moveQuantity);
          });
          if(this.splitPackageDataSet.radio == '0'){
            var maxPackage= Math.max.apply(Math, this.packageList.map(function(o) { return o.packageNo; }))+1;
            this.moveSplitPackage(this.splitPackageDataSet.dataSet,maxPackage);
          }else{
            this.moveSplitPackage(this.splitPackageDataSet.dataSet,this.splitPackageDataSet.moveTo);
          }
         
          
        },
        async moveSplitPackage(data,newPackageNo){
          let backupItems = [];
          
          if(newPackageNo==null|| newPackageNo==''){
            newPackageNo = Math.max.apply(Math, this.packageList.map(function(o) { return o.packageNo; }))+1;
            
          }
          
          console.log(data[0].packageNo);
          
          let packageIndex = this.packageList.findIndex(x=>x.packageNo ==data[0].packageNo);
       
          for(let item of data)
          {

            let temp = this.packageList[packageIndex].itemList.find(i => i.stockShopId == item.stockShopId)
            
            if(temp != undefined )
            {
              let checkCount = parseInt(temp.quantity) - parseInt( item.moveQuantity)
              if(checkCount == 0)
              {
                let index = this.packageList[packageIndex].itemList.findIndex(i => i.stockShopId == item.stockShopId);
                let removedItem =  this.packageList[packageIndex].itemList.splice(index, 1);
                this.$forceUpdate(this.packageList);
                backupItems.push(...removedItem);
              }
              else
              {
                temp.quantity = checkCount
                let addItem  = {...temp, quantity : temp.moveQuantity}
                backupItems.push(addItem);
              }
            }
          }
          this.$set(this.packageList[packageIndex], 'itemList', [...this.packageList[packageIndex].itemList]);

          this.$set(this.packageList[packageIndex],"select", []); 
        
          let newPackageIndex=0;
          for(let move of data)
          {
          
            
            newPackageIndex = this.packageList.findIndex(x=>x.packageNo ==newPackageNo);
          
            if(newPackageIndex==-1){ 
              newPackageIndex = this.packageList.findIndex(x=>x.packageNo ==newPackageNo);
              var newPackage = { packageNo:newPackageNo,itemList:[],trackStatus:[]}
              this.packageList.push(newPackage);
              newPackageIndex = this.packageList.findIndex(x=>x.packageNo ==newPackageNo);
            }
            
            let temp = this.packageList[newPackageIndex].itemList.find(i => i.stockShopId == move.stockShopId)
            if(temp==null)
            {
              
              let addMove = { ...move ,packageNo :newPackageNo , quantity:move.moveQuantity }
              this.packageList[newPackageIndex].itemList.push(addMove)
              this.$set(this.packageList[newPackageIndex], 'itemList', [...this.packageList[newPackageIndex].itemList]);
            }
            else
            {

              temp.quantity = temp.quantity +  move.moveQuantity
            }

          }
          
          this.$set(this.packageList[newPackageIndex], 'itemList', [...this.packageList[newPackageIndex].itemList]);
          this.$set(this.packageList[newPackageIndex],"select", []);
          
          if(this.packageList.filter(x => x.itemList.length == 0).length>0)
          {
            newPackageNo=1; 
            let sortPackageList = JSON.parse(JSON.stringify( this.packageList.filter(x => x.itemList.length > 0)));

            var index=0;
            for(let element of sortPackageList)
            {
                if(element.dueDeliveryDate == undefined){
                  element.dueDeliveryDate = null
                } 
                this.packageList[index].itemList = element.itemList
                this.packageList[index].dueDeliveryDate = element.dueDeliveryDate;
                this.packageList[index].packageNo = newPackageNo; 
                this.packageList[index].itemList.forEach(element => {
                  element.packageNo=newPackageNo;
                });
                newPackageNo++;
                index++;
            }

            if(this.packageList.length>index){
              this.packageList.splice(index );
            }
           
          }
          
          this.splitPackageDataSet.moveTo = null
          this.splitPackageDataSet.radio = '0' 
          this.splitPackagePopup.dialog = false; 
         
              
        },
        async btnEditPackaged(){ 
          
          var  data = this.packageList.map((entry) => {
            
          
          if(entry.dueDeliveryDate !== null){
            entry.dueDeliveryDate = moment(entry.dueDeliveryDate, "YYYY-MM-DDTHH:mm:ss.SS").format("DD-MM-YYYY");
          } 

          // อัปเดต packedQuantity ใน itemList
          entry.itemList = entry.itemList.map((item) => ({
            ...item,
            moveQuantity: item.quantity
          }));

          return entry;
        });

          this.can_edit_packaged = true;
        },
         checkSplitBtn(itemList){
          
          if( itemList.select &&  itemList.select.length > 0  && itemList.select != undefined ){
            // let checkQuantityPack = itemList.select.filter( (item )=> 
            //   {  
            //     return item.quantity < item.packedQuantity 
                
            //     || item.packedQuantity == null 
            //      ||  item.packedQuantity == ""
             
            //   }
             
            // )
            // if(!checkQuantityPack.length == 0 ){ return  true}
            return false;
          }
          return true;
        },
        async alertError(error_description) {
          Swal.fire({
            text: error_description,
            title: "ไม่สามารถทำรายการได้",
            type: "error",
          });
        },
        async alertSuccess(error_description) {
          Swal.fire({
            text: error_description,
            title: "ทำรายการสำเร็จ",
            type: "success",
          });
        },
      
        async OpenPopupAddPackage(data,limits,offsets){
         try {
          
         
           let ResultCount = 0
           this.loading = true;
           this.addPackagePopup.searchStockModelPopup = null;
           this.addPackagePopup.Select_Product = [];
           this.addPackagePopup.Data = [];
           this.addPackagePopup.ProductIDList = [];
           this.addPackagePopup.loading = false;

           let ProductIDList = data.itemList.map(m => m.stockShopId);
            

           this.addPackagePopup.Popupsearch = null
           this.addPackagePopup.ProductIDList = ProductIDList

           this.addPackagePopup.page = 1
           this.addPackagePopup.pageTemp = 1
           let response  =await this.getSelectPackage(this.addPackagePopup.limits,this.addPackagePopup.offsets,this.addPackagePopup.searchStockModelPopup,this.addPackagePopup.page,true,this.addPackagePopup.ProductIDList)
             
            
          
            if(response.resultCount){
               ResultCount = response.resultCount;
             
              
              this.addPackagePopup.pageLength =  (Math.ceil(ResultCount/this.addPackagePopup.limits)) 
              
              if (this.addPackagePopup.pageLength<=0){this.addPackagePopup.pageLength=1}
            }
          
              this.addPackagePopup.Data = response.stockList

              this.loading = false
              this.addPackagePopup.Dialog = true
          
            } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }



         },
        async btnChangePagePackage() {
           if (this.addPackagePopup.pageLength!=1 && this.addPackagePopup.pageTemp!=this.addPackagePopup.page){
            this.addPackagePopup.offsets = (this.addPackagePopup.page*this.addPackagePopup.limits)-this.addPackagePopup.limits
             this.loading = true
             let response = await this.getSelectPackage(this.addPackagePopup.limits,this.addPackagePopup.offsets,this.addPackagePopup.Popupsearch,this.addPackagePopup.page,false,this.addPackagePopup.ProductIDList)
            
             this.addPackagePopup.Data = response.stockList

             this.addPackagePopup.pageTemp = this.addPackagePopup.page
           }
           this.loading = false;
         },
        async btnSubmitAddPackagePopup(){
          this.loading = true
      
          
          // this.addPackagePopup.SelectPackage =[ ...
          //   {orderItemId: this.addPackagePopup.SelectPackage.id,
          //   productName : this.addPackagePopup.SelectPackage.name,
          //   packageNo : this.packageNo,
          //   packedQuantity:null,
          //   quantity:1,}
          //   ]
          // this.addPackagePopup.SelectPackage.orderItemId = this.addPackagePopup.SelectPackage.id

          // this.addPackagePopup.SelectPackage.productName = this.addPackagePopup.SelectPackage.name
          // this.addPackagePopup.SelectPackage.packageNo = this.packageNo
          // this.addPackagePopup.SelectPackage.packedQuantity = null
          // this.addPackagePopup.SelectPackage.quantity = 1
   
          let transformedItems = this.addPackagePopup.SelectPackage.map((item) => { 
            
            return {...item,
              stockShopId: item.id || 0,
              productName: item.name || '',
              packageNo: this.packageNo || 0,
              packedQuantity: null,
              quantity: item.packedQuantity == undefined ? 1: item.packedQuantity ,
              
            };
          });

          // เพิ่มรายการที่แปลงแล้วไปใน packageList
          // this.packageList[this.packageNo-1].itemList.push(...transformedItems)
          
          // let newList = this.packageList[this.packageNo - 1].itemList

           transformedItems.push(...this.packageList[this.packageNo - 1].itemList)
     
           
          
          // console.log("newObj",newObj);
          

          this.$set(this.packageList[this.packageNo - 1], 'itemList', transformedItems );

          // this.$set(this.packageList[item.packageNo - 1],"itemList", newObj);   

          this.$forceUpdate();
          this.addPackagePopup.SelectPackage = []
          this.loading = false
          this.addPackagePopup.Dialog = false
          
          
        },
        async btnSearchPackagek(limits,offsets){
          let response =[]
          let ResultCount = 0
          // this.addPackagePopup.searchStockModelPopup

          // let response  =await this.getSelectPackage(this.addPackagePopup.limits,this.addPackagePopup.offsets,this.addPackagePopup.searchStockModelPopup,this.addPackagePopup.page,true,this.addPackagePopup.ProductIDList)


            
           if (this.addPackagePopup.searchStockModelPopup != null){
            this.addPackagePopup.Popupsearch = this.addPackagePopup.searchStockModelPopup.trim()
             this.loading = true
             if (this.addPackagePopup.Popupsearch.length>0){
               response  =await this.getSelectPackage(limits,offsets,this.addPackagePopup.Popupsearch,1,true,this.addPackagePopup.ProductIDList)
              }else{
                response  = await this.getSelectPackage(limits,offsets,null,1,true,this.addPackagePopup.ProductIDList)
               }
             if(response.resultCount){
               ResultCount = response.resultCount;
             
              
              this.addPackagePopup.pageLength =  (Math.ceil(ResultCount/this.addPackagePopup.limits))
              
              if (this.addPackagePopup.pageLength<=0){ this.addPackagePopup.pageLength = 1}
            }
          
              this.addPackagePopup.Data = response.stockList
              this.loading = false
           }
           
           
           this.loading = false;
         },
         
         async btnSaveChangePackage() {
          let body = {
            "orderMasterID": this.detail_ID,
            "staffShopID": this.StaffShopID,
            "packages": []
          };

          for (let packageData of this.packageList) {
            // สร้าง packagesBody เป็น object
            let packagesBody = {
              packageNo: packageData.packageNo,
              dueDeliveryDate: packageData.dueDeliveryDate != null?  moment(packageData.dueDeliveryDate, "DD-MM-YYYY").format("YYYY-MM-DD") : packageData.dueDeliveryDate,
              items: packageData.itemList.map(item => ({
                stockShopID: item.stockShopId,
                quantity: item.quantity
              }))
            };

            // เพิ่ม packagesBody ลงใน body.packages
            body.packages.push(packagesBody);
          }
        
          
      

          // เรียกใช้ฟังก์ชัน API หรือการอัปเดตข้อมูล
          let res = await this.UpdateSplitPackageForOrderDetailPage(body);
          if(res.status == "success"){
            this.alertSuccess();
            this.can_edit_packaged =false
          }else{
            this.alertError(res.error_description)
          }
          
          
        },
        async  btnSavePayment() {
          if(this.input_transferAmount == null||this.input_transferAmount==''||this.input_transferAmount=='0')
          {
            this.$refs.input_transferAmount.focus()
            this.$swal({
              type: "warning",
              text: "กรุณาระบุยอดเงิน",
            })
            return
          }
          if(parseInt(this.input_transferStatusID)<4  )
          {
            if(this.select_dueDate==null)
            {
              this.$swal({
                type: "warning",
                text: "กรุณาระบุวันที่ดิวชำระ",
              })
              return
            }
            else
            {
              this.select_transferDate=null;
              this.paymentBank=null;
            }
            
          }
          if(parseInt(this.input_transferStatusID)==4  )
          {
            if(this.select_transferDate==null)
            {
              this.$swal({
                type: "warning",
                text: "กรุณาระบุวันที่ขำระจริง",
              })
              return
            } 
          }
          if(this.input_transferFileUpload){
            let resUpload = await this.UploadFile(this.input_transferFileUpload)
            if(resUpload.status == "success"){
              this.input_transferPhotoLink = resUpload.file_path
            }else{
              this.alertStop();
            }
          }
          var transfer_date=null;
          var due_date=null;
          if(this.select_transferDate !== null)
          {
            let hours = this.select_transferHour??"00"
            let Minute = this.select_transferMinute??"00"
            transfer_date = this.select_transferDate + "T" + hours+ ":" + Minute+":00"
          }
          if(this.select_dueDate){ 
            due_date = this.select_dueDate;
          }

           // var data_list_payment = []
          var payment_data = {
            "id": this.input_transferId == null ? 0 : this.input_transferId ,
            "orderMasterId": this.detail_ID,
            "shopCustomerID": (this.paymentBank==null? null : (this.paymentBank.id == -1 ? this.currentShopCustomerID:null)),
            "bankAccountID": (this.paymentBank==null ||  this.paymentBank.id == -1 ? null: this.paymentBank.id),
            "amount": parseFloat( Number(this.input_transferAmount).toFixed(2)),
            "photoLink": this.input_transferPhotoLink,
            "informDatetime": transfer_date,
            "dueDate": due_date,
            "staffShopID": this.StaffShopID,
            "statusID": parseInt(this.input_transferStatusID),
            "remark": this.input_transferRemark
          }
          this.loading=true;
          let resUPdate = await this.updateOrderPayment(payment_data);
         
          if(resUPdate.status=="success"){
           // เคลียข้อมูล
            this.edit_cod_bank = null
            this.input_transferFileUpload = null
            this.input_transferAmount = null
            this.select_transferDate = null
            this.select_transferHour = null
            this.select_transferMinute = null
            
            this.btnClosePayment();
            this.GetOrderPayment(this.detail_ID)

          } 
          this.loading=false;
           
        },
        async btnOpenAddSlip(){
          this.currentShopCustomerID = null;
          let shopcustomer = await axios.get(orderService_dotnet+'Order/get-shop-customer-by-orderid?OrderID='+this.detail_ID, { headers: this.header_token});
          this.currentShopCustomerID =shopcustomer.data.id;

          this.select_transferDate  =  null
          this.select_transferHour  = null
          this.select_transferMinute  = null
          this.select_dueDate  = null;
          this.input_transferStatusID="2";
          this.shop_bank_account_to_show = JSON.parse(JSON.stringify(this.shop_bank_account));
          
          var sumAmount = this.detailOrderPayment_temp.reduce((partialSum, a) => partialSum + a.amount, 0);
          
          if(shopcustomer.data.balance>=parseFloat(this.detail_orderTotalAmount) - sumAmount)
          {
            var bank_balance1= {id:-1,fullBankName:"Balance"};
            this.shop_bank_account_to_show.push(bank_balance1);
          } 
          this.input_transferAmount =parseFloat(this.detail_orderTotalAmount) - sumAmount
          this.input_transferId =0; 
          this.input_transferFileUpload = null
          this.input_transferPhotoLink =null 
          this.input_transferRemark = null
          this.dialog_add_slip = true;
        },
        async btnEditPayment(data){ 
          this.currentShopCustomerID = null;
          let shopcustomer = await axios.get(orderService_dotnet+'Order/get-shop-customer-by-orderid?OrderID='+data.orderMasterId, { headers: this.header_token});
          this.currentShopCustomerID =shopcustomer.data.id;

          if(data.informDatetime){
            let date =  data.informDatetime.substr(0,10);
            let hours = moment(data.informDatetime, "YYYY-MM-DDTHH:mm:ss.SS").format("HH");
            let minute = moment(data.informDatetime, "YYYY-MM-DDTHH:mm:ss.SS").format("mm");

            
            this.select_transferDate  =  date
            this.select_transferHour  = hours
            this.select_transferMinute  = minute
          }
          
           if(data.dueDate){
            let date1 =  data.dueDate.substr(0,10);
            this.select_dueDate  =  date1
           }else{
            this.select_dueDate  = null;
           }
           
          this.shop_bank_account_to_show = JSON.parse(JSON.stringify(this.shop_bank_account));
          
          if(data.bankAccountText=="Balance")
          {
            var bank_balance= {id:-1,fullBankName:"Balance"};
            this.shop_bank_account_to_show.push(bank_balance);
            this.paymentBank = bank_balance;
            this.currentShopCustomerID = data.shopCustomerID;
          }
          else
          {
            let bankAccountID = this.shop_bank_account.filter(b => b.id == data.bankAccountID )
          
            
            if(bankAccountID.length>0 )
              this.paymentBank =  bankAccountID[0];
            else
              this.paymentBank =null

            if(shopcustomer.data.balance>=data.amount){
              var bank_balance1= {id:-1,fullBankName:"Balance"};
              this.shop_bank_account_to_show.push(bank_balance1);
            } 

        
          }
          this.input_transferId = data.id
          this.input_transferStatusID = data.statusID.toString();
          this.input_transferFileUpload = null
          this.input_transferPhotoLink = data.photoLink
          this.input_transferAmount = data.amount  
          this.input_transferRemark = data.remark
          this.dialog_add_slip = true
        },
        async btnClosePayment(){ 
           this.input_transferFileUpload = null
           this.input_transferPhotoLink = null;
           this.input_transferAmount = null
           this.input_transferDueDate = null
           this.input_transferStatusID="2"
           this.select_transferDate = null
           this.select_dueDate = null
           this.select_transferHour = null
           this.select_transferMinute = null
           this.input_transferId = 0
           this.input_transferRemark=null;
           this.paymentBank =null;
        
           this.dialog_add_slip = false
        },
        async UploadFile(files){
            let bodyUploadFile = [];
      
            
            var from_data = new FormData();
                from_data.append("bucket", "Doc_file");
                from_data.append("file", files);
                bodyUploadFile = from_data;

            return await axios
              .post(
                generalService_dotnet + "General/upload-file-with-bucket",
                bodyUploadFile,
                { headers: this.header_token }
              )
              .then(async (res) => {
                  return res.data
              })
              .catch((error) => {
                this.loading = false;
                this.AlertError();
                return false;
              });
        },
        async updateOrderPayment(body) {
          return await axios
            .post(
              orderService_dotnet +"Order/update-order-payment",
              body,
              { headers: this.header_token }
            )
            .then(async (res) => { 
                return res.data
            })
            .catch((error) => {
              this.loading = false;
              this.AlertError();
              return false;
            });
        },

        formatDateTimeToISO(dateTime) {
          return moment(dateTime, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SS");
        },

      }
  }
</script>
<style scoped>
  /* >>>.v-dialog {
      -webkit-box-shadow: none;
      box-shadow: none;
  } */
  .data-table-select-column {
  display: inline-block;
  width: 2px;
  text-align: center;
  
}
  >>>.bex48-logo {
      width: 12.533vw;
      height: 11.2vw;
      display: block;
      /* margin: 8.8vw auto 1.6vw; */
      margin: 1.6vw auto 1.6vw;
  }

  >>>.slide-fade-enter-active {
    transition: all .3s ease;
  }
  >>>.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  >>>.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
  }

  .packhai-border-table{
    border:1px solid rgba(0, 0, 0, 0.12);
    border-radius:4px;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{

    font-weight: bold;
    font-size: 1rem;
  }
  .return-order-table{
    width: 100%;
  }
  .padding-for-order-dialog{
    padding: 0px !important ;
  }
  @media screen and (min-width: 768px) {
    .return-order-table {
      width: 300px;
    }

    .padding-for-order-dialog{
      padding: 15px !important;
    }
  }

  .packhai-border-preview {
    border-top: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
    border-radius: 10px ;
  }

  .px-15{
    padding-left: 15px;
    padding-right:  15px;
  }

  .fz-12{
    font-size: 12px !important;
  }

  .fz-14{
    font-size: 14px !important;
  }
</style>
