<template>
    <div>
  
      <!-- แสดงภาพ -->
      <v-dialog v-model="showphoto" max-width="500" persistent>
        <v-card class="ma-0">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <img class="hidden-md-and-up" :src="showphotolink" width="300">
                <img class="hidden-sm-and-down" :src="showphotolink" width="450">
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn text color="#1976d2" @click="Opentap(showphotolink)">เปิดแถบใหม่</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="#1976d2" @click="ClosePopup()">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
    export default {
      props: [
        'showphotolink',
        'showphoto',
      ],
      methods: {
        Opentap(PhotoLink){
          window.open(PhotoLink);
        },
        ClosePopup(){
          !this.showphoto
          this.$emit('emitshowphoto', false)
        },
      },
    }
  </script>
  