<template>
    <div v-if="page_loading">
      <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />
  
      <!-- นำทาง -->
      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
         การเงิน
          <v-icon>mdi-chevron-right</v-icon>
          รายการจ่ายเงิน
        </v-card-title>
      </v-card>
  
      <!-- แสดงภาพ -->
      <v-dialog v-model="ShowPhoto" max-width="500">
        <v-card class="ma-0">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
                <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- หัวข้อเมนู -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
          <div class="py-1">
            <v-icon size="22" color="orange darken-3">mdi mdi-view-list</v-icon><span class="ml-3">{{use_language.info}}</span>
          </div>
          <v-spacer/>
          <div>
            <vue-excel-xlsx
                id="GetExcel"
                v-show="false"
                :data="datasetExcel"
                :columns="headerExcel"
                :file-name="ExcelName"
              
                >
            </vue-excel-xlsx>
            <v-btn :elevation="1" outlined color="success" class="px-2" @click="ExportExcel()">
              <v-icon size="18" class="ml-1" left>fa-download</v-icon>
              {{use_language.export_excel}}
              <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
            </v-btn>
           
          </div>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="px-0">
        
             
              <v-row> 
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> วันที่ดิวชำระ : </h3>
                </v-col>
                <v-col cols="12" md="4"   class="pt-1 pb-2">
                  <v-menu
                    v-model="menu_due_date_from"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computed_due_date_from"
                            @change="value => computed_due_date_from = value"
                            autocomplete="off"
                            ref="select_dueDate_from"
                            outlined
                            label="จากวันที่"
                            prepend-icon=""
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            clearable
                            dense
                            @click:clear="select_dueDate_from = null"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="select_dueDate_from"
                        no-title 
                        @input="menu_due_date_from = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4"  class="pt-1 pb-2">
                  <v-menu
                    v-model="menu_due_date_to"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computed_due_date_to"
                            @change="value => computed_due_date_to = value"
                            autocomplete="off"
                            ref="select_dueDate_from"
                            outlined
                            label="จากวันที่"
                            prepend-icon=""
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            clearable
                            dense
                            @click:clear="select_dueDate_to = null"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="select_dueDate_to"
                        no-title 
                        @input="menu_due_date_to = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row> 
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> วันที่ชำระเงิน : </h3>
                </v-col>
                <v-col cols="12" md="4"   class="pt-1 pb-2">
                  <v-menu
                    v-model="menu_transfer_date_from"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computed_transfer_date_from"
                            @change="value => computed_transfer_date_from = value"
                            autocomplete="off"
                            ref="select_transferDate_from"
                            outlined
                            label="จากวันที่"
                            prepend-icon=""
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            clearable
                            dense
                            @click:clear="select_transferDate_from = null"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="select_transferDate_from"
                        no-title 
                        @input="menu_transfer_date_from = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4"  class="pt-1 pb-2">
                  <v-menu
                    v-model="menu_transfer_date_to"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computed_transfer_date_to"
                            @change="value => computed_transfer_date_to = value"
                            autocomplete="off"
                            ref="select_transferDate_to"
                            outlined
                            label="ถึงวันที่"
                            prepend-icon=""
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            clearable
                            dense
                            @click:clear="select_transferDate_to = null"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="select_transferDate_to"
                        no-title 
                        @input="menu_transfer_date_to = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row> 
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> หาจากสถานะ : </h3>
                </v-col>
                <v-col cols="12" md="8"   class="pt-1 pb-2">
                  
                      <div  style="display: inline-block;">
                        <v-checkbox label="ร่าง" value="1" v-model="status1"></v-checkbox>
                      </div>
                      <div  class="pl-2" style="display: inline-block;">
                        <v-checkbox label="รอชำระ"  value="2" v-model="status2"></v-checkbox>
                      </div>
                       
                      <div  class="pl-2" style="display: inline-block;">
                        <v-checkbox label="ชำระแล้ว"  value="4" v-model="status4"></v-checkbox>
                      </div>
                
                    
                  
                </v-col>
              </v-row>
          
            

            </v-col>
            <v-col cols="6" class="px-0">
              <v-row>  
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"><h3 class="fn-14"> เลขที่บิล : </h3>  </v-col>
                <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                  <v-text-field
                              v-model="purchaseOrderIDText"
                              autocomplete="off"
                              outlined
                              hide-details
                              dense
                              placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                          ></v-text-field>
                </v-col>
              </v-row>
              <v-row>  
       
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"><h3 class="fn-14"> บริษัท : </h3>  </v-col>
                <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                  <v-autocomplete
                  v-model="supplierID"
                  :items="supplierIDList"
                  item-value="id"
                  item-text="name"
                  dense
                  hide-details
                  no-data-text="ไม่พบบริษัท"
                  outlined
                  return-object

                 >
                </v-autocomplete>
                </v-col>
              </v-row>
              
           
               
              <v-row>
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"/>
         
                  <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                    <v-btn    :elevation="1" color="primary" class="pa-2" dark @click="SearchData(limits,0,search,1)">
                      <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                      {{use_language.search}}
                    </v-btn>
                  </v-col>
                </v-row>
            </v-col>
         
          </v-row>
        </v-card-text>
      </v-card>
  
      <!-- ข้อมูลทั้งหมด -->
      <v-row class="">
        <v-col cols="6" class="pa-0">
          {{use_language.all_}} {{ formatMoney(total) }} {{use_language.row}}
        </v-col>
        <v-col cols="6"  class="pa-0 text-right">
        ยอดรวม : {{ formatMoney(totalAmount) }}  
      </v-col>
      </v-row>
  
      <!-- แสดงตาราง -->
      <v-card elevation="1">
        <v-card-text class="pa-0">
  
          <!-- PC hidden-xs-only -->
          <v-data-table
            :headers="header"
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-footer
            class="packhai-border-table hidden-xs-only"
          >
          <template v-slot:item.edit="{ item }">
           <a @click="btnEditPayment(item)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
           </template>
            <template v-slot:item.delete="{ item }">
             
              <span  @click="btnDeletePurchase(item)" >
                <a><v-icon   size="18" color="red darken-3">fa-trash-alt</v-icon></a>
              </span>
            
            </template>
            <template   v-slot:item.paymentAmount="{ item }">
              <span class="text-right"> {{ formatMoneyIsDecimal(item.paymentAmount) }}</span>
               
            </template>
            
            <template v-slot:item.dueDate="{ item }">
               {{ set_format_date_time(item.dueDate).substr(0,10) }}
            </template>
            <template v-slot:item.paymentDate="{ item }">
               {{ set_format_date_time(item.paymentDate) }}
            </template>
            <template v-slot:item.purchaseOrderIDText="{ item }">
              <a @click="openPurchase(item.purchaseOrderID)"> {{ item.purchaseOrderIDText }} </a>
            </template>
            <template v-slot:item.updated="{ item }">
               {{ set_format_date_time(item.updated) }}
            </template> 
            <template v-slot:item.statusName="{ item }">
              <span v-if="item.statusID==1">{{  item.statusName }}</span>
              <span style="color:orange"  v-if="item.statusID==2">{{  item.statusName }}</span>
              <span style="color:purple"  v-if="item.statusID==3">{{  item.statusName }}</span>
              <span style="color:green" v-if="item.statusID==4">{{  item.statusName }}</span>
          </template> 
            <template v-slot:item.photoLink="{ item }">
              
            <div v-if="item.photoLink != null && item.photoLink != ''">
              <a
                @click="OpenPhoto(item.photoLink)"
              >
                <v-avatar class="picsize-hover" tile>
                  <img :src="item.photoLink" class="picsize" />
                </v-avatar>
              </a>
          
            </div>
           
           </template>
            
          </v-data-table>
  
          <!-- Mobile hidden-sm-and-up -->
          <!-- <v-data-iterator
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
            class="hidden-sm-and-up"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
                  <v-card>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="6" class="pa-0 pt-1">
                        ({{ formatMoney((total-dataset.indexOf(item))-offsets) }})
                        <a  > {{ GenIE(item.id) }} </a>
                      </v-col>
                      <v-col cols="6" class="text-right pa-0 pt-1">
                        {{ formatDatetime(item.createdDatetime) }}
                        <span v-if="(item.approvedStaffBranchId==null && item.approvedStaffShopId==null)" class="pa-1">
                          <a @click="DeleteTransfer(item.id)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="12" class="text-center pa-0">
                        <span v-if="item.approvedStaffBranchName!=null && item.approvedStaffBranchName!= ''"><v-chip color="success"> {{use_language.approve}} </v-chip></span>
                        <span v-else-if="item.approvedStaffShopName!=null && item.approvedStaffShopName!= ''"><v-chip color="success"> {{use_language.approve}} </v-chip></span>
                        <span v-else><v-chip color="warning"> {{use_language.pending_approval}} </v-chip></span>
                      </v-col>
                    </v-row>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="4" class="pa-0 py-1 text-cut">
                        <span v-if="item.staffBranchName!=null && item.staffBranchName!= ''"> {{use_language.creator}} : {{ item.staffBranchName }}</span>
                        <span v-else-if="item.staffShopName!=null && item.staffShopName!= ''"> {{use_language.creator}} : {{ item.staffShopName }}</span>
                      </v-col>
                      <v-col cols="4" class="pa-0 py-1 text-center">
                        <span v-if="item.transactionType==1" class="color-green">{{ item.transactionName }}</span>
                        <span v-else-if="item.transactionType==2" class="color-red">{{ item.transactionName }}</span>
                        <span v-else-if="item.transactionType==3" class="color-orange">{{ item.transactionName }}</span>
                        <span v-else class="color-orange">{{ item.transactionName }}</span>
                      </v-col>
                      <v-col cols="4" class="text-right pa-0 py-1 text-cut">
                        <span v-if="item.approvedStaffBranchName!=null && item.approvedStaffBranchName!= ''"> {{use_language.approver}} : {{ item.approvedStaffBranchName }}</span>
                        <span v-else-if="item.approvedStaffShopName!=null && item.approvedStaffShopName!= ''"> {{use_language.approver}} : {{ item.approvedStaffShopName }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="12" class="pa-0">
                        {{ item.shopName }}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator> -->
  
        </v-card-text>
      </v-card>
  
      <!-- เปลี่ยนหน้า -->
    
  
      <v-row
        v-if="pageLength > 1 && dataset.length > 0"
        class="text-center pa-2 ma-0"
        @click="changePage()"
      >
        <v-col cols="2"  md="3" lg="2"  xl="1" > </v-col>
        <v-col  cols="8"  md="6" lg="8"  xl="10"  class="pa-0">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="pageTotalVisible"
            color="error"
          ></v-pagination>
        </v-col>
        <v-col cols="2" md="3"  lg="2" xl="1" class="text-right">
          <v-select
            v-model="limits"
            label="จำนวนแสดง"
            :items="[10, 20, 50, 100, 500, 1000]"
            @change="LimitChange()"
            outlined
          ></v-select>
        </v-col>
      </v-row>
  

       <!-- เพิ่มประวัติการชำระเงิน -->
       <v-dialog v-model="EditDialog" 
          :max-width="addPaymentHistoryPopup.MaxWidth"
          persistent >
        <v-card>
            <v-card-title>
                 แก้ไขรายการจ่ายเงิน
                <v-spacer />
                <v-btn icon @click="EditDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3" lg="2" class="pb-0">
                    <h4>สถานะ :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                  <v-radio-group row v-model="input_transferStatusID">
                    <v-radio  label="ร่าง" value="1"> </v-radio>
                    <v-radio  label="รอชำระ" value="2"> </v-radio>  
                    <v-radio  label="ชำระแล้ว" value="4"> </v-radio>
                  </v-radio-group>
                  
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" lg="2" class="pb-0">
                    <h4>ยอดเงิน :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                    <v-text-field
                        v-model.number="input_transferAmount"
                        autocomplete="off"
                        type="number"
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>
              </v-row>
                 
              <v-row>
                <v-col cols="12" sm="3" lg="2" class="pb-0">
                    <h4>วันที่ดิวชำระ :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                  <v-menu
                      v-model="menu_due_date"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                      <template v-slot:activator="{ on }">
                          <v-text-field
                              :value="computed_due_date"
                              @change="value => computed_due_date = value"
                              autocomplete="off"
                              ref="select_dueDate"
                              outlined
                              prepend-icon=""
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              hide-details
                              clearable
                              @click:clear="select_dueDate = null"
                              dense
                              v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="select_dueDate"
                          no-title 
                          @input="menu_due_date = false"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
                  
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" lg="2" class="pb-0">
                    <h4>วันที่ชำระเงิน :</h4>
                </v-col>
                <v-col cols="12" sm="5" lg="4" class="pb-0">
                  <v-menu
                    v-model="menu_transfer_date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computed_transfer_date"
                            @change="value => computed_transfer_date = value"
                            autocomplete="off"
                            ref="select_transferDate"
                            outlined
                            prepend-icon=""
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            dense
                            clearable
                            @click:clear="select_transferDate = null"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="select_transferDate"
                        no-title 
                        @input="menu_transfer_date = false"
                    ></v-date-picker>
                </v-menu>
                </v-col>
                <v-col cols="12" md="2" lg="3" class="pb-0">
                  <v-select
                          v-model="select_transferHour"
                          :items="DateHourList"
                          item-text="name"
                          item-value="value"
                          persistent-hint
                          dense hide-details
                          outlined
                          label="ขั่วโมง"
                          
                        ></v-select>
                </v-col>
                <v-col cols="12" md="2" lg="3" class="pb-0">
                  <v-select
                          v-model="select_transferMinute"
                          :items="DateMinuteList"
                          item-text="name"
                          item-value="value"
                          persistent-hint
                          dense hide-details
                          outlined
                          label="นาที"
                          
                        ></v-select>
                </v-col>
              </v-row>
        
              <v-row>
                <v-col cols="12" sm="3" lg="2" class="pb-0">
                    <h4>หมายเหตุ :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                    <v-text-field
                        v-model="input_transferRemark"
                        autocomplete="off"
                        
                        outlined
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" lg="2" class="pb-0">
                    <h4>{{use_language.bank}} :</h4>
                </v-col>
                <v-col cols="12" sm="9" lg="10" class="pb-0">
                  <v-select
                        v-model="paymentBank"
                        :items="cod_bank_data"
                        item-text="fullBankName"
                        item-value="id"
                        persistent-hint
                        dense hide-details
                        outlined
                        return-object

                        style="width:100%"
                      ></v-select>
                      
                  </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2"  lg="2" class="pb-0">
                    <h4>แนบไฟล์ :</h4>
                </v-col>
                <v-col cols="12" sm="10" lg="10" class="pb-0">
                    <v-file-input
                        v-model="input_transferFileUpload"
                        outlined
                        hide-details="auto"
                        dense
                        prepend-icon=""
                        prepend-inner-icon="mdi-file-image"
                        
                    >
                        <template v-slot:selection="{ text }">
                            <v-chip
                                small
                                label
                                color="primary"
                            >
                                {{ text }}
                            </v-chip>
                        </template>
                    </v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="py-5" style="justify-content: flex-end;">
                <v-btn
                    outlined
                    color="primary"
                    @click="btnSubmitAddPayment()"
                >
                    {{use_language.add_item}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    </div>
  </template>
  
  <style scoped>
    >>>.v-select__slot{
      height: 40px;
    }
    >>>.v-input__prepend-inner{
      margin-top: 10px;
    }
  </style>
  
  <script>
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import Loading from '@/website/components/Loading'
  import { generalService_dotnet, shopService_dotnet,orderService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertError, } from '@/website/global_alert'
  import { formatMoney, isNumberWithDot, isNumberWNoDot,  CheckUrlPhoto,DateNowNotNull, GenIE, Trim_value, Trim_rules, formatDatetime, ChangeIE,get_languages,set_format_date_time,set_format_date_time_for_excel,formatMoneyIsDecimal,getdatelongstring } from '@/website/global_function'
 
  import moment from "moment";

   export default {
      components: {
        Loading, 
      },
      data: vm => ({
        header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
        page_loading: true,
        loading: false,
        totalAmount:0,
        use_language: null,
        set_language: null,
        StaffShopID:parseInt(localStorage.getItem("staff_id")), 
        select_dueDate_from: null,
        select_dueDate_to: null,
        select_transferDate_from: null,
        select_transferDate_to: null,
        menu_transfer_date_from:null,
        menu_transfer_date_to:null,
        menu_due_date_from:null,
        menu_due_date_to:null,
        menu_transfer_date:null,
        select_transferDate:null,
        menu_due_date:null,
        select_dueDate:null,
        input_transferStatusID:null,
        input_transferAmount:null,
        input_transferFileUpload:null,
        paymentBank:null,
        cod_bank_data:null,
        input_transferRemark:null,
        select_transferHour:null,
        select_transferMinute:null,

        // แสดงภาพ
        ShowPhoto: false,
        ShowPhotoLink: '',
        ExcelName:'',
        authorize:false,
        status1:"1",
        status2:"2",
        status3:"3",
        status4:"",
        //#region วันที่
        invoiceidlist:null,
        dueDate:{   
        startDate: null,
        dateStart: null,
        menuStartDate: false,
        checkStart: true,
        endDate: null,
        dateEnd: null,
        menuEndDate: false,
        checkEnd: true,
        },
        EditDialog:false,
        createDate:{   
        startDate: null,
        dateStart: null,
        menuStartDate: false,
        checkStart: true,
        endDate: null,
        dateEnd: null,
        menuEndDate: false,
        checkEnd: true,
        },
        purchaseOrderIDText:null,
        DateMinuteList: [
        { name: '00', value: '00' },
        { name: '01', value: '01' },
        { name: '02', value: '02' },
        { name: '03', value: '03' },
        { name: '04', value: '04' },
        { name: '05', value: '05' },
        { name: '06', value: '06' },
        { name: '07', value: '07' },
        { name: '08', value: '08' },
        { name: '09', value: '09' },
        { name: '10', value: '10' },
        { name: '11', value: '11' },
        { name: '12', value: '12' },
        { name: '13', value: '13' },
        { name: '14', value: '14' },
        { name: '15', value: '15' },
        { name: '16', value: '16' },
        { name: '17', value: '17' },
        { name: '18', value: '18' },
        { name: '19', value: '19' },
        { name: '20', value: '20' },
        { name: '21', value: '21' },
        { name: '22', value: '22' },
        { name: '23', value: '23' },
        { name: '24', value: '24' },
        { name: '25', value: '25' },
        { name: '26', value: '26' },
        { name: '27', value: '27' },
        { name: '28', value: '28' },
        { name: '29', value: '29' },
        { name: '30', value: '30' },
        { name: '31', value: '31' },
        { name: '32', value: '32' },
        { name: '33', value: '33' },
        { name: '34', value: '34' },
        { name: '35', value: '35' },
        { name: '36', value: '36' },
        { name: '37', value: '37' },
        { name: '38', value: '38' },
        { name: '39', value: '39' },
        { name: '40', value: '40' },
        { name: '41', value: '41' },
        { name: '42', value: '42' },
        { name: '43', value: '43' },
        { name: '44', value: '44' },
        { name: '45', value: '45' },
        { name: '46', value: '46' },
        { name: '47', value: '47' },
        { name: '48', value: '48' },
        { name: '49', value: '49' },
        { name: '50', value: '50' },
        { name: '51', value: '51' },
        { name: '52', value: '52' },
        { name: '53', value: '53' },
        { name: '54', value: '54' },
        { name: '55', value: '55' },
        { name: '56', value: '56' },
        { name: '57', value: '57' },
        { name: '58', value: '58' },
        { name: '59', value: '59' }
          ],
        DateHourList: [
          { name: '00', value: '00' },
          { name: '01', value: '01' },
          { name: '02', value: '02' },
          { name: '03', value: '03' },
          { name: '04', value: '04' },
          { name: '05', value: '05' },
          { name: '06', value: '06' },
          { name: '07', value: '07' },
          { name: '08', value: '08' },
          { name: '09', value: '09' },
          { name: '10', value: '10' },
          { name: '11', value: '11' },
          { name: '12', value: '12' },
          { name: '13', value: '13' },
          { name: '14', value: '14' },
          { name: '15', value: '15' },
          { name: '16', value: '16' },
          { name: '17', value: '17' },
          { name: '18', value: '18' },
          { name: '19', value: '19' },
          { name: '20', value: '20' },
          { name: '21', value: '21' },
          { name: '22', value: '22' },
          { name: '23', value: '23' },
        ],
           // #endregion  
      
        // ค้นหา
        search: {
           
          startDate: null,
          endDate: null,
          remark: null,
          importExportMasterId: null,
          referenceNumber: null,
         
        },
       
       
        supplierID: {id:0,name:'--ทั้งหมด--'},
        supplierIDList:[{id:0,name:'--ทั้งหมด--'}],
        // แสดงตาราง
        total: 0,
        dataset: [],
        header: [
          { text: 'No', align: 'center', sortable: false, value: 'no', width: '60px' },
          { text: 'แก้ไข',align: 'center',sortable: false,value: 'edit', width: '60px'},
          { text: 'ลบ',align: 'center',sortable: false,value: 'delete', width: '60px'},
          { text: 'เลขที่บิล PO', align: 'center', sortable: false, value: 'purchaseOrderIDText', width: '100px' },
          { text: 'บริษัท', align: 'center', sortable: false, value: 'companyName'  },
          { text: 'วันที่ดิวชำระ', align: 'center', sortable: false, value: 'dueDate', width: '100px' },
          { text: 'วันที่ชำระ', align: 'center', sortable: false, value: 'paymentDate', width: '150px' },
          
          { text: 'ยอดเงิน', align: 'right', sortable: false, value: 'paymentAmount', width: '120px' },
          { text: 'ธนาคาร', align: 'center', sortable: false, value: 'bankAccountText' , width: '180px' },
          { text: 'สถานะ', align: 'center', sortable: false, value: 'statusName', width: '90px' },
          { text: 'รูปภาพ', align: 'center', sortable: false, value: 'photoLink', width: '100px' },
          { text: 'หมายเหตุ', align: 'center', sortable: false, value: 'remark', width: '180px' },
          
          
          { text: 'อัพเดทวันที่', align: 'center', sortable: false, value: 'updated', width: '150px' },
          { text: 'อัพเดทโดย', align: 'center', sortable: false, value: 'updatedByName', width: '100px' },
        ],
        // Export Excel
        loadingExcel: 0,
        datasetExcel: [],
        headerExcel: [
        { label: "No", field: "no", width: "60px" },
        { label: "เลขที่บิล PO", field: "purchaseOrderIDText", width: "100px" },
        { label: "วันที่ดิวชำระ", field: "dueDate", width: "200px" },
        { label: "วันที่ชำระ", field: "paymentDate", width: "150px" },
        
        { label: "ชื่อบริษัท", field: "companyName", width: "180px" },
        { label: "ยอดเงิน", field: "paymentAmount", width: "120px" },
        { label: "สถานะการชำระเงิน", field: "statusName", width: "150px" },
        { label: "รูปภาพ", field: "photoLink", width: "100px" },
        { label: "หมายเหตุ", field: "remark", width: "150px" },
        { label: "สร้างวันที่", field: "created", width: "150px" },
        { label: "สร้างโดย", field: "createdByName", width: "100px" },
        { label: "วันที่อัพเดท", field: "updated", width: "130px" },
        { label: "อัพเดทโดย", field: "updatedByName", width: "130px" },
        ],
  

        
        addPaymentHistoryPopup: {
          dialog: false,
          MaxWidth: "800",
          MaxHeight: 70,
        },
        // paginations
        page: 1,
        pageTemp: 1,
        pageLength: 1,
        pageTotalVisible: 9,
        limits: 10,
        offsets: 0,
        shopId: parseInt(localStorage.getItem('shop_id')),
      }),
      computed: { 
        computed_due_date() {
            if(this.select_dueDate == null) {
              return null
            }else {
              return this.formatDate(this.select_dueDate)
            }
        },
        computed_transfer_date() {
            if(this.select_transferDate == null) {
              return null
            }else {
              return this.formatDate(this.select_transferDate)
            }
        },
        computed_transfer_date_from() {
            if(this.select_transferDate_from == null) {
              return null
            }else {
              return this.formatDate(this.select_transferDate_from)
            }
        },
        computed_transfer_date_to() {
            if(this.select_transferDate_to == null) {
              return null
            }else {
              return this.formatDate(this.select_transferDate_to)
            }
        },
        computed_due_date_from() {
            if(this.select_dueDate_from == null) {
              return null
            }else {
              return this.formatDate(this.select_dueDate_from)
            }
        },
        computed_due_date_to() {
            if(this.select_dueDate_to == null) {
              return null
            }else {
              return this.formatDate(this.select_dueDate_to)
            }
        },
      },
      watch: {
        'dueDate.startDate': async function(newVal, oldVal) {
            
            
          if (new Date(newVal)>new Date(this.dueDate.endDate) && this.dueDate.endDate!=null && newVal!=null ){
            if (oldVal!=null){
              this.dueDate.checkStart = false
              this.dueDate.startDate = oldVal
            }else{
              this.dueDate.dateStart = oldVal
              this.$refs.dueDatemenuStartDate.save(oldVal)
            }
          }else if(this.dueDate.checkStart){
            try {
              this.$refs.dueDatemenuStartDate.save(newVal)
            }catch(err) {
              console.log("");
            }
          }else{
            this.dueDate.dateStart = this.formatDate(newVal)
            this.$refs.dueDatemenuStartDate.save(newVal)
            this.dueDate.checkStart = true
          }
        },
        'dueDate.endDate': async function(newVal, oldVal) {
          if (new Date(newVal)<new Date(this.dueDate.startDate) && this.dueDate.startDate!=null  && newVal!=null){
            if (oldVal!=null){
              this.dueDate.checkEnd = false
              this.dueDate.endDate = oldVal
            }else{
              this.dueDate.dateEnd = oldVal
              this.$refs.dueDatemenuEndDate.save(oldVal)
            }
          }else if(this.checkEnd){
            try {
              this.$refs.dueDatemenuEndDate.save(newVal)
            }catch(err) {
              console.log("");
            }
          }else{
            this.dueDate.dateEnd = this.formatDate(newVal)
            this.$refs.dueDatemenuEndDate.save(newVal)
            this.dueDate.checkEnd = true
          }
        },
        'createDate.startDate': async function(newVal, oldVal) {
          if (new Date(newVal)>new Date(this.createDate.endDate) && this.createDate.endDate!=null && newVal!=null ){
            if (oldVal!=null){
              this.createDate.checkStart = false
              this.createDate.startDate = oldVal
            }else{
              this.createDate.dateStart = oldVal
              this.$refs.createDatemenuStartDate.save(oldVal)
            }
          }else if(this.createDate.checkStart){
            try {
              this.$refs.createDatemenuStartDate.save(newVal)
            }catch(err) {
              console.log("");
            }
          }else{
            this.createDate.dateStart = this.formatDate(newVal)
            this.$refs.createDatemenuStartDate.save(newVal)
            this.createDate.checkStart = true
          }
        },
        'createDate.endDate': async function(newVal, oldVal) {
          if (new Date(newVal)<new Date(this.createDate.startDate) && this.createDate.startDate!=null  && newVal!=null){
            if (oldVal!=null){
              this.createDate.checkEnd = false
              this.createDate.endDate = oldVal
            }else{
              this.createDate.dateEnd = oldVal
              this.$refs.createDatemenuEndDate.save(oldVal)
            }
          }else if(this.checkEnd){
            try {
              this.$refs.createDatemenuEndDate.save(newVal)
            }catch(err) {
              console.log("");
            }
          }else{
            this.createDate.dateEnd = this.formatDate(newVal)
            this.$refs.createDatemenuEndDate.save(newVal)
            this.createDate.checkEnd = true
          }
        },
      },
      async created() {
  
            //เก็บภาษามาไว้ใน use_language
           this.use_language = await this.get_languages(localStorage.getItem("set_language"));
  
            //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
            this.get_authorize_staffshop();
  
        document.title = this.$router.history.current.meta.title
        let shopBank = await axios.post(shopService_dotnet+'Shop/get-shop-bank-list', {"shopID": this.shopId},{ headers: this.header_token});
        this.cod_bank_data = shopBank.data;
        this.supplierIDList.push(...await this.getSupplierForDropDown());
        await this.SearchData(this.limits,0,this.search,1);
        this.page_loading = false
      },
      methods: {
        get_languages,
        async SearchData(limits,offsets,search,page){
          var ispass=false 
          
          var StatusList=[]
          if(this.status1=="1"){
            StatusList.push(1);
          }
          if(this.status2=="2"){
            StatusList.push(2);
          }
          
          if(this.status4=="4"){
            StatusList.push(4);
          }
          while(!ispass){
            try {
              this.loading = true
              let response = await axios.post(orderService_dotnet+'PO/get-purchase-order-payment', {
                "purchaseOrderID":this.purchaseOrderIDText,
                "shopID":this.shopId,
                "company": this.supplierID.id != 0 ?this.supplierID.name:null,
                "statusIDList":StatusList,
                "dueDateFrom": this.select_dueDate_from ,
                "dueDateTo": this.select_dueDate_to ,
                "createdFrom": this.select_transferDate_from ,
                "createdTo": this.select_transferDate_to ,
                "remark": this.search.remark=='' ? null : this.search.remark,
                "isNeedResultCount": true,
                "skip": offsets,
                "take": limits,
              }, {headers: this.header_token})
              ispass=true
              this.loading = false
              if (response.status==200){
                this.page = page
                this.pageTemp = page
                this.offsets = offsets
                this.dataset = response.data.items
                this.total = response.data.resultCount
                this.totalAmount = response.data.totalAmount
                this.pageLength =  (Math.ceil(this.total/this.limits))
                if (this.pageLength<=0){this.pageLength=1}
              } else {
                Swal.fire({
                  icon: "error",
                  title:"ไม่สามารถทำรายการได้ ",
                  text: "กรุณาแจ้งผู้ดูแลระบบ",
                  showConfirmButton: true,
                })
              }
            } catch (error) {
              this.loading = false
              Swal.fire({
                toast: true,
                icon: 'error',
                title: 'ไม่สามารถติดต่อฐานข้อมูลได้',
                text: 'กำลังดำเนินการใหม่อีกครั้ง',
                timer: 1500,
                showConfirmButton: false,
                position:'bottom-end'
              })
            }
          }
        },
        async getPurchaseOrderid(limits,offsets,search,page){
            var StatusList=[]
            if(this.status1=="1"){
              StatusList.push(1);
            }
            if(this.status2=="2"){
              StatusList.push(2);
            }
            
            if(this.status4=="4"){
              StatusList.push(4);
            }
            try {
              this.loading = true
              let response = await axios.post(orderService_dotnet+'PO/get-purchase-order-payment', {
                "purchaseOrderID":'',
                "shopID":this.shopId,
                "company": this.supplierID.id != 0 ?this.supplierID.name:null,
                "statusIDList":StatusList,
                "dueDateFrom": this.select_dueDate_from ,
                "dueDateTo": this.select_dueDate_to ,
                "createdFrom": this.select_transferDate_from ,
                "createdTo": this.select_transferDate_to ,
                "remark": this.search.remark=='' ? null : this.search.remark,
                "isNeedResultCount": true,
                "skip": offsets,
                "take": limits,
              }, {headers: this.header_token})
              
              this.loading = false
              if (response.status==200){
                return response.data.items
              } else {
                Swal.fire({
                  icon: "error",
                  title:"ไม่สามารถทำรายการได้ ",
                  text: "กรุณาแจ้งผู้ดูแลระบบ",
                  showConfirmButton: true,
                })
              }
            } catch (error) {
              this.loading = false
              Swal.fire({
                toast: true,
                icon: 'error',
                title: 'ไม่สามารถติดต่อฐานข้อมูลได้',
                text: 'กำลังดำเนินการใหม่อีกครั้ง',
                timer: 1500,
                showConfirmButton: false,
                position:'bottom-end'
              })
            }
          
        },
        openPurchase(id){
          window.open('addExpense?id='+id, '_blank');
        },
        async getSupplierForDropDown() {
          return await axios
            .post(
              orderService_dotnet +"PO/get-supplier-for-dropdown",
              {
                shopID:this.shopId , 
              },
              { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data.items
            })
            .catch((error) => {
              this.loading = false;
              this.AlertError();
              return false;
            });
        },
        async updatePurchaseOrderPayment(body) {
          
          return await axios
            .post(
              orderService_dotnet +"PO/update-purchase-order-payment",
              {
                "id": body.id, 
                "paymentDate": body.paymentDate,
                "dueDate": body.dueDate,
                "paymentAmount": body.paymentAmount,
                "photoLink": body.photoLink,
                "statusID": body.statusID,
                "remark": body.remark,
                "staffShopID": this.StaffShopID,
                "bankAccountID": body.bankAccountID
              },
              { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data
            })
            .catch((error) => {
              this.loading = false;
              this.AlertError();
              return false;
            });
        },

        async editPurchase(id){
          
          window.open("addExpense?id="+id);
        },
        async deletePurchaseOrderPayment(body) {
        return await axios
          .get(
            orderService_dotnet +"PO/delete-purchase-order-payment?ID="+body.id,
            { headers: this.header_token }
          )
          .then(async (res) => {
              return res.data
          })
          .catch((error) => {
            this.loading = false;
            this.AlertError();
            return false;
          });
        },
        async btnDeletePurchase(item){
           
          Swal.fire({
             
            type: 'question',
            title: 'ต้องการลบ?',
            text: 'เลขที่ : '+item.purchaseOrderIDText+', ยอดเงิน : '+this.formatMoneyIsDecimal(item.paymentAmount),
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.value) {
              this.loading=true;
              await this.deletePurchaseOrderPayment(item);
              var offsets = (this.page*this.limits)-this.limits
              await this.SearchData(this.limits,offsets,this.search,this.page)
              this.loading=false;
            }}
          )
        },
         
        // ส่วนของ btn
        async btnEditPayment(data){
          if(data.paymentDate){
            let date =  data.paymentDate.substr(0,10);
            let hours = moment(data.paymentDate, "YYYY-MM-DDTHH:mm:ss.SS").format("HH");
            let minute = moment(data.paymentDate, "YYYY-MM-DDTHH:mm:ss.SS").format("mm");

            
            this.select_transferDate  =  date
            this.select_transferHour  = hours
            this.select_transferMinute  = minute
          }else{
            this.select_transferDate=null;
            this.select_transferHour=null;
            this.select_transferMinute=null;

          }
          
          if(data.dueDate){
            let date1 =  data.dueDate.substr(0,10);
            this.select_dueDate  =  date1
          }else{
            this.select_dueDate  = null;
          }
          let bankAccountID = this.cod_bank_data.filter(b => b.id == data.bankAccountID )
          if(bankAccountID.length>0 ){
            this.paymentBank =  bankAccountID[0];
          }
          else{
            this.paymentBank =null
          }
          this.input_transferId = data.id
          this.input_transferStatusID = data.statusID.toString();
          this.input_transferFileUpload = null
          this.input_transferPhotoLink = data.photoLink
          this.input_transferAmount = data.paymentAmount  
          this.input_transferRemark = data.remark  
          this.EditDialog = true;
        },
        Opentap(PhotoLink) {
          window.open(PhotoLink);
        },
        async btnSubmitAddPayment(){
     
          if(parseInt(this.input_transferStatusID)<4 &&this.select_dueDate==null ){

            Swal.fire({
            // title: "The Internet?",
              text: "กรุณาระบุวันที่ดิวชำระ",
              type: "warning",
            });
            return;
          }
          if(parseInt(this.input_transferStatusID)==4 &&this.select_transferDate==null ){
            Swal.fire({
            // title: "The Internet?",
              text: "กรุณาระบุวันที่จ่ายเงิน",
              type: "warning",
            });
            return;
          }
          if(this.input_transferAmount)
          {
            if(this.input_transferFileUpload){
              let resUpload = await this.UploadFile(this.input_transferFileUpload)
              
              if(resUpload.status == "success"){
                this.input_transferPhotoLink = resUpload.file_path
              }else{
                this.alertStop();
              }
            }
            var transfer_date=null;
            var due_date=null;
            if(this.select_transferDate !== null)
            {
              let hours = this.select_transferHour??"00"
              let Minute = this.select_transferMinute??"00"
              transfer_date = this.select_transferDate + "T" + hours+ ":" + Minute+":00"
            }

          
            if(this.select_dueDate){ 
              due_date = this.select_dueDate;
            }
            var payment_data = {
              "id": this.input_transferId == null ? 0 : this.input_transferId ,
              "bankAccountID": (this.paymentBank==null? null: this.paymentBank.id),
              "paymentAmount": parseFloat( Number(this.input_transferAmount).toFixed(2)),
              "photoLink": this.input_transferPhotoLink,
              "paymentDate": transfer_date,
              "dueDate": due_date,
              "staffShopID": this.StaffShopID,
              "statusID": parseInt(this.input_transferStatusID),
              "remark": this.input_transferRemark
            }

            this.loading=true;
            let resUpdate = await this.updatePurchaseOrderPayment(payment_data);
            if(resUpdate.status == "success"){
              var offsets = (this.page*this.limits)-this.limits
              await this.SearchData(this.limits,offsets,this.search,this.page)
            }else{
              this.alertError("ไม่สามารถทำรายการได้ " + resUpdate.error_description)
            }
            this.loading=false;
            this.EditDialog = false;
          }else{
            Swal.fire({
            // title: "The Internet?",
              text: "กรุณาระบุยอดเงิน",
              type: "warning",
            });
          }
        },
       
        // เรียกใช้
      
        async ExportExcel(){
          this.datasetExcel = []
          this.loadingExcel = 0
          const datenow = new Date();
          this.ExcelName = 'รายการรับเงิน'+ this.getdatelongstring();
          Swal.fire({
         
            type: 'warning',
            text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            allowOutsideClick: false
          }).then(async(result) => {
            if (result.value) {
  
              var limits = 50
              var offsets = 0
              this.loadingExcel = 1
              var total = this.total
              var round = parseInt( total/limits)
  
              if ( limits * round < total ){
                round = round+1
              }
  
              var no = 0
              for (var i = 1; i <= round; i++) {
  
                var resultData = await this.getPurchaseOrderid(limits,offsets,this.search)
                resultData.forEach(element => {
                  element.dueDate = this.set_format_date_time_for_excel(element.dueDate)
                  element.paymentDate = this.set_format_date_time_for_excel(element.paymentDate)
                  element.created = this.set_format_date_time_for_excel(element.created)
                  element.updated = this.set_format_date_time_for_excel(element.updated)

                }); 
                this.datasetExcel.push(...resultData);
                this.loadingExcel = parseInt(
                  (parseFloat(i) / parseFloat(round)) * parseFloat(100)
                );
              offsets = offsets + limits;
            }
              this.loadingExcel = this.datasetExcel.length== 0 ? 0 : 100
              document.getElementById("GetExcel").click();
            }
          })
        },
        async LimitChange() {
          await this.SearchData(this.limits, 0, this.search, 1);
        },
        async EditStock(ImportExportMasterID){
          window.open("transfer-list/update?id="+btoa(ImportExportMasterID));
        },
        async changePage() {
          if (this.pageLength!=1 && this.pageTemp!=this.page){
            var offsets = (this.page*this.limits)-this.limits
            await this.SearchData(this.limits,offsets,this.search,this.page)
            this.pageTemp = this.page
          }
        },
        formatDate (date) {
         
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        },
        parseDate (date) {
          
          if (!date) return null
          const [day, month, year] = date.split('/')
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatMFG (datetime) {
        if (datetime==null || datetime==""){
          return null
        }
        return datetime.substring(0,4)+"-"+datetime.substring(5,7)+"-"+datetime.substring(8,10);
        },
        allowedDates: val => val <= new Date().toISOString().substr(0, 10),
        OpenPhoto(Photo){
          this.ShowPhotoLink = Photo
          this.ShowPhoto = true
        },
        async get_authorize_staffshop () {
            if(localStorage.getItem("240") == 2)
            {
             this.authorize = true
            }
        },
   
        formatDateTimeToISO(dateTime) {
          return moment(dateTime, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SS");
        },
        async UploadFile(files){
          let bodyUploadFile = [];
    
          
          var from_data = new FormData();
              from_data.append("bucket", "Doc_file");
              from_data.append("file", files);
              bodyUploadFile = from_data;

          return await axios
            .post(
              generalService_dotnet + "General/upload-file-with-bucket",
              bodyUploadFile,
              { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data
            })
            .catch((error) => {
              this.loading = false;
              this.AlertError();
              return false;
            });
        },
        async alertStop() {
          Swal.fire({
            // title: "The Internet?",
            text: "ไม่สามารถทำรายการได้",
            type: "error",
          });
        },
        formatMoney,
        formatMoneyIsDecimal,
        isNumberWithDot,
        isNumberWNoDot,
        DateNowNotNull,
        GenIE,
        Trim_value,
        Trim_rules,
        formatDatetime,
        ChangeIE,
        AlertSuccess,
        AlertError,
        set_format_date_time,
        set_format_date_time_for_excel,
        getdatelongstring,
        CheckUrlPhoto,
      }
    }
  </script>
  