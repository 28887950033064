export const shippingStatusDict = {
  'OpenOrder': 10,
  'WaitCheck': 20,
  'WaitPack': 30,
  'Packing': 40,
  'Packed': 50,
  'Ready': 60,
  'Sent': 70,
  'Cancel': 90
}
