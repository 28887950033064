<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
       
        v-model="formattedDateTime"
        :label="isLabel"
        readonly
        v-bind="attrs"
        v-on="on"
        hide-details
      ></v-text-field>
    </template>

    <v-card>
      <!-- แสดง Date Picker -->
      <div v-if="step === 'date'">
        <v-date-picker v-model="date" @change="onDateSelected"  no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn v-if="isTime" text color="primary" @click="onDateConfirmed">ตกลง</v-btn>
        </v-date-picker>
      </div>

      <!-- แสดง Time Picker -->
      <div v-else-if="step === 'time' && isTime">
        <v-time-picker v-model="time" format="24hr" @change="onTimeSelected">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeMenu">ตกลง</v-btn>
        </v-time-picker>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "DateTimePicker",
  props: {
    value: { type: String, default: null }, // สำหรับ v-model
    isTime: { type: Boolean, default: true }, // แสดงตัวเลือกเวลา
    isLabel: { type: String, default: null }, // แสดง label
  },
  data() {
    return {
      menu: false, // สถานะเปิด/ปิดเมนู
      step: "date", // ขั้นตอนการเลือก ('date' หรือ 'time')
      date: null, // เก็บวันที่ที่เลือก
      time: null, // เก็บเวลาที่เลือก
      formattedDateTime: this.value || "", // แสดงผลใน Text Field
    };
  },
  methods: {
    // เมื่อเลือกวันที่แล้ว
    onDateSelected(selectedDate) {
      this.date = selectedDate;
      if (this.isTime) {
        this.step = "time"; // เปลี่ยนขั้นตอนเป็นการเลือกเวลา
      } else {
        this.onDateConfirmed(); // ถ้าไม่แสดงเวลา ให้ยืนยันทันที
      }
    },
    // ยืนยันวันที่เมื่อไม่เลือกเวลา
    onDateConfirmed() {
      this.updateDateTime();
      this.closeMenu();
    },
    // เมื่อเลือกเวลาแล้ว
    onTimeSelected(selectedTime) {
      this.time = selectedTime;
      this.updateDateTime(); // รวมวันที่และเวลา
      this.closeMenu();      // ปิดเมนู
    },
    // รวมวันที่และเวลาให้อยู่ในรูปแบบที่เลือก
    updateDateTime() {
      if (this.date) {
        const combinedDateTime = new Date(this.date);
        if (this.isTime && this.time) {
          const [hours, minutes] = this.time.split(":").map(Number);
          combinedDateTime.setHours(hours);
          combinedDateTime.setMinutes(minutes);
        } else {
          combinedDateTime.setHours(0);
          combinedDateTime.setMinutes(0);
        }
        combinedDateTime.setSeconds(0);
        combinedDateTime.setMilliseconds(0);

        // แสดงวันและเวลาในรูปแบบที่เลือก
        const formattedDate = this.formatDateTime(combinedDateTime);
        this.formattedDateTime = formattedDate;

        // ส่งค่ากลับไปยัง parent ผ่าน v-model
        this.$emit("input", formattedDate);

        // รีเซ็ตขั้นตอนสำหรับการเลือกครั้งถัดไป
        this.step = "date";
      }
    },
    // ฟังก์ชันเพื่อจัดรูปแบบวันที่และเวลา
    formatDateTime(date) {
      const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
      };
      if (this.isTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.hour12 = false;
      }

      let formattedDate = date.toLocaleString("en-GB", options)
        .replace(/\//g, "-")
        .replace(",", ""); // ปรับรูปแบบเป็น DD-MM-YYYY HH:mm

      return formattedDate;
    },
    // ปิดเมนู
    closeMenu() {
      this.menu = false;
    },
  },
  watch: {
    value(newValue) {
      this.formattedDateTime = newValue; // อัปเดตค่าเมื่อเปลี่ยนจาก parent
    },
  },
};
</script>
