import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.showphoto),callback:function ($$v) {_vm.showphoto=$$v},expression:"showphoto"}},[_c(VCard,{staticClass:"ma-0"},[_c(VCardText,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('img',{staticClass:"hidden-md-and-up",attrs:{"src":_vm.showphotolink,"width":"300"}}),_c('img',{staticClass:"hidden-sm-and-down",attrs:{"src":_vm.showphotolink,"width":"450"}})])],1)],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"#1976d2"},on:{"click":function($event){return _vm.Opentap(_vm.showphotolink)}}},[_vm._v("เปิดแถบใหม่")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"#1976d2"},on:{"click":function($event){return _vm.ClosePopup()}}},[_vm._v("ปิด")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }