<template>
    <div v-if="page_loading">
      <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />
  
      <!-- นำทาง -->
      <v-card class="v-card-bottom-30">
        <v-card-title>
          {{use_language.stock}}
          <v-icon>mdi-chevron-right</v-icon>
          {{use_language.delivery_plan}}
        </v-card-title>
      </v-card>
 
     
  
      <!-- ค้นหาสินค้า -->
      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <div class="py-1">
            <v-icon size="22" color="orange darken-3">mdi mdi-home-assistant</v-icon><span class="ml-3">{{use_language.search}}</span>
          </div>
          <v-spacer/>
          <div>
           
            <vue-excel-xlsx
                id="GetExcel"
                v-show="false"
                :data="datasetExcel"
                :columns="headerExcel"
                :file-name="'mystock'"
                :sheet-name="'shee1'"
                >
            </vue-excel-xlsx>
            <v-btn :elevation="1" outlined color="success" class="px-2 ml-5" @click="ExportExcel(search)">
              <v-icon size="18" class="ml-1" left>fa-download</v-icon>
            {{use_language.export_excel}}
              <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider/>
        <v-card-text>
  
          <v-row>
            <v-col cols="12" lg="6" class="pa-0">
              <v-row>
                <v-col cols="12"  lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> {{use_language.warehouse}} : </h3>
                </v-col>
                <v-col cols="12"  lg="8" class="pt-1 pb-2">
                  <v-select
                    v-model="warehouse_selected"
                    :items="warehouse_data"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    return-object
                    dense hide-details
                    outlined
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="12"  lg="3" class="pt-1 pb-2">  </v-col> 
                <v-col cols="12"  lg="8" class="pt-1 pb-2">
                  <v-btn :elevation="1" color="primary" class="pa-2" dark @click="Load_Data()">
                    <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                    {{use_language.search}}
                  </v-btn>
                </v-col>
  
                
  
              </v-row>
            </v-col>
          
          </v-row>
  
        </v-card-text>
      </v-card>
  
      <!-- ข้อมูลทั้งหมด -->
     
      <!-- แสดงตาราง -->
      <v-card elevation="1">
        <v-card-text class="pa-0">
           
           
              <!-- PC hidden-xs-only -->
        <v-simple-table class="packhai-border-table" v-if="headers.length>0" >
            <template v-slot:default>
              <thead>
                <tr>
                  <th  class="text-center" v-for="item in headers" :key="item.key">
                    {{item.text}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr  v-for="item2 in dataitems" :key="item2.id">
                  <th class="text-center" v-for="item3 in item2.columns" :key="item3.key">
                    <span v-if="item3.key>1 && parseFloat(item3.value)>0" style="color: green"> {{item3.value}}</span>
                    <span v-else-if="item3.key>1 && parseFloat(item3.value)<0"  style="color: red"> {{item3.value}}</span>
                    <span v-else> {{item3.value}}</span>
                   
                  </th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
  
  
        
        </v-card-text>
    </v-card>
  
      <!-- แก้ไขสต๊อกสินค้า -->
    
  
      <!-- เปลี่ยนหน้า -->
  
      <div class="v-card-bottom-30" />
  
    
  
    </div>
  </template>
  
  <script>
    import Loading from '@/website/components/Loading'
    import axios from 'axios'
    import Swal from 'sweetalert2' 
    import { stockService_dotnet, shopService_dotnet, ecomService_dotnet } from '@/website/global'
    import { formatMoney, formatDate_thai, format_price, isNumberWithDot, isNumberWNoDot, DateNowNotNull,
    Trim_value, Trim_rules,get_languages, ViewTypePage,UpdateViewTypePage } from '@/website/global_function'
  
  
   // สำหรับ datatable move columns start 
  
    
    // สำหรับ datatable move columns  end
  
   export default {
      components: {
        Loading
      },
      data: () => ({
        // page
        header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
        shop:JSON.parse(localStorage.getItem("shop")), 
        page_loading: true,
        loading: false,
        use_language: null,
        set_language: null,
        // แสดงภาพ
        ShowPhoto: false,
        ShowPhotoLink: '',
        headers:[],
        dataitems:[],
        // Permisstion
        SeeCostPermisstion: '',
        PermisstionManageStock: '',
  
       
        
  
        // ค้นหา
         
        warehouse_data:[],
        warehouse_selected:null,
        warehouseIDList:[],
        // Export Excel
        loadingExcel: 0,
        datasetExcel: [],
        headerExcel: [],
   
        warehouseId: 0,
  
        // แสดงตาราง
        tableloading: false,
        total: 0, 
        IsUpdateStockMarketplace : false,
        IsHeaderDataNotTheSame : false,
         
   
        page: 1,
        pageTemp: 1,
        pageLength: 1,
        pageTotalVisible: 9,
        limits: 10,
        offsets: 0, 
        loadingStock : 0 , 
       
      }),
      computed: {
          showHeaders () {
            
        return this.headers.filter(s => this.headers.includes(s));
      }
      },
      watch: {
      },
      async created() {
  
            //เก็บภาษามาไว้ใน use_language
        this.use_language = await this.get_languages(localStorage.getItem("set_language"));
  
        document.title = this.$router.history.current.meta.title
   
         
        await this.GetWarehouse()
       
  
        
  
        //ส่วนของการดึง viewtpye
 
        this.page_loading = false
      },
  
     methods: {
        get_languages, 
         
   
  
        async GetWarehouse() {
          let response = await axios.post(shopService_dotnet+'Shop/get-warehouse-list', {
            "shopId": this.shop.id,
          }, {headers: this.header_token})
          if (response.status ==200) {
            this.warehouseIDList=[]
            this.warehouse_data = [{"id": 0, "name": "--ทั้งหมด--"}];
            
            if (response.data.length>0){
              if(response.data.length==1){
                this.warehouse_data = [{"id": response.data[0].id, "name":response.data[0].name}];
                this.warehouseIDList.push( response.data[0].id);
              }else{
                for (var i in  response.data){
                  this.warehouse_data.push({
                    "id": response.data[i].id,
                    "name": response.data[i].name,
                  })
                  this.warehouseIDList.push(response.data[i].id)
                }
              }
            }
            this.warehouse_selected = this.warehouse_data[0];
          }
        }, 
        async Load_Data() {
          
         if( this.warehouse_selected.id==0){
            return;
         }
          
          this.loading =  true
          let response = await axios.post(stockService_dotnet+'Stock/get-stock-planing', { 
            "shopID":this.shop.id,
            "warehouseID": this.warehouse_selected.id, 
          }, {headers: this.header_token})
         
          if (response.status ==200) {
            this.headerExcel = response.data.dataHeader_excel;
            this.datasetExcel = response.data.dataitems_excel;
            this.headers = response.data.headers;
            this.dataitems = response.data.dataitems;
            console.log(this.headers);
            console.log(this.dataitems);
            this.loading =false;
          
          } else {
            this.loading = false
            Swal.fire({
              position: "top",
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            })
          }
        }, 
   
       
        async ExportExcel(search){
            document.getElementById("GetExcel").click();
        }, 
     
  
  
        format_price,
        formatMoney,
        formatDate_thai,
        isNumberWithDot,
        isNumberWNoDot,
        DateNowNotNull,
        Trim_value,
        Trim_rules,
        ViewTypePage,
        UpdateViewTypePage
      },
  
       
    }
  </script>
  